import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Skeleton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { FiCircle } from 'react-icons/fi';
import { HiGlobeAmericas } from 'react-icons/hi2';
import { MdOutlinePhoneAndroid } from 'react-icons/md';
import { FaLaptop } from 'react-icons/fa';
import { IoRefresh } from 'react-icons/io5';
import { MdInfoOutline } from 'react-icons/md';
import { PiCaretCircleRightFill } from 'react-icons/pi';
import { RxCaretRight } from 'react-icons/rx';

interface GoogleTemplatesPreviewProps {
  category: string;
  headlines: string[];
  descriptions: string[];
  sitelinks: {
    headline: string;
    description1: string;
    description2: string;
    finalUrl: string;
  }[];
  url: string;
  showToolbar?: boolean;
  center?: boolean;
  images?: {
    id: string;
    url: string;
    height: number;
    width: number;
    asset_id: string;
    public_id: string;
    resource_type: string;
  }[];
  business_logo?: any;
  business_name?: string;
}

const GoogleTemplatesPreview: React.FC<GoogleTemplatesPreviewProps> = ({
  category = 'search',
  headlines,
  descriptions,
  sitelinks,
  url,
  showToolbar = true,
  center = false,
  images = [],
  business_logo = '',
  business_name = '',
}) => {
  const [displayHeadline1, setDisplayHeadline1] = useState('');
  const [displayHeadline2, setDisplayHeadline2] = useState('');
  const [displayDescription, setDisplayDescription] = useState('');
  const [displayName, setDisplayName] = useState(business_name);
  const [displayImage, setDisplayImage] = useState('');
  const [viewMode, setViewMode] = useState('mobile');
  const [displayUrl, setDisplayUrl] = useState('');
  const [businessLogoUrl, setBusinessLogoUrl] = useState('');

  const setDisplays = () => {
    if (url) {
      let u = url[url.length - 1] !== '/' ? `${url}/` : url;
      //get text before the first ?
      const index = u.indexOf('?');
      if (index > -1) {
        u = u.substring(0, index);
      }
      setDisplayUrl(u);
    }

    if (headlines) {
      const h = headlines.sort(() => Math.random() - 0.5);
      setDisplayHeadline1(h[0]);
      setDisplayHeadline2(h[1]);
    }
    if (descriptions) {
      const d =
        descriptions.sort().join(' ').length < 200
          ? [...descriptions, ...headlines]
          : descriptions;
      setDisplayDescription(d.sort(() => Math.random() - 0.5).join(' '));
    }
    if (images) {
      if (category === 'search') {
        const i = images.sort(() => Math.random() - 0.5);
        setDisplayImage(i[0]?.url || '');
      }
      if (['display', 'performancemax'].includes(category)) {
        const i = images.sort(() => Math.random() - 0.5);
        if (typeof i[0] === 'string') {
          setDisplayImage(i[0]);
        } else if (typeof i[0] === 'object' && i[0].url) {
          setDisplayImage(i[0]?.url || '');
        }
      }
    }
    if (business_logo) {
      if (typeof business_logo === 'string') {
        console.log(`business_logo is string`);
        setBusinessLogoUrl(business_logo);
      } else if (Array.isArray(business_logo) && business_logo.length > 0) {
        console.log(`business_logo is array`);
        setBusinessLogoUrl(business_logo[0].url);
      } else if (business_logo?.url && business_logo?.url?.length > 0) {
        console.log(`business_logo is object`);
        setBusinessLogoUrl(business_logo.url);
      } else {
        console.log(`business_logo is empty`);
        setBusinessLogoUrl('');
      }
    }
  };

  useEffect(() => {
    setDisplays();
    const interval = setInterval(() => setDisplays(), 10000);

    console.log({
      images,
      business_logo,
      business_name,
      headlines,
      descriptions,
      sitelinks,
      url,
    });
    return () => clearInterval(interval);
  }, [headlines, descriptions]);

  return (
    <Box bgcolor={showToolbar ? `transparent` : `#f1f3f4`} sx={{}}>
      {showToolbar ? (
        <Box
          sx={{
            display: `flex`,
            justifyContent: `space-between`,
            marginBottom: 1,
            gap: 2,
          }}
        >
          <Typography variant="h6" color="primary" fontWeight={800}>
            Preview: {viewMode === 'desktop' ? 'Desktop' : 'Mobile'} View
          </Typography>
          <Box
            sx={{
              display: `flex`,
              justifyContent: `flex-end`,
              marginBottom: 1,
              gap: 2,
            }}
          >
            <Button
              onClick={setDisplays}
              sx={{
                backgroundColor: `white`,
                '&hover:backgroundColor': `#e6ffd4`,
                padding: 1,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                border: `1px solid #e8eaeb`,
                borderRadius: 2,
              }}
            >
              <IoRefresh size={24} />
            </Button>

            <Box
              sx={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
              }}
            >
              <Button
                onClick={() => {
                  setViewMode('mobile');
                }}
                sx={{
                  backgroundColor: viewMode === 'mobile' ? '#e6ffd4' : `white`,
                  '&hover:backgroundColor': `#e6ffd4`,
                  paddingY: 1,
                  paddingLeft: 2,
                  paddingRight: 1,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  border: `2px solid #e8eaeb`,
                  borderTopLeftRadius: '999px',
                  borderBottomLeftRadius: '999px',
                }}
              >
                <MdOutlinePhoneAndroid size={24} />
              </Button>
              <Button
                onClick={() => {
                  setViewMode('desktop');
                }}
                sx={{
                  backgroundColor: viewMode === 'desktop' ? '#e6ffd4' : `white`,
                  '&hover:backgroundColor': `#e6ffd4`,
                  paddingY: 1,
                  paddingLeft: 1,
                  paddingRight: 2,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  border: `2px solid #e8eaeb`,
                  borderTopRightRadius: '999px',
                  borderBottomRightRadius: '999px',
                }}
              >
                <FaLaptop size={24} />
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}
      {category === 'search' ? (
        <Box>
          {viewMode === 'mobile' ? (
            <Box
              bgcolor={`#f1f3f4`}
              sx={{
                paddingTop: 2,
                paddingX: 2,
                maxWidth: 420,
                marginLeft: `auto`,
                marginRight: center ? `auto` : 0,
              }}
            >
              <Box
                bgcolor={`#ffffff`}
                sx={{
                  borderTopLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderTop: `4px solid #e8eaeb`,
                  borderRight: `4px solid #e8eaeb`,
                  borderLeft: `4px solid #e8eaeb`,
                  paddingTop: 1,
                  paddingX: 2,
                  maxWidth: 420,
                  marginLeft: `auto`,
                }}
              >
                <Box
                  sx={{
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    paddingBottom: 1,
                  }}
                >
                  <FiCircle color="#d5d8da" />
                </Box>
                <Box
                  bgcolor={`#f1f3f4`}
                  sx={{
                    paddingTop: 2,
                    paddingX: 2,
                    paddingBottom: 1,
                    maxWidth: 420,
                    marginLeft: `auto`,
                  }}
                >
                  <Box
                    bgcolor={`white`}
                    sx={{
                      borderRadius: 2,
                      padding: 2,
                      boxShadow: 1,
                    }}
                  >
                    <Typography
                      fontFamily={`sans-serif`}
                      fontSize={12}
                      fontWeight={600}
                    >
                      Sponsored
                    </Typography>
                    <Box
                      sx={{
                        marginTop: 1,
                        marginBottom: 2,
                        display: `flex`,
                        gap: 1,
                        alignItems: `center`,
                        justifyContent: `start`,
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: '#e2eeff',
                          width: `fit-content`,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                          padding: `4px`,
                          borderRadius: `100%`,
                        }}
                      >
                        {businessLogoUrl ? (
                          <>
                            <img
                              src={businessLogoUrl}
                              alt={`business_logo`}
                              height={`auto`}
                              width={18}
                            />
                          </>
                        ) : (
                          <HiGlobeAmericas color="#0060f0" size={20} />
                        )}
                      </Box>
                      <Typography
                        fontFamily={`sans-serif`}
                        fontSize={12}
                        sx={{
                          overflow: `hidden`,
                          display: `-webkit-box`,
                          WebkitLineClamp: 2,
                          lineClamp: 2,
                          WebkitBoxOrient: `vertical`,
                          textWrap: `balance`,
                        }}
                      >
                        {displayUrl}
                      </Typography>
                    </Box>
                    <Typography
                      fontFamily={`sans-serif`}
                      fontSize={16}
                      color={`#1558d6`}
                      fontWeight={500}
                      sx={{
                        overflow: `hidden`,
                        display: `-webkit-box`,
                        WebkitLineClamp: 2,
                        lineClamp: 2,
                        WebkitBoxOrient: `vertical`,
                      }}
                    >
                      {displayHeadline1} - {displayHeadline2}
                    </Typography>

                    <Box>
                      {displayImage ? (
                        <Box
                          sx={{
                            display: `flex`,
                            justifyContent: `space-between`,
                            alignItems: `start`,
                            gap: 1,
                          }}
                        >
                          <Typography
                            fontFamily={`sans-serif`}
                            fontSize={14}
                            minWidth={100}
                            sx={{
                              overflow: `hidden`,
                              display: `-webkit-box`,
                              WebkitLineClamp: 4,
                              lineClamp: 4,
                              WebkitBoxOrient: `vertical`,
                            }}
                          >
                            {displayDescription}
                          </Typography>
                          <Box
                            sx={{
                              flexShrink: 0,
                            }}
                          >
                            {displayImage ? (
                              <>
                                <img
                                  src={displayImage}
                                  alt={displayImage}
                                  height={`auto`}
                                  width={`85px`}
                                ></img>
                              </>
                            ) : null}
                          </Box>
                        </Box>
                      ) : (
                        <Typography
                          fontFamily={`sans-serif`}
                          fontSize={14}
                          sx={{
                            overflow: `hidden`,
                            display: `-webkit-box`,
                            WebkitLineClamp: 4,
                            lineClamp: 4,
                            WebkitBoxOrient: `vertical`,
                          }}
                        >
                          {displayDescription}
                        </Typography>
                      )}
                    </Box>

                    <Box
                      sx={{
                        display: `flex`,
                        flexDirection: `row`,
                        justifyContent: `start`,
                        alignItems: `center`,
                        marginTop: 1,
                        gap: `10px`,
                        overflow: `hidden`,
                      }}
                    >
                      {sitelinks.map((sitelink, index) => (
                        <Typography
                          sx={{
                            flexShrink: 0,
                            border: `1px solid #e8eaeb`,
                            borderRadius: 2,
                            color: `#1558d6`,
                            paddingX: `8px`,
                            paddingY: `4px`,
                            fontSize: 14,
                          }}
                        >
                          {sitelink.headline}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}
          {viewMode === 'desktop' ? (
            <Box
              bgcolor={`white`}
              sx={{
                borderRadius: 2,
                padding: 2,
                boxShadow: 1,

                maxWidth: 680,
                marginLeft: `auto`,
              }}
            >
              <Typography
                fontFamily={`sans-serif`}
                fontSize={12}
                fontWeight={600}
              >
                Sponsored
              </Typography>
              <Box
                sx={{
                  marginTop: `5px`,
                  marginBottom: `5px`,
                  display: `flex`,
                  gap: 1,
                  alignItems: `center`,
                  justifyContent: `start`,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#e2eeff',
                    width: `fit-content`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    padding: `4px`,
                    borderRadius: `100%`,
                  }}
                >
                  {businessLogoUrl ? (
                    <>
                      <img
                        src={businessLogoUrl}
                        alt={business_logo.url}
                        height={`auto`}
                        width={18}
                      />
                    </>
                  ) : (
                    <HiGlobeAmericas color="#0060f0" size={20} />
                  )}
                </Box>
                <Typography fontFamily={`sans-serif`} fontSize={12}>
                  {displayUrl}
                </Typography>
              </Box>
              <Typography
                fontFamily={`sans-serif`}
                fontSize={16}
                color={`#1558d6`}
                fontWeight={500}
                sx={{
                  overflow: `hidden`,
                  display: `-webkit-box`,
                  WebkitLineClamp: 1,
                  lineClamp: 1,
                  WebkitBoxOrient: `vertical`,
                }}
              >
                {displayHeadline1} - {displayHeadline2}
              </Typography>

              <Typography
                fontFamily={`sans-serif`}
                fontSize={14}
                color={`#696969`}
                sx={{
                  overflow: `hidden`,
                  display: `-webkit-box`,
                  WebkitLineClamp: 4,
                  lineClamp: 4,
                  WebkitBoxOrient: `vertical`,
                }}
              >
                {displayDescription}
              </Typography>
              <Typography
                fontFamily={`sans-serif`}
                fontSize={14}
                color={`#1558d6`}
                sx={{
                  overflow: `hidden`,
                  display: `-webkit-box`,
                  WebkitLineClamp: 1,
                  lineClamp: 1,
                  WebkitBoxOrient: `vertical`,
                }}
              >
                {sitelinks
                  .map((sitelink, index) => sitelink.headline)
                  .join(' • ')}
              </Typography>
            </Box>
          ) : null}
        </Box>
      ) : null}
      {category === 'display' || category === 'performancemax' ? (
        <Box
          sx={{
            maxWidth: 420,
            marginLeft: `auto`,
            marginRight: center ? `auto` : 0,
            backgroundColor: '#ffffff',
            border: `1px solid #AAA`,
          }}
        >
          <Box>
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  height: 24,
                  width: 24,
                  backgroundColor: '#f1f3f4',
                }}
              >
                <MdInfoOutline color="#00aecd" size={24} />
              </Box>
              <img
                src={displayImage}
                alt=""
                style={{
                  width: `100%`,
                }}
              />
            </Box>
            <Box sx={{ padding: 1 }}>
              <Typography sx={{ fontWeight: 600 }}>
                {displayHeadline1} {displayHeadline2}
              </Typography>
              <Typography sx={{ display: `none` }}>
                <PiCaretCircleRightFill />
              </Typography>
              <Typography sx={{ marginTop: 1 }}>{displayName}</Typography>
              <Box
                sx={{
                  textAlign: 'right',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography sx={{ display: `inline` }}>Open</Typography>
                <RxCaretRight style={{ display: `inline` }} size={30} />
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: `none`, gap: 1 }}>
            <Box
              sx={{
                display: `flex`,
                gap: 1,
                alignItems: `center`,
                marginBottom: 1,
              }}
            >
              <Skeleton
                variant="circular"
                width={60}
                height={60}
                sx={{ flexShrink: 0 }}
              />
              <div style={{ width: `100%` }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <Skeleton variant="rounded" height={40} width={`100%`} />
              </div>
            </Box>
            <Skeleton variant="rectangular" height={300} />
            <Box sx={{ display: `none`, gap: 1 }}>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="circular" width={40} height={40} />
              <Skeleton variant="rounded" height={400} />
              <Skeleton variant="rectangular" width={210} height={60} />
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default GoogleTemplatesPreview;
