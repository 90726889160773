import React, { FormEvent, useContext, useEffect, useState } from 'react';
import {
  Brand,
  ChangeEventType,
  FbCampaignAdSetForm,
  FbCampaignForm,
  FbTargetSearch,
  Campaign,
  StripeCustomerForm,
  StripeCreateSetupIntentForm,
  StripeCustomerBalanceCredit,
  FBLeadGenData,
  FBLeadGenQuestionForm,
  FBLeadGenForm,
  FbAssetFeedSpecMedia,
  FbCreativeForm,
  Geolocation,
  CampaignAd,
} from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import {
  buildAdNamingConvention,
  editDiySteps,
} from '../../../utils/helpers/DiyHelpers';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import styles from '../../../assets/styles/components/Forms/Form.module.scss';
import CircularLoading from '../../CircularLoading';
import PageHeader from '../../PageHeader';
import LinearProgressBarWithLabel from '../../ProgressBar/LinearProgressBarWithLabel';
import { PrimaryButton } from '../../Buttons';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import AdsTemplateListTable from '../../Tables/TemplateList/AdsTemplateListTable';
import { AdsTemplate, DiySettings } from '../../../types/IDiy';
import {
  editCampaign,
  fetchCampaignTemplates,
  getSingleDiySetting,
  rebuildTemplate,
} from '../../../services/diy';
import { Objectives, adsProvider } from '../../../utils/constants/facebookAds';
import { DrawerMenu } from '../../Menu';
import { useParams } from 'react-router';
import DiyAudienceForm from './DiyAudienceForm';
import DiyTargetMarketForm from './DiyTargetMarketForm';
import {
  createFacebookGenForms,
  duplicateFbAdAccountImages,
  fetchBrandCampaigns,
  fetchSpecificFacebookGenForms,
  getFbPageDefaultIgAccount,
  getFbTargetingReachEstimate,
  getSingleBrandAd,
} from '../../../services/ads';
import DiyAudienceSize from './DiyAudienceSize';
import footer from '../../../assets/images/audience.png';
import DiyUpdateAdBudgetForm from './DiyUpdateAdBudgetForm';
import DiyAdPreview from './DiyAdPreview';
import { buildBrandAddress } from '../../../utils/helpers/BrandHelpers';
import PopupModal from '../../Modal';
import AdMediaForm from '../Ads/AdMediaForm';
import DiyAdDetailsForm from './DiyAdDetailsForm';
import { AuthContext } from '../../../context';
import { ADMIN, SUPER_ADMIN } from '../../../utils';
import DiyBillingSummary from './DiyBillingSummary';
import { createStripeSetupIntent } from '../../../services/stripe/paymentIntent';
import {
  createOrUpdateStripeCustomer,
  fetchSingleStripeCustomer,
  getCustomerCreditBalances,
  getCustomerPaymentMethods,
} from '../../../services/stripe/customer';
import StripePaymentTermsModal from '../../Modal/StripePaymentTermsModal';
import StripePaymentUIModal from '../../Modal/StripePaymentUIModal';
import { setBrand, setLocation, toggleAlert } from '../../../redux/actions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { errorMessageParser } from '../../../utils/helpers/ToastHelper';
import momentTz from 'moment-timezone';
import { humanizeString } from '../../../utils/stringModifier';
import { useForm } from 'react-hook-form';
import FacebookAdDetails from '../../Details/Ads/FacebookAdDetails';
import { getSingleCampaignAnalytics } from '../../../services/report';
import { getSingleAdBudget } from '../../../services/budget';
import { calculateTotalBudget } from '../../../utils/helpers/BudgetHelper';

const EditDiyForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const adId: string = params.adId;
  const [searchParams] = useSearchParams();
  const fields: string[] = searchParams.get('fields')?.split(',');
  const isRestart: boolean = searchParams.get('restart') === 'true';
  const isTemplateChanged = fields.includes('template');
  const isBudgetChanged = fields.includes('budget');
  const isAudienceChanged = fields.includes('audience');
  const isGeographicsChanged = fields.includes('geographics');
  const isFormChanged = fields.includes('form');
  const isWebsiteUrlChanged = fields.includes('website');
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const timezone: string = useSelector(
    (state: any) => state?.brandTimezone?.timezone,
  );
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const { state } = useContext(AuthContext);
  const role = state.role;
  const isSuperAdmin = role === SUPER_ADMIN;
  const isAdmin = role === ADMIN;

  const [activeStep, setActiveStep] = useState<string>(null);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [templates, setTemplates] = useState<AdsTemplate[]>([]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<AdsTemplate>(null);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [diySettings, setDiySettings] = useState<DiySettings>(null);
  const [currentTemplate, setCurrentTemplate] = useState<AdsTemplate>(null);
  const [adSetTemplate, setAdSetTemplate] = useState<FbCampaignAdSetForm>(null);
  const [campaignTemplate, setCampaignTemplate] =
    useState<FbCampaignForm>(null);
  const [showExclusions, setShowExclusions] = useState<boolean>(false);
  const [showNarrowAudience, setShowNarrowAudience] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [geoLocationTemplate, setGeoLocationTemplate] = useState<any>(null);
  const [excludedGeoLocationTemplate, setExcludedGeoLocationTemplate] =
    useState<any>(null);
  const [excludeGeolocation, setExcludeGeolocation] = useState<boolean>(false);
  const [reachEstimate, setReachEstimate] = useState<any>({
    users_lower_bound: 0,
    users_upper_bound: 0,
  });
  const [reachError, setReachError] = useState<string>(null);
  const [sizeLoading, setSizeLoading] = useState<boolean>(false);
  const [newMonthlyBudget, setNewMonthlyBudget] = useState<number>(null);
  const [totalCharge, setTotalCharge] = useState<number>(null);
  const [isDecrease, setIsDecrease] = useState<boolean>(false);
  const [openMediaForm, setOpenMediaForm] = useState<boolean>(false);
  const [editMedia, setEditMedia] = useState<boolean>(false);
  const [editRatio, setEditRatio] = useState<string>(null);
  const [parsedBody, setParsedBody] = useState<string>(null);
  const [campaignNameTag, setCampaignNameTag] = useState<string>('');
  const [adSetNameTag, setAdSetNameTag] = useState<string>('');
  const [adNameTag, setAdNameTag] = useState<string>('');
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [nameBuilt, setNameBuilt] = useState<boolean>(false);
  const [customerForm, setCustomerForm] = useState<StripeCustomerForm>({
    address: {
      city: '',
      country: 'US',
      line1: '',
      line2: '',
      postal_code: '',
      state: '',
    },
    email: '',
    name: '',
    phone: '',
    metadata: {
      user_id: (location || brand)?._id,
      role: 'brand',
    },
  });
  const [customer, setCustomer] = useState<any>(null);
  const [openTermsModal, setOpenTermsModal] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<string>(null);
  const [clientSecret, setClientSecret] = useState<string>(null);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<string>(null);
  const [changeType, setChangeType] = useState<string>('immediate');
  const [creditBalance, setCreditBalance] =
    useState<StripeCustomerBalanceCredit>(null);
  const [templateRebuilded, setTemplateRebuilded] = useState<boolean>(false);
  const [leadFormValues, setLeadFormValues] = useState<FBLeadGenForm>(null);
  const [leadForm, setLeadForm] = useState<FBLeadGenData>(null);
  const [brandDiySettings, setBrandDiySettings] = useState<DiySettings>(null);
  const [enableAdvantageLookalike, setEnableAdvantageLookalike] =
    useState<boolean>(true);
  const [enableAdvantageDetailed, setEnableAdvantageDetailed] =
    useState<boolean>(true);
  const [newVideoIds, setNewVideoIds] = useState<string[]>([]);
  const [confirmPayment, setConfirmPayment] = useState<boolean>(false);
  const [ad, setAd] = useState<CampaignAd>(null);
  const [thruYesterdaySpend, setThruYesterdaySpend] = useState<number>(0);
  const [todaySpend, setTodaySpend] = useState<number>(0);
  const [rebuildLoading, setRebuildLoading] = useState<boolean>(false);
  const [templatesLoading, setTemplatesLoading] = useState<boolean>(false);

  const isEmployment =
    currentTemplate?.campaignTemplate?.special_ad_categories?.includes(
      'EMPLOYMENT',
    );
  const isSpecialAds =
    currentTemplate?.campaignTemplate?.special_ad_categories?.length > 0;

  const {
    formState: { errors },
    handleSubmit,
    register,
    clearErrors,
    setValue,
    setError,
  } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (adId) getRebuildedTemplate();
  }, [adId, location, brand]);

  useEffect(() => {
    if (selectedTemplate?.adSetTemplate) {
      let temp: AdsTemplate = { ...selectedTemplate };

      temp.adSetTemplate.targeting = {
        ...selectedTemplate.adSetTemplate.targeting,
        geo_locations: {
          ...geoLocationTemplate,
        },
        excluded_geo_locations: {
          ...excludedGeoLocationTemplate,
        },
      };

      setSelectedTemplate(temp);
    }
  }, [geoLocationTemplate, excludedGeoLocationTemplate]);

  useEffect(() => {
    if (location) {
      getDiySettings();
      getBrandDiySettings(brand);
    } else {
      getDiySettings();
      if (brand?.__type === 'location') {
        getBrandDiySettings(brand?.franchisor);
      }
    }

    getCampaigns();
  }, [location, brand]);

  useEffect(() => {
    if (location || brand) {
      if (isTemplateChanged) getTemplates();
      getCustomerCredits();
    }
  }, [location, brand, isTemplateChanged]);

  useEffect(() => {
    if (currentTemplate && !isTemplateChanged && !templateRebuilded && ad) {
      rebuildSelectedTemplate(selectedTemplate);
      setTemplateRebuilded(true);
    }
  }, [currentTemplate, isTemplateChanged, ad]);

  useEffect(() => {
    if (customer && (location || brand)) getPaymentMethods(location || brand);
  }, [customer, location, brand, openPaymentModal]);

  useEffect(() => {
    if (currentTemplate) {
      const customLocations = [
        {
          latitude: (location || brand)?.address?.lat,
          longitude: (location || brand)?.address?.lng,
          distance_unit: 'mile',
          radius: diySettings?.maxAddressRadius || 50,
        },
      ];
      setGeoLocationTemplate({
        ...(isEmployment
          ? {
              custom_locations: customLocations,
            }
          : currentTemplate?.adSetTemplate?.targeting?.geo_locations),
        custom_locations: diySettings?.locationTypes?.includes('address')
          ? [...customLocations]
          : [],
      });

      setSelectedAddress((location || brand)?.address);
    }
  }, [currentTemplate, isEmployment, isSpecialAds]);

  useEffect(() => {
    if (!isSpecialAds) {
      setExcludedGeoLocationTemplate({
        ...currentTemplate?.adSetTemplate?.targeting?.excluded_geo_locations,
      });
    }
  }, [brand, location, isEmployment, isSpecialAds]);

  useEffect(() => {
    if (
      editDiySteps(
        location || brand,
        isTemplateChanged,
        isBudgetChanged,
        isAudienceChanged,
        isGeographicsChanged,
        isTemplateChanged || isFormChanged || isWebsiteUrlChanged,
      ).length > 0
    ) {
      const step = editDiySteps(
        location || brand,
        isTemplateChanged,
        isBudgetChanged,
        isAudienceChanged,
        isGeographicsChanged,
        isTemplateChanged || isFormChanged || isWebsiteUrlChanged,
      )?.find((value: string, index: number) => {
        return index === activeStepIndex;
      });

      setActiveStep(step);
    }
  }, [
    editDiySteps(
      location || brand,
      isTemplateChanged,
      isBudgetChanged,
      isAudienceChanged,
      isGeographicsChanged,
      isTemplateChanged || isFormChanged || isWebsiteUrlChanged,
    ),
    activeStepIndex,
  ]);

  useEffect(() => {
    if (
      activeStep === 'Target Market' &&
      selectedTemplate?.adSetTemplate?.targeting
    ) {
      let temp: any = { ...selectedTemplate?.adSetTemplate?.targeting };
      let geolocations: any = {
        ...selectedTemplate?.adSetTemplate?.targeting?.geo_locations,
      };
      let excludedGeolocations: any = {
        ...selectedTemplate?.adSetTemplate?.targeting?.excluded_geo_locations,
      };

      if (selectedTemplate?.adSetTemplate?.targeting?.geo_locations) {
        temp = { ...temp, geo_locations: geolocations };
      }

      if (selectedTemplate?.adSetTemplate?.targeting?.excluded_geo_locations) {
        temp = { ...temp, excluded_geo_locations: excludedGeolocations };
      }

      getFacebookTargetReach(temp);
    }
  }, [activeStep, selectedTemplate?.adSetTemplate?.targeting]);

  useEffect(() => {
    if (activeStep === 'Billing Summary' && (location || brand)) {
      getSingleStripeCustomer(location || brand);
    }

    if (activeStep === 'Templates') {
      setNameBuilt(false);
      // TODO: Re-activate if allowed to edit start date
      // setDateSet(false);
    }

    if (
      activeStep === 'Ad Preview' &&
      (location || brand) &&
      selectedTemplate
    ) {
      let temp: AdsTemplate = { ...selectedTemplate };

      let originalString: string =
        temp?.creativeTemplate?.asset_feed_spec?.bodies[0]?.text;

      if (!parsedBody) setParsedBody(originalString);

      let parsedCustomField: any[] = [];

      temp.customFields?.forEach((custom: any) => {
        let params: any = { ...custom };

        switch (params.field) {
          case '{{address}}':
            params = { ...params, value: buildBrandAddress(location || brand) };
            originalString = originalString.replace(
              params.field,
              buildBrandAddress(location || brand),
            );
            break;
          case '{{phone_number}}':
            params = {
              ...params,
              value: (location || brand)?.phoneNumber || '',
            };
            originalString = originalString.replace(
              params.field,
              (location || brand)?.phoneNumber || '',
            );
            break;
          default:
            params = { ...params, value: params.field };
            originalString = originalString.replace(params.field, params.field);
            break;
        }

        parsedCustomField = [...parsedCustomField, params];
      });

      temp = { ...temp, customFields: parsedCustomField };
      temp.creativeTemplate.asset_feed_spec.bodies[0].text = originalString;
      setSelectedTemplate(temp);
    }
  }, [activeStep, brand, location, campaigns]);

  useEffect(() => {
    if (activeStep === 'Budget' && (location || brand) && ad) {
      (async () => {
        const startRange = momentTz()
          .tz(timezone)
          .startOf('month')
          .startOf('day');
        const endRange = momentTz().tz(timezone).subtract(1, 'day');
        if (activeStep === 'Budget') {
          const thruYesterdaySpend = await getSingleCampaignAnalytics(
            (location || brand)?._id,
            startRange,
            endRange,
            'adset',
            ad?.adset_id,
          );

          setThruYesterdaySpend(
            parseFloat(thruYesterdaySpend?.data?.spend || 0),
          );

          const currentDate = momentTz().tz(timezone);
          const todaySpend = await getSingleCampaignAnalytics(
            (location || brand)?._id,
            currentDate,
            currentDate,
            'adset',
            ad?.adset_id,
          );

          setTodaySpend(parseFloat(todaySpend?.data?.spend || 0));
        }
      })();
    }
  }, [activeStep, ad, brand, location]);

  useEffect(() => {
    if (
      selectedTemplate?.campaignTemplate?.objective ===
        Objectives.OUTCOME_LEADS &&
      !leadForm &&
      (location || brand)?.__type === 'location'
    ) {
      getLeadForm();
    }
  }, [
    brand,
    location,
    selectedTemplate?.campaignTemplate?.objective,
    leadForm,
  ]);

  const getTemplates = async () => {
    try {
      setTemplatesLoading(true);

      const response = await fetchCampaignTemplates(
        (location || brand)?.__type === 'location'
          ? (location || brand)?.franchisor?._id
          : (location || brand)?._id,
        adsProvider.FACEBOOK,
        true,
      );

      setTemplates(response.data);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setTemplatesLoading(false);
    }
  };

  const getFacebookTargetReach = async (targeting: FbTargetSearch) => {
    setSizeLoading(true);
    try {
      const response = await getFbTargetingReachEstimate(
        (location || brand)?._id,
        targeting,
        selectedTemplate?.adSetTemplate?.optimization_goal,
      );

      setReachEstimate(response.data);
      setReachError(null);
    } catch (error: any) {
      const err = error.response.data;
      console.log(err);
      setReachEstimate({
        users_lower_bound: 0,
        users_upper_bound: 0,
      });
      setReachError(err?.message);
    } finally {
      setSizeLoading(false);
    }
  };

  const getDiySettings = async () => {
    try {
      const response = await getSingleDiySetting((location || brand)?._id);

      setDiySettings(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getRebuildedTemplate = async () => {
    try {
      setFetchLoading(true);

      const response = await rebuildTemplate(adId);

      let tempAdSets: FbCampaignAdSetForm = {
        ...response.data.adSetTemplate,
        totalBudget: 0,
      };

      setEnableAdvantageDetailed(
        response.data?.adSetTemplate?.targeting?.targeting_optimization ===
          'expansion_all',
      );
      setEnableAdvantageLookalike(
        response.data?.adSetTemplate?.targeting?.targeting_relaxation_types
          ?.lookalike === 1,
      );

      setCurrentTemplate(response.data);
      setCampaignTemplate(response.data.campaignTemplate);

      const ad = await getSingleBrandAd(adId, (location || brand)?._id);

      setAd(ad.data);

      const currentDate = momentTz().tz(timezone);
      const currentMonth = currentDate.clone().month() + 1;
      const currentYear = currentDate.clone().year();

      const currentBudget = await getSingleAdBudget(
        (location || brand)?._id,
        currentMonth,
        currentYear,
        'facebook',
        ad.data.adset_id,
      );

      tempAdSets = {
        ...tempAdSets,
        totalBudget: currentBudget?.data?.totalBudget || 0,
      };

      setAdSetTemplate(tempAdSets);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getCampaigns = async () => {
    try {
      const campaigns = await fetchBrandCampaigns(
        (location || brand)?._id,
        'facebook',
        true,
      );

      setCampaigns(campaigns?.data || []);
      return campaigns?.data || [];
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getPaymentMethods = async (brand: Brand) => {
    try {
      const response = await getCustomerPaymentMethods(brand?._id);
      let temp: any = [...(response?.data || [])];
      temp = temp.sort((a: any) => {
        if (customer.invoice_settings.default_payment_method === a.id) {
          return -1;
        }

        return 1;
      });

      setPaymentMethods(temp);
      if (temp.length > 0) {
        setPaymentMethod(temp[0]?.id);
      }
      setLoading(false);
    } catch (error: any) {
      setPaymentMethods([]);

      console.log(error);
    }
  };

  const getSingleStripeCustomer = async (brand: Brand) => {
    try {
      const { data } = await fetchSingleStripeCustomer(brand?._id, 'brand');

      setCustomer(data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getCustomerCredits = async () => {
    try {
      const response = await getCustomerCreditBalances(
        (location || brand)?._id,
      );

      if (response.data.length > 0) {
        setCreditBalance(response.data[0]);
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getPageDefaultIgAccount = async () => {
    try {
      const response = await getFbPageDefaultIgAccount(
        diySettings?.facebookPage?.fbPageId,
        (location || brand)?._id,
      );

      return response.data;
    } catch (error: any) {
      console.log(error.message);
      return { error: 'Please setup your Instagram Account first.' };
    }
  };

  const getLeadForm = async () => {
    try {
      setLoading(true);

      const response = await fetchSpecificFacebookGenForms(
        ((location || brand)?.__type === 'location'
          ? (location || brand)?.franchisor
          : location || brand
        )?._id,
        selectedTemplate?.creativeTemplate?.asset_feed_spec?.call_to_actions[0]
          ?.value?.lead_gen_form_id,
      );

      let data: FBLeadGenData = response.data;

      let contextCardData: any;
      if (data?.context_card) {
        const {
          id: contextCardId,
          cover_photo,
          ...contextCard
        } = data.context_card;

        contextCardData = contextCard;
      }

      const { id: thankPageId, ...thankYouPage } = data.thank_you_page;
      const questions = data.questions.map(
        (question: FBLeadGenQuestionForm) => {
          const { id, ...questions } = question;

          if (questions.type !== 'CUSTOM') {
            delete questions['label'];
          }

          return questions;
        },
      );
      let temp: FBLeadGenForm = {
        ...leadFormValues,
        context_card: contextCardData ? { ...contextCardData } : null,
        name: `${(location || brand)?.brand}-${momentTz().tz(timezone).unix()}`,
        privacy_policy: {
          url: data.legal_content.privacy_policy.url,
          link_text: data.legal_content.privacy_policy.link_text,
        },
        questions: [...questions],
        thank_you_page: { ...thankYouPage },
        block_display_for_non_targeted_viewer:
          data.block_display_for_non_targeted_viewer,
        locale: data.locale as any,
        allow_organic_lead: data.allow_organic_lead,
        is_optimized_for_quality: data.is_optimized_for_quality,
        follow_up_action_url: data.follow_up_action_url,
        question_page_custom_headline: data.question_page_custom_headline,
      };

      setLeadFormValues(temp);

      setLeadForm(data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getBrandDiySettings = async (brand: Brand) => {
    try {
      const response = await getSingleDiySetting(brand?._id);

      setBrandDiySettings(response.data);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const createLeadForm = async () => {
    try {
      const response = await createFacebookGenForms(
        (location || brand)?._id,
        leadFormValues,
      );

      return response.data;
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const handleOnSubmit = async () => {
    setLoading(true);

    const progressPercentage =
      progress +
      (1 /
        editDiySteps(
          location || brand,
          isTemplateChanged,
          isBudgetChanged,
          isAudienceChanged,
          isGeographicsChanged,
          isTemplateChanged || isFormChanged || isWebsiteUrlChanged,
        ).length) *
        100;

    setProgress((prevProgress) =>
      progressPercentage >= 100
        ? 100
        : prevProgress +
          (1 /
            editDiySteps(
              location || brand,
              isTemplateChanged,
              isBudgetChanged,
              isAudienceChanged,
              isGeographicsChanged,
              isTemplateChanged || isFormChanged || isWebsiteUrlChanged,
            ).length) *
            100,
    );

    try {
      let params: AdsTemplate = { ...selectedTemplate };

      const hasSpecialCategory =
        params?.campaignTemplate?.special_ad_categories.length > 0;

      if (isAudienceChanged || isGeographicsChanged) {
        switch (params?.audienceType) {
          case 'custom':
            params = {
              ...params,
              adSetTemplate: {
                ...params?.adSetTemplate,
                targeting: {
                  ...adSetTemplate?.targeting,
                  targeting_relaxation_types: {
                    lookalike: enableAdvantageLookalike ? 1 : 0,
                    custom_audience: 0,
                  },
                  geo_locations:
                    params?.adSetTemplate?.targeting?.geo_locations,
                  excluded_geo_locations:
                    params?.adSetTemplate?.targeting?.excluded_geo_locations,
                },
              },
            };
            break;
          default:
            params = {
              ...params,
              adSetTemplate: {
                ...params?.adSetTemplate,
                targeting: {
                  ...adSetTemplate?.targeting,
                  geo_locations:
                    params?.adSetTemplate?.targeting?.geo_locations,
                  excluded_geo_locations:
                    params?.adSetTemplate?.targeting?.excluded_geo_locations,
                  targeting_optimization:
                    params?.campaignTemplate?.objective ===
                      Objectives.OUTCOME_LEADS && enableAdvantageDetailed
                      ? 'expansion_all'
                      : null,
                  custom_audiences: [],
                },
              },
            };
            break;
        }
      }

      if (isBudgetChanged && (location || brand)?.allowSetupPayment) {
        params = {
          ...params,
          paymentMethod,
        };
      }

      if (isTemplateChanged || isFormChanged || isWebsiteUrlChanged) {
        let instagramId: string = '';

        if (!diySettings?.instagramAccount?._id) {
          if (diySettings?.facebookPage?.instagramId) {
            instagramId = diySettings?.facebookPage?.instagramId;
          } else {
            const response = await getPageDefaultIgAccount();
            if (response.error)
              return dispatch(
                toggleAlert({
                  toggle: true,
                  message: response.error,
                  type: 'error',
                }),
              );
            instagramId = response.id;
          }
        } else {
          instagramId = diySettings?.instagramAccount?.instagramId;
        }

        params = {
          ...params,
          creativeTemplate: {
            ...params?.creativeTemplate,
            name: `${params?.category}-${humanizeString(
              params?.media,
            )}-${momentTz().tz(timezone).unix()}`,
            object_story_spec: {
              ...params?.creativeTemplate?.object_story_spec,
              page_id: diySettings?.facebookPage?.fbPageId,
              instagram_actor_id: instagramId,
            },
            asset_feed_spec: {
              ...params?.creativeTemplate?.asset_feed_spec,
              call_to_actions: [
                {
                  ...params?.creativeTemplate?.asset_feed_spec
                    ?.call_to_actions[0],
                  value: {
                    ...params?.creativeTemplate?.asset_feed_spec
                      ?.call_to_actions[0]?.value,
                    lead_gen_form_id:
                      campaignTemplate?.objective === Objectives.OUTCOME_LEADS
                        ? params?.creativeTemplate?.asset_feed_spec
                            ?.call_to_actions[0]?.value?.lead_gen_form_id
                        : null,
                    link:
                      campaignTemplate?.objective === Objectives.OUTCOME_LEADS
                        ? null
                        : params?.creativeTemplate?.asset_feed_spec
                            ?.call_to_actions[0]?.value?.link,
                  },
                },
              ],
              asset_customization_rules: [
                {
                  ...params?.creativeTemplate?.asset_feed_spec
                    ?.asset_customization_rules[0],
                  customization_spec: {
                    ...params?.creativeTemplate?.asset_feed_spec
                      ?.asset_customization_rules[0]?.customization_spec,
                    publisher_platforms: ['facebook', 'instagram'],
                    facebook_positions: [
                      'feed',
                      'video_feeds',
                      'marketplace',
                      'search',
                    ],
                    instagram_positions: [
                      'stream',
                      'ig_search',
                      'profile_feed',
                      'explore',
                      'explore_home',
                    ],
                  },
                },
                {
                  ...params?.creativeTemplate?.asset_feed_spec
                    ?.asset_customization_rules[1],
                  customization_spec: {
                    ...params?.creativeTemplate?.asset_feed_spec
                      ?.asset_customization_rules[1]?.customization_spec,
                    publisher_platforms: [
                      'facebook',
                      'instagram',
                      'messenger',
                      'audience_network',
                    ],
                    facebook_positions: ['story', 'facebook_reels'],
                    instagram_positions: ['reels', 'story'],
                    messenger_positions: ['story'],
                    audience_network_positions: [],
                  },
                },
              ],
            },
            degrees_of_freedom_spec: {
              creative_features_spec: {
                standard_enhancements: {
                  enroll_status: 'OPT_OUT',
                },
              },
            },
            contextual_multi_ads: {
              enroll_status: 'OPT_OUT',
            },
          },
          adTemplate: {
            ...params?.adTemplate,
            template: selectedTemplate?._id,
          },
        };

        let formId: string = '';

        if (
          params?.campaignTemplate?.objective === Objectives.OUTCOME_LEADS &&
          diySettings?.allowSetupOwnAccount &&
          diySettings?.facebookPage?.fbPageId !==
            brandDiySettings?.facebookPage?.fbPageId &&
          (location || brand)?.__type === 'location'
        ) {
          const leadForm: any = await createLeadForm();

          if (leadForm) {
            formId = leadForm.id;

            params.creativeTemplate.asset_feed_spec.call_to_actions[0].value = {
              ...params.creativeTemplate.asset_feed_spec.call_to_actions[0]
                .value,
              lead_gen_form_id: formId,
            };
          }
        }

        /**
         * Duplicate Ad Image from one ad account to another
         */
        if (params?.accountId !== diySettings?.fbAdAccount?.accountId) {
          if (params?.media === 'image') {
            params?.creativeTemplate?.asset_feed_spec?.images?.forEach(
              async (image: FbAssetFeedSpecMedia) => {
                const data: any = {
                  source_account_id: params?.accountId,
                  hash: image?.hash,
                };

                await duplicateFbAdAccountImages(
                  (location || brand)?._id,
                  data,
                );
              },
            );
          } else {
            return dispatch(
              toggleAlert({
                toggle: true,
                message:
                  'The template was created using different ad account. Try to use a different one or update your template.',
                type: 'error',
              }),
            );
            /**
           * TODO: Re-enable this when needed
           *
          const response = await duplicateVideo(params);

          console.log(response);
          */
          }
        }
      }

      if (hasSpecialCategory) {
        params = {
          ...params,
          campaignTemplate: {
            ...params.campaignTemplate,
            special_ad_category_country: ['US'],
          },
        };
      }

      if (params?.accountId !== diySettings?.fbAdAccount?.accountId) {
        if (params?.media === 'image') {
          params?.creativeTemplate?.asset_feed_spec?.images?.forEach(
            async (image: FbAssetFeedSpecMedia) => {
              const data: any = {
                source_account_id: params?.accountId,
                hash: image?.hash,
              };

              await duplicateFbAdAccountImages((location || brand)?._id, data);
            },
          );
        } else {
          return dispatch(
            toggleAlert({
              toggle: true,
              message:
                'The template was created using different ad account. Try to use a different one or update your template.',
              type: 'error',
            }),
          );
          /**
           * TODO: Re-enable this when needed
           *
          const response = await duplicateVideo(params);

          console.log(response);
          */
        }
      }

      params = {
        ...params,
        isTargetChanged: isAudienceChanged || isGeographicsChanged,
        isCreativeChanged:
          isTemplateChanged || isFormChanged || isWebsiteUrlChanged,
        isBudgetChanged,
        isCampaignChanged:
          currentTemplate?.campaignTemplate?.name !==
          params?.campaignTemplate?.name,
        isAudienceChanged,
        isGeographicsChanged,
        isFormChanged,
        isTemplateChanged,
      };

      const response = await editCampaign(adId, params, isRestart);

      const isPaused = response.data.isPaused;
      const resumeDate = response.data.resumeDate;
      const thisMonthsBudget = response.data.thisMonthsBudget;
      const budgetChanged = response.data.isBudgetChanged;

      const message = isPaused
        ? `Your ad has been paused due to an insufficient budget. It will resume on ${resumeDate}`
        : 'Ad updated successfully';

      dispatch(
        toggleAlert({
          toggle: true,
          message,
        }),
      );

      const isPayment =
        (isBudgetChanged && (location || brand)?.allowSetupPayment) ||
        budgetChanged;

      const redirectUrl = `/adz${isPayment ? '?fromUpdateBudget=true' : ''}${
        isPayment && isDecrease && response.data.totalCredit > 0
          ? `&creditAmount=${response.data.totalCredit}`
          : ''
      }${
        isPayment && thisMonthsBudget > 0
          ? `&thisMonthsBudget=${thisMonthsBudget}`
          : ''
      }`;

      navigate(redirectUrl);
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleCreateCustomer = async (e: FormEvent) => {
    e.preventDefault();
    handleCloseTermsModal();

    try {
      setLoading(true);

      const params: StripeCustomerForm = {
        ...customerForm,
        metadata: {
          user_id: (location || brand)?._id,
          role: 'brand',
        },
      };
      const customerData = await createOrUpdateStripeCustomer(
        params,
        (location || brand)?._id,
        'brand',
      );

      if (customerData?.data) {
        let temp: Brand = {
          ...(location || brand),
          stripe: {
            ...(location || brand)?.stripe,
            customerId: customerData.data.id,
          },
        };

        if (location) {
          dispatch(setLocation(temp));
        } else {
          dispatch(setBrand(temp));
        }

        const params: StripeCreateSetupIntentForm = {
          customer: customerData.data.id,
          payment_method_types: ['card'],
          metadata: {
            user_id: (location || brand)?._id,
            role: 'brand',
            action: 'payment_method',
          },
        };

        const setupIntent = await createStripeSetupIntent(
          params,
          (location || brand)?._id,
        );

        handleOpenPaymentModal(setupIntent.data.client_secret, 'setup');
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    if (activeStep === 'Templates') {
      setNameBuilt(true);
    }

    setActiveStepIndex((prev) => prev + 1);
    setProgress(
      (prevProgress) =>
        prevProgress +
        (1 /
          editDiySteps(
            location || brand,
            isTemplateChanged,
            isBudgetChanged,
            isAudienceChanged,
            isGeographicsChanged,
            isTemplateChanged || isFormChanged || isWebsiteUrlChanged,
          ).length) *
          100,
    );
  };

  const handleBack = () => {
    setActiveStepIndex((prev) => prev - 1);
    setProgress(
      (prevProgress) =>
        prevProgress -
        (1 /
          editDiySteps(
            location || brand,
            isTemplateChanged,
            isBudgetChanged,
            isAudienceChanged,
            isGeographicsChanged,
            isTemplateChanged || isFormChanged || isWebsiteUrlChanged,
          ).length) *
          100,
    );
  };

  const handleOpenPreview = (template: AdsTemplate) => {
    setSelectedTemplate(template);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const rebuildSelectedTemplate = async (template: AdsTemplate) => {
    try {
      setRebuildLoading(true);

      let temp: AdsTemplate = { ...template };

      const {
        _id,
        campaignTemplate,
        adSetTemplate,
        adTemplate,
        creativeTemplate,
        name,
        description,
        category,
        objective,
        media,
        targets,
        audienceType,
        customAudienceType,
        images,
        videos,
        paymentMethod,
        limited,
        amount,
        customFields,
        mediaEditable,
        urlLocalizable,
        leadAdTool,
      }: AdsTemplate = currentTemplate;

      let tempCreative: FbCreativeForm = { ...creativeTemplate };

      if (campaignTemplate?.objective === Objectives.OUTCOME_TRAFFIC) {
        tempCreative = {
          ...tempCreative,
          asset_feed_spec: {
            ...tempCreative?.asset_feed_spec,
            call_to_actions: [
              {
                adlabels: [{ name: 'cta_1' }],
                type: tempCreative?.asset_feed_spec?.call_to_action_types[0],
                value: {
                  link: tempCreative?.asset_feed_spec?.link_urls[0].website_url,
                },
              },
            ],
          },
        };
      }

      const currentDate = momentTz().tz(timezone);
      const currentMonth = currentDate.clone().month() + 1;
      const currentYear = currentDate.clone().year();
      let futureMonth = currentMonth + 1;
      let futureYear = currentYear;
      if (futureMonth > 12) {
        futureMonth = 1;
        futureYear = futureYear++;
      }

      const futureBudget = await getSingleAdBudget(
        (location || brand)?._id,
        futureMonth,
        futureYear,
        'facebook',
        ad.adset_id,
      );

      const nextMonth = currentDate.clone().add(1, 'month');
      const nextMonthDays = nextMonth.daysInMonth();

      if (futureBudget?.data) {
        setValue('futureBudget', futureBudget?.data?.totalBudget);
      }

      temp = {
        ...temp,
        _id: isTemplateChanged ? temp?._id : _id,
        campaignTemplate: { ...campaignTemplate, name: campaignTemplate?.name },
        adSetTemplate: {
          ...adSetTemplate,
          name: adSetTemplate?.name,
          futureBudget: futureBudget?.data?.totalBudget,
          futureDailyBudget: Number(
            (futureBudget?.data?.totalBudget || 0 / nextMonthDays).toFixed(2),
          ),
        },
        adTemplate: {
          ...adTemplate,
          name: adTemplate?.name,
          alias: adTemplate?.alias,
          description: adTemplate?.description,
        },
        name,
        description,
        category,
        objective,
        media: isTemplateChanged ? temp?.media : media,
        targets,
        audienceType,
        customAudienceType,
        images: isTemplateChanged ? temp?.images : images,
        videos: isTemplateChanged ? temp?.videos : videos,
        paymentMethod,
        limited,
        amount,
        customFields: isTemplateChanged ? temp?.customFields : customFields,
        mediaEditable: isTemplateChanged ? temp?.mediaEditable : mediaEditable,
        urlLocalizable: isTemplateChanged
          ? temp?.urlLocalizable
          : urlLocalizable,
        leadAdTool,
        accountId: diySettings?.fbAdAccount?.accountId,
        creativeTemplate: isTemplateChanged
          ? {
              ...temp?.creativeTemplate,
              asset_feed_spec: {
                ...temp?.creativeTemplate?.asset_feed_spec,
                call_to_actions: [
                  {
                    ...temp?.creativeTemplate?.asset_feed_spec
                      ?.call_to_actions[0],
                    value: {
                      ...temp?.creativeTemplate?.asset_feed_spec
                        ?.call_to_actions[0]?.value,
                      lead_gen_form_id:
                        campaignTemplate?.objective === Objectives.OUTCOME_LEADS
                          ? currentTemplate?.creativeTemplate?.asset_feed_spec
                              ?.call_to_actions[0]?.value?.lead_gen_form_id
                          : null,
                      link:
                        campaignTemplate?.objective === Objectives.OUTCOME_LEADS
                          ? null
                          : currentTemplate?.creativeTemplate?.asset_feed_spec
                              ?.call_to_actions[0]?.value?.link,
                    },
                  },
                ],
              },
            }
          : tempCreative,
      };

      const locationTypes = await buildLocationTypes(
        temp?.adSetTemplate?.targeting?.geo_locations,
        false,
      );

      const excludedLocationTypes = await buildLocationTypes(
        temp?.adSetTemplate?.targeting?.excluded_geo_locations,
        true,
      );

      setDiySettings({ ...diySettings, locationTypes, excludedLocationTypes });

      setShowExclusions(temp?.targets?.exclusions?.length > 0);
      setShowNarrowAudience(temp?.targets?.flexible?.length > 0);
      setSelectedTemplate(temp);
    } catch (error: any) {
      console.log(error);
    } finally {
      setRebuildLoading(false);
    }
  };

  const handleSelectTemplate = (template: AdsTemplate) => {
    rebuildSelectedTemplate(template);
    handleClosePreview();
    handleNext();
  };

  const buildLocationTypes = async (
    geolocation: Geolocation,
    exclude: boolean,
  ) => {
    let locationTypes: string[] = [];
    Object.keys(geolocation).forEach((key: string) => {
      if (geolocation[key]?.length > 0) {
        let type: string = '';

        if (key === 'cities') {
          const counties = geolocation[key].filter(
            (location: any) => location.type === 'medium_geo_area',
          );

          const cities = geolocation[key].filter(
            (location: any) => location.type === 'city',
          );

          if (cities.length > 0) {
            locationTypes = [...locationTypes, 'city'];
          }

          if (counties.length > 0) {
            locationTypes = [...locationTypes, 'county'];
          }
        } else {
          if (key === 'custom_locations') {
            type = 'address';
          } else if (key === 'regions') {
            type = 'state';
          } else {
            type = geolocation[key][0].type;
          }

          locationTypes = [...locationTypes, type];
        }

        if (exclude) setExcludeGeolocation(true);
      }
    });

    return locationTypes;
  };

  const handleOpenMediaForm = (
    mediaType?: string,
    edit?: boolean,
    ratio?: string,
  ) => {
    let temp: AdsTemplate = { ...selectedTemplate };
    switch (mediaType) {
      case 'image':
      case 'video':
        temp = { ...temp, media: mediaType };
        setEditMedia(edit);
        setEditRatio(ratio);

        break;
      default:
        break;
    }

    setOpenMediaForm((prev) => !prev);
    setSelectedTemplate(temp);
  };

  const handleCloseMediaForm = () => {
    setOpenMediaForm((prev) => !prev);
  };

  const handleOnChange = (e: ChangeEventType) => {
    let temp: AdsTemplate = { ...selectedTemplate };
    const name = e.target.name;

    switch (name) {
      case 'link':
        temp.creativeTemplate.asset_feed_spec.link_urls[0].website_url =
          e.target.value;
        temp.creativeTemplate.asset_feed_spec.link_urls[0].display_url =
          e.target.value;
        break;
      case 'name':
        temp = { ...temp, [name]: e.target.value };
        break;
      case 'description':
        temp = { ...temp, [name]: e.target.value };
        break;
      default:
        temp.creativeTemplate.asset_feed_spec.bodies[0].text = e.target.value;
        break;
    }

    setSelectedTemplate(temp);
  };

  const handleOnChangeTags = (
    type: string,
    campaignTag?: string,
    adSetTag?: string,
    adTag?: string,
  ) => {
    const campaignName = buildAdNamingConvention(
      'campaign',
      location || brand,
      selectedTemplate,
      campaigns,
      currentTemplate?.limited,
      null,
      null,
      campaignTag,
    );

    const adSetName = buildAdNamingConvention(
      'adset',
      location || brand,
      selectedTemplate,
      campaigns,
      currentTemplate?.limited,
      campaignName,
      null,
      null,
      adSetTag,
    );

    const adName = buildAdNamingConvention(
      'ad',
      location || brand,
      selectedTemplate,
      campaigns,
      currentTemplate?.limited,
      null,
      adSetName,
      null,
      null,
      adTag,
    );

    let temp: AdsTemplate = { ...selectedTemplate };

    switch (type) {
      case 'adset':
        temp = {
          ...selectedTemplate,
          adSetTemplate: {
            ...selectedTemplate?.adSetTemplate,
            name: adSetName,
          },
          adTemplate: {
            ...selectedTemplate?.adTemplate,
            name: adName,
          },
        };

        setAdSetNameTag(adSetTag);

        break;
      case 'ad':
        temp = {
          ...temp,
          name: adTag,
          adTemplate: {
            ...selectedTemplate?.adTemplate,
            name: adName,
          },
        };

        setAdNameTag(adTag);

        break;
      default:
        temp = {
          ...temp,
          campaignTemplate: {
            ...selectedTemplate?.campaignTemplate,
            name: campaignName,
          },
          adSetTemplate: {
            ...selectedTemplate?.adSetTemplate,
            name: adSetName,
          },
          adTemplate: {
            ...selectedTemplate?.adTemplate,
            name: adName,
          },
        };

        setCampaignNameTag(campaignTag);

        break;
    }

    setSelectedTemplate(temp);
  };

  const handleOnChangeCustomField = (e: ChangeEventType) => {
    const name = e.target.name;
    let temp: AdsTemplate = { ...selectedTemplate };
    let originalString: string = parsedBody;

    const index = temp?.customFields?.findIndex((value: any) => {
      return value.field === name;
    });

    temp.customFields[index].value = e.target.value;

    temp.customFields.forEach((custom: any) => {
      const { field, value } = custom;

      originalString = originalString.replace(field, value);
    });

    temp.creativeTemplate.asset_feed_spec.bodies[0].text = originalString;

    setSelectedTemplate(temp);
  };

  const handleOnChangeUrl = (e: ChangeEventType) => {
    let temp: AdsTemplate = { ...selectedTemplate };

    if (temp?.creativeTemplate?.asset_feed_spec?.link_urls[0]) {
      temp.creativeTemplate.asset_feed_spec.link_urls[0].website_url =
        e.target.value;
      temp.creativeTemplate.asset_feed_spec.link_urls[0].display_url =
        e.target.value;

      temp.creativeTemplate.asset_feed_spec.call_to_actions = [
        {
          ...temp.creativeTemplate.asset_feed_spec.call_to_actions[0],
          value: { link: e.target.value },
        },
      ];

      setSelectedTemplate({ ...selectedTemplate, isTemplateChanged: true });
    }

    setSelectedTemplate(temp);
  };

  const handleOnChangeCustomerForm = (e: ChangeEventType) => {
    const isAddress = [
      'city',
      'country',
      'line1',
      'line2',
      'postal_code',
      'state',
    ].includes(e.target.name);

    if (isAddress) {
      setCustomerForm({
        ...customerForm,
        address: {
          ...customerForm?.address,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      setCustomerForm({
        ...customerForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleOpenTermsModal = () => {
    setOpenTermsModal(true);
  };

  const handleCloseTermsModal = () => {
    setOpenTermsModal(false);
  };

  const handleAddPaymentMethod = async () => {
    setLoading(true);
    try {
      const params: StripeCreateSetupIntentForm = {
        customer: (location || brand)?.stripe?.customerId,
        payment_method_types: ['card'],
        metadata: {
          user_id: (location || brand)?._id,
          role: 'brand',
          action: 'payment_method',
        },
      };

      const setupIntent = await createStripeSetupIntent(
        params,
        (location || brand)?._id,
      );

      handleOpenPaymentModal(setupIntent.data.client_secret, 'setup');
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenPaymentModal = async (
    clientSecret: string,
    transactionType: string,
  ) => {
    setClientSecret(clientSecret);
    setOpenPaymentModal(true);
    setTransactionType(transactionType);
  };

  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
  };

  const buildPageheader = () => {
    let title: string = '';
    switch (activeStep) {
      case 'Templates':
        title = 'PLEASE CHOOSE YOUR TEMPLATE';
        break;
      case 'Audience Target':
        title = 'PLEASE CHOOSE YOUR AUDIENCE';
        break;
      case 'Target Market':
        title = 'PLEASE CHOOSE YOUR TARGET MARKET';
        break;
      case 'Ad Preview':
        title = 'AD PREVIEW';
        break;
      case 'Creative':
        title = 'AD PREVIEW';
        break;
      case 'Budget':
        title = 'PLEASE SET YOUR SCHEDULE AND BUDGET';
        break;
      case 'Campaign Details':
        title = 'CAMPAIGN DETAILS';
        break;
      default:
        title = 'BILLING SUMMARY';
        break;
    }

    return title;
  };

  const hideNextButton = () => {
    return (
      (activeStep === 'Templates' && isTemplateChanged) ||
      (activeStep === 'Target Market' && reachError) ||
      (activeStepIndex + 1 ===
        editDiySteps(
          location || brand,
          isTemplateChanged,
          isBudgetChanged,
          isAudienceChanged,
          isGeographicsChanged,
          isTemplateChanged || isFormChanged || isWebsiteUrlChanged,
        ).length &&
        activeStep === 'Billing Summary' &&
        (!paymentMethod || !confirmPayment) &&
        !isDecrease) ||
      (activeStep === 'Budget' &&
        selectedTemplate?.adSetTemplate?.daily_budget < 1)
    );
  };

  const renderContent = () => {
    if (activeStep === 'Templates') {
      return (
        <AdsTemplateListTable
          templates={templates}
          loading={templatesLoading}
          onOpenPreview={handleOpenPreview}
        />
      );
    }

    if (activeStep === 'Audience Target') {
      return (
        <DiyAudienceForm
          adSetTemplate={adSetTemplate}
          brand={brand}
          setAdSetTemplate={setAdSetTemplate}
          accountId={diySettings?.fbAdAccount?.accountId}
          formValues={selectedTemplate}
          setFormValues={setSelectedTemplate}
          isEdit={false}
          isDuplicate={false}
          hasSpecialCategory={
            campaignTemplate?.special_ad_categories?.length > 0
          }
          diySettings={diySettings}
          setDiySettings={setDiySettings}
          error={null}
          showExclusions={showExclusions}
          setShowExclusions={setShowExclusions}
          showNarrowAudience={showNarrowAudience}
          setShowNarrowAudience={setShowNarrowAudience}
          setLoading={setLoading}
          isEditAds
          isAdmin={isSuperAdmin || isAdmin}
          enabledAdvantageLookalike={enableAdvantageLookalike}
          setEnabledAdvantageLookalike={setEnableAdvantageLookalike}
          enabledAdvantageDetailed={enableAdvantageDetailed}
          setEnabledAdvantageDetailed={setEnableAdvantageDetailed}
          isLeads={campaignTemplate?.objective === Objectives.OUTCOME_LEADS}
          reachError={reachError}
          setReachError={setReachError}
          loading={loading}
        />
      );
    }

    if (activeStep === 'Target Market') {
      return (
        <DiyTargetMarketForm
          diySettings={diySettings}
          setDiySettings={setDiySettings}
          brand={location || brand}
          geoLocationTemplate={geoLocationTemplate}
          setGeoLocationTemplate={setGeoLocationTemplate}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setCurrentTemplate}
          isSpecialAds={isSpecialAds}
          isEmployment={isEmployment}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          getTargetReach={getFacebookTargetReach}
          excludedGeolocationTemplate={excludedGeoLocationTemplate}
          setExcludedGeolocationTemplate={setExcludedGeoLocationTemplate}
          excludeGeolocation={excludeGeolocation}
          setExcludeGeolocation={setExcludeGeolocation}
          error={null}
        />
      );
    }

    if (activeStep === 'Budget') {
      return (
        <DiyUpdateAdBudgetForm
          brand={location || brand}
          currentBudget={adSetTemplate?.totalBudget}
          newMonthlyBudget={newMonthlyBudget}
          setNewMonthlyBudget={setNewMonthlyBudget}
          setTotalCharge={setTotalCharge}
          isDecrease={isDecrease}
          setIsDecrease={setIsDecrease}
          template={selectedTemplate}
          setTemplate={setSelectedTemplate}
          register={register}
          errors={errors}
          clearErrors={clearErrors}
          setError={setError}
          thruYesterDaySpend={calculateTotalBudget(
            location || brand,
            thruYesterdaySpend,
          )}
          todaySpend={calculateTotalBudget(location || brand, todaySpend)}
          setValue={setValue}
          loading={fetchLoading}
        />
      );
    }

    if (activeStep === 'Ad Preview') {
      return (
        <DiyAdPreview
          template={selectedTemplate}
          onOpenMediaForm={handleOpenMediaForm}
          onChange={handleOnChange}
          onChangeCustomField={handleOnChangeCustomField}
          onChangeUrl={handleOnChangeUrl}
          diySettings={diySettings}
          loading={fetchLoading}
          activeStep={activeStep}
          objective={selectedTemplate?.campaignTemplate?.objective}
          setTemplate={setSelectedTemplate}
          brand={location || brand}
          isEditAds
          isWebsiteChanged={isWebsiteUrlChanged}
          isFormChanged={isFormChanged}
        />
      );
    }

    if (activeStep === 'Campaign Details') {
      return (
        <DiyAdDetailsForm
          template={selectedTemplate}
          setTemplate={setSelectedTemplate}
          onChange={handleOnChange}
          onChangeTags={handleOnChangeTags}
          diyError={null}
          campaignNameTag={campaignNameTag}
          adSetNameTag={adSetNameTag}
          adNameTag={adNameTag}
          isAdmin={isSuperAdmin || isAdmin}
        />
      );
    }

    if (activeStep === 'Billing Summary') {
      return (
        <DiyBillingSummary
          brand={location || brand}
          onChange={handleOnChangeCustomerForm}
          formValues={customerForm}
          setFormValues={setCustomerForm}
          onOpenTerms={handleOpenTermsModal}
          paymentMethods={paymentMethods}
          paymentMethodId={paymentMethod}
          setPaymentMethodId={setPaymentMethod}
          loading={false}
          onAddPaymentMethod={handleAddPaymentMethod}
          isDecrease={isDecrease}
          changeType={changeType}
          totalCharge={totalCharge}
          creditBalance={creditBalance}
          confirmPayment={confirmPayment}
          setConfirmPayment={setConfirmPayment}
        />
      );
    }
  };

  return (
    <Box className={`${styles.form} ${xsOnly ? `${styles['-mobile']}` : ''}`}>
      <CircularLoading loading={fetchLoading || rebuildLoading} />

      <Box
        component="div"
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          margin: 0,
          marginBottom: '20px',
        }}
      >
        <Box component="div" sx={{ paddingBottom: 0 }}>
          <PageHeader
            title={buildPageheader()}
            textAlign="left"
            marginBottom="0"
          />
        </Box>
      </Box>

      <Grid container spacing={12}>
        <Grid
          item
          xs={12}
          md={['Target Market'].includes(activeStep) ? 8 : 12}
          sx={{ minHeight: 'calc(100vh - 222px)' }}
        >
          {renderContent()}
        </Grid>

        {activeStep === 'Target Market' ? (
          <Grid item xs={12} md={4} mt={2}>
            <Grid container spacing={2}>
              {activeStep === 'Target Market' ? (
                <Grid item xs={12}>
                  <DiyAudienceSize
                    reachEstimate={reachEstimate}
                    loading={sizeLoading}
                    brand={brand}
                    error={reachError}
                    diySettings={diySettings}
                    setDiySettings={setDiySettings}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <Box
                  component="img"
                  src={footer}
                  alt=""
                  sx={{
                    width: '300px',
                    marginTop: '100px',
                    position: 'sticky',
                    bottom: 0,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      <Grid
        container
        spacing={1}
        mt={4}
        sx={{
          position: 'sticky',
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: '#FFF',
          paddingBottom: '20px',
          zIndex: 999,
          alignItems: 'flex-end',
        }}
      >
        <Grid item xs={12} sm={6}>
          <LinearProgressBarWithLabel value={progress} />
        </Grid>

        {activeStepIndex > 0 || !isTemplateChanged ? (
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              justifyContent: 'flex-end',
              display: 'flex',
              paddingY: '20px',
            }}
          >
            {activeStepIndex > 0 ? (
              <PrimaryButton
                title="Back"
                type="button"
                handleOnClick={handleBack}
                marginRight5
                variant="text"
                color="red"
              />
            ) : null}

            {hideNextButton() ? null : (
              <PrimaryButton
                loading={
                  activeStepIndex + 1 ===
                    editDiySteps(
                      location || brand,
                      isTemplateChanged,
                      isBudgetChanged,
                      isAudienceChanged,
                      isGeographicsChanged,
                      isTemplateChanged || isFormChanged || isWebsiteUrlChanged,
                    ).length && loading
                }
                title={
                  activeStepIndex + 1 ===
                  editDiySteps(
                    location || brand,
                    isTemplateChanged,
                    isBudgetChanged,
                    isAudienceChanged,
                    isGeographicsChanged,
                    isTemplateChanged || isFormChanged || isWebsiteUrlChanged,
                  ).length
                    ? 'Go Live'
                    : 'Next'
                }
                type="button"
                handleOnClick={
                  activeStepIndex + 1 ===
                  editDiySteps(
                    location || brand,
                    isTemplateChanged,
                    isBudgetChanged,
                    isAudienceChanged,
                    isGeographicsChanged,
                    isTemplateChanged || isFormChanged || isWebsiteUrlChanged,
                  ).length
                    ? handleOnSubmit
                    : handleSubmit(handleNext)
                }
                startIcon={
                  activeStepIndex + 1 ===
                  editDiySteps(
                    location || brand,
                    isTemplateChanged,
                    isBudgetChanged,
                    isAudienceChanged,
                    isGeographicsChanged,
                    isTemplateChanged || isFormChanged || isWebsiteUrlChanged,
                  ).length ? (
                    <RocketLaunchIcon />
                  ) : null
                }
              />
            )}
          </Grid>
        ) : null}
      </Grid>

      <DrawerMenu
        anchor="right"
        open={openPreview}
        onClose={handleClosePreview}
      >
        <FacebookAdDetails
          brand={location || brand}
          templateId={selectedTemplate?._id}
          isTemplate
          withAction
          onSelect={handleSelectTemplate}
          isDiy
          onClose={handleClosePreview}
          isMobile={xsOnly}
          fetchLoading={fetchLoading}
        />
      </DrawerMenu>

      <StripePaymentTermsModal
        open={openTermsModal}
        onClose={handleCloseTermsModal}
        onAgree={handleCreateCustomer}
        title="Advertising Services Agreement"
      />

      <StripePaymentUIModal
        open={openPaymentModal}
        onClose={handleClosePaymentModal}
        clientSecret={clientSecret}
        transactionType={transactionType}
        allowRedirect={false}
      />

      <PopupModal open={openMediaForm} handleClose={handleCloseMediaForm}>
        <AdMediaForm
          open={openMediaForm}
          brand={
            (location || brand)?.__type === 'location'
              ? (location || brand)?.franchisor
              : location || brand
          }
          accountId={diySettings?.fbAdAccount?.accountId}
          format={'media'}
          mediaType={selectedTemplate?.media}
          onClose={handleCloseMediaForm}
          template={selectedTemplate}
          setTemplate={setSelectedTemplate}
          assetFeedSpec={selectedTemplate?.creativeTemplate?.asset_feed_spec}
          edit={editMedia}
          ratio={editRatio}
          diy
          diySettings={diySettings}
          objective={selectedTemplate?.campaignTemplate?.objective}
          newVideoIds={newVideoIds}
          setNewVideoIds={setNewVideoIds}
          isEmployment={isEmployment}
        />
      </PopupModal>
    </Box>
  );
};

export default EditDiyForm;
