import {
  Box,
  Button,
  Typography,
  Divider,
  Drawer,
  Modal,
  FormControlLabel,
  Checkbox,
  Tooltip,
  ImageList,
  ImageListItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  formatNumber,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { MdAdd, MdOutlineEdit, MdOutlineCancel } from 'react-icons/md';
import { Brand } from '../../../../types';
import PageHeader from '../../../PageHeader';
import { DataGridPremium, gridClasses } from '@mui/x-data-grid-premium';
import GGAdzCreation from './GGAdzCreation';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions/ToggleAlert';
import {
  fetchGoogleAccountDetails,
  fetchGoogleAds,
  fetchUserInterestAndTopics,
  getGoogleCampaignAndAdgroupsAndAds,
  googleTestFunction,
  modifyGoogleAdStatus,
  modifyGoogleResourceStatus,
  pauseGoogleAdGroupAd,
} from '../../../../services/googleAds';
import {
  MdOutlinePauseCircleOutline,
  MdPlayCircle,
  MdTextSnippet,
} from 'react-icons/md';
import CircularLoading from '../../../CircularLoading';
import GoogleTemplatesPreview from '../../Templatez/Google/GGLTemplatesPreview';
import GoogleTemplateSummary from '../../Templatez/Google/GGLTemplateSummary';
import GGTemplateCreation from '../../Templatez/Google/GGTemplateCreation';
import { googleConstants } from '../../../../utils/constants/google/googleconstants';
import GoogleImageCard from '../../../Cards/Google/ImageCard';
import GoogleMultiUnitReport from '../../../Reporting/GoogleMultiUnitReport';
import GGAdzMultiUnitCreation from './GGAdzMultiUnitCreation';

interface GGAdzIndexProps {
  defaultRefreshToken: string;
  brand: Brand;
  connectedAccountsIds: string[];
  campaignProvider: string;
}

const GGAdzIndex: React.FC<GGAdzIndexProps> = ({
  defaultRefreshToken,
  brand,
  connectedAccountsIds,
  campaignProvider,
}) => {
  const [isMultipleLocations, setIsMultipleLocations] = useState(false);
  const [isCreatingAds, setIsCreatingAds] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [adsCollection, setAdsCollection] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});
  const [selectedAd, setSelectedAd] = useState<any>({});
  const [open, setOpen] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [editPages, setEditPages] = React.useState([]);
  const [selectionBudget, setSelectionBudget] = React.useState(false);
  const [selectionLocations, setSelectionLocations] = React.useState(false);
  const [selectionURL, setSelectionURL] = React.useState(false);
  const [selectionKeywords, setSelectionKeywords] = React.useState(false);
  const [selectionPhoneNumber, setSelectionPhoneNumber] = React.useState(false);
  const [selectionHeadlines, setSelectionHeadlines] = React.useState(false);
  const [selectionDescriptions, setSelectionDescriptions] =
    React.useState(false);
  const [selectionCallouts, setSelectionCallouts] = React.useState(false);
  const [selectionSitelinks, setSelectionSitelinks] = React.useState(false);
  const [selectionImages, setSelectionImages] = React.useState(false);
  const [isExisting, setIsExisting] = React.useState(false);
  const [isEditingAd, setIsEditingAd] = React.useState(false);
  const [adsData, setAdsData] = React.useState<any>([]);
  const [adDb, setAdDb] = React.useState<any>([]);
  const [abTestModalOpen, setAbTestModalOpen] = React.useState(false);
  const [abTestTo, setAbTestTo] = React.useState('');
  //test
  const [tableRows, setTableRows] = useState<any>([]);
  const dispatch = useDispatch();
  const syncGoogleAds = async () => {
    setIsLoading(true);
    const rawData = await fetchGoogleAds(brand._id);
    console.log('rawData', rawData);
    if (rawData.length === 0) return setIsLoading(false);
    fetchGoogleAdsData(rawData);
  };

  const doneCreatingHandler = () => {
    setIsCreatingAds(false);
    setIsEditingAd(false);
    setIsExisting(false);
    setSelectionBudget(false);
    setSelectionLocations(false);
    setSelectionURL(false);
    setSelectionKeywords(false);
    setSelectionPhoneNumber(false);
    setSelectionHeadlines(false);
    setSelectionDescriptions(false);
    setSelectionCallouts(false);
    setSelectionSitelinks(false);
    setSelectionImages(false);

    syncGoogleAds();
    dispatch(
      toggleAlert({
        toggle: true,
        message: `Changes have been saved successfully`,
      }),
    );
  };

  const pauseAd = async () => {
    console.log(`🚀 selectedAd`, selectedAd);

    const res = await modifyGoogleResourceStatus({
      brandId: brand._id,
      resource_names: [selectedAd?.adgroupAd ? selectedAd.adgroupAd : ''],
      type: 'ad',
      status: 'PAUSED',
    });
    //.then(()=>{
    // modifyGoogleResourceStatus({
    //   brandId: brand._id,
    //   resource_names: [selectedAd.adgroup],
    //   type: 'ad_group',
    //   status: 'PAUSED',
    // }).then(()=>{
    //   modifyGoogleResourceStatus({
    //     brandId: brand._id,
    //     resource_names: [selectedAd.campaign],
    //     type: 'campaign',
    //     status: 'PAUSED',
    //   })
    // })
    //})
    syncGoogleAds();
  };

  const fetchGoogleAdsData = async (adCol: any[]) => {
    const rawData = await getGoogleCampaignAndAdgroupsAndAds({
      brandId: brand._id,
    });

    console.log('rawData2', rawData);

    if (!rawData) return setIsLoading(false);
    setAdsData(rawData);
    const campaigns: any = {};
    const filteredads = rawData?.map((ad: any) => {
      // campaigns[ad.campaign.name] = {
      //   ...ad.campaign,
      //   primaryStatus: ad?.campaign?.primaryStatus,
      //   primaryStatusReasons: ad?.campaign?.primaryStatusReasons?.join(', '),
      //   status: ad?.campaign?.status,
      // };

      return {
        id: ad?.adGroupAd?.ad?.id || ad?.campaign?.id,
        name: ad?.adGroup?.name
          ? `${ad?.campaign?.name} / ${ad?.adGroup?.name} / ${ad?.adGroupAd?.ad?.resourceName}`
          : `${ad?.campaign?.name}`,
        rss: ad?.adGroupAd?.ad?.resourceName || ad?.campaign?.resourceName,
        type: ad?.adGroupAd
          ? ad?.adGroupAd?.ad?.type
          : ad?.campaign?.advertisingChannelType,
        status: ad?.adGroupAd ? ad?.adGroupAd?.status : ad?.campaign?.status,
        primaryStatus: ad?.adGroupAd
          ? ad?.adGroupAd?.primaryStatus
          : ad?.campaign?.primaryStatus,
        primaryStatusReasons: ad?.adGroupAd
          ? ad?.adGroupAd?.primaryStatusReasons?.join(', ')
          : ad?.campaign?.primaryStatusReasons?.join(', '),
        campaignName: ad?.campaign?.name,
        adGroupName: ad?.adGroup?.name,
      };
    });

    console.log('filteredads', filteredads);

    let newAdCollection = adCol.map((ad: any, index) => {
      for (let i = 0; i < filteredads.length; i++) {
        if (ad.ad === filteredads[i].rss) {
          ad.id = filteredads[i].id;
          ad.status = filteredads[i].status;
          ad.primaryStatus = filteredads[i].primaryStatus || '';
          ad.primaryStatusReasons = filteredads[i].primaryStatusReasons || '';
          ad.type = filteredads[i].type;
          ad.campaignName = filteredads[i].campaignName;
          ad.adGroupName = filteredads[i].adGroupName;
          ad.path = [
            filteredads[i].campaignName || '',
            filteredads[i].adGroupName + '-' + index || '',
          ];

          campaigns[filteredads[i].campaignName] = {
            ...campaigns[filteredads[i].name],
            budget: ad.budget,
            id: ad.id,
            status: ad.status,
            primaryStatus: ad.primaryStatus,
            primaryStatusReasons: ad.primaryStatusReasons,
            type: ad.type,
            path: [ad.campaignName || ''],
          };
        } else if (ad.campaign === filteredads[i].rss) {
          ad.id = `${filteredads[i].id}-campaign`;
          ad.status = filteredads[i].status;
          ad.primaryStatus = filteredads[i].primaryStatus || '';
          ad.primaryStatusReasons = filteredads[i].primaryStatusReasons || '';
          ad.type = filteredads[i].type;
          ad.campaignName = filteredads[i].campaignName;
          ad.path = [filteredads[i].campaignName || ''];
          // campaigns[filteredads[i].campaignName] = {
          //   ...campaigns[filteredads[i].name],
          //   budget: ad.budget,
          //   id: ad.id,
          //   status: ad.status,
          //   primaryStatus: ad.primaryStatus,
          //   primaryStatusReasons: ad.primaryStatusReasons,
          //   type: ad.type,
          //   path: [ad.name || ''],
          // };
        }
      }

      return ad;
    });

    const otherAds: any[] = filteredads.filter((ad: any) => {
      return !newAdCollection.some((newAd) => newAd.id === ad.id);
    });
    setIsLoading(false);
    newAdCollection = [
      ...newAdCollection,
      // ...Object.values(campaigns).filter((campaign: any) => campaign.budget),
    ];
    setAdDb(otherAds);
    setAdsCollection(newAdCollection);
    console.log('campaigns', campaigns);
    console.log(
      'newAdCollection',
      newAdCollection.filter((ad: any) => ad.adgroupAd),
    );
    console.log(
      Object.values(campaigns).filter((campaign: any) => campaign.budget),
    );
    setTableRows([
      ...Object.values(campaigns).filter((campaign: any) => campaign.budget),
      ...newAdCollection,
    ]);
  };

  useEffect(() => {
    syncGoogleAds();
    if (brand?.allowLocations) {
      setIsMultipleLocations(true);
    }
  }, []);

  const handleCloseAdEdit = () => {
    setEditModalOpen(false);
    setSelectionBudget(false);
    setSelectionLocations(false);
    setSelectionURL(false);
    setSelectionKeywords(false);
    setSelectionPhoneNumber(false);
    setSelectionHeadlines(false);
    setSelectionDescriptions(false);
    setSelectionCallouts(false);
    setSelectionSitelinks(false);
    setSelectionImages(false);
  };

  const handleCloseAbtestModal = () => {
    setAbTestModalOpen(false);
  };

  const handleEditAdContinue = () => {
    const selectedFields: {
      [key: string]: boolean;
    } = {
      Budget: selectionBudget,
      Locations: selectionLocations,
      URL: selectionURL,
      Keywords: selectionKeywords,
      'Keywords Matching Type': selectionKeywords,
      'Phone Number': selectionPhoneNumber,
      Headlines: selectionHeadlines,
      Descriptions: selectionDescriptions,
      Callouts: selectionCallouts,
      Sitelinks: selectionSitelinks,
      Images: selectionImages,
      Preview: true,
    };
    const editPages = Object.keys(selectedFields).filter(
      (field) => selectedFields[field],
    );
    setEditModalOpen(false);
    setEditPages(editPages);
    setIsExisting(true);
    setIsEditingAd(true);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: `100%`,
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: `4px solid ${DEFAULT_COLOR_THEME}`,
    boxShadow: 24,
    p: 4,
  };

  const EditModal = (
    <Modal
      open={editModalOpen}
      // onClose={handleCloseConfirmOverwrite}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          color={DEFAULT_COLOR_THEME}
          sx={{
            display: `flex`,
            justifyContent: `start`,
            gap: 1,
            alignItems: `center`,
            fontWeight: 600,
          }}
        >
          Ad Edit
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          You are about to edit the Ad:{' '}
          <Typography
            sx={{
              display: `inline`,
              color: DEFAULT_COLOR_THEME,
              fontWeight: 600,
            }}
          >
            {selectedTemplate.name}
          </Typography>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          Select the fields you want to edit for this Ad.
        </Typography>

        <Box sx={{ marginBottom: 2, paddingLeft: `20px` }}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionBudget}
                  onChange={() => {
                    setSelectionBudget(!selectionBudget);
                  }}
                />
              }
              label="Budget"
            />
          </Box>

          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionLocations}
                  onChange={() => {
                    setSelectionLocations(!selectionLocations);
                  }}
                />
              }
              label="Locations"
            />
          </Box>
          <Divider />
          <Typography sx={{ fontSize: 12, fontStyle: 'italic' }}>
            *Note: Changing the following will also affect the linked template
          </Typography>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionURL}
                  onChange={() => {
                    setSelectionURL(!selectionURL);
                  }}
                />
              }
              label="URL"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionKeywords}
                  onChange={() => {
                    setSelectionKeywords(!selectionKeywords);
                  }}
                />
              }
              label="Keywords & Keyword Match Types"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionPhoneNumber}
                  onChange={() => {
                    setSelectionPhoneNumber(!selectionPhoneNumber);
                  }}
                />
              }
              label="Phone Number"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionHeadlines}
                  onChange={() => {
                    setSelectionHeadlines(!selectionHeadlines);
                  }}
                />
              }
              label="Headlines"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionDescriptions}
                  onChange={() => {
                    setSelectionDescriptions(!selectionDescriptions);
                  }}
                />
              }
              label="Descriptions"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionCallouts}
                  onChange={() => {
                    setSelectionCallouts(!selectionCallouts);
                  }}
                />
              }
              label="Callouts"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionSitelinks}
                  onChange={() => {
                    setSelectionSitelinks(!selectionSitelinks);
                  }}
                />
              }
              label="Sitelinks"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectionImages}
                  onChange={() => {
                    setSelectionImages(!selectionImages);
                  }}
                />
              }
              label="Business Logo / Images"
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: `flex`,
            justifyContent: `space-between`,
            gap: 2,
          }}
        >
          <Button onClick={handleCloseAdEdit}>Cancel</Button>
          <Button variant="contained" onClick={handleEditAdContinue}>
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  const ABTestModal = (
    <Modal
      open={abTestModalOpen}
      // onClose={handleCloseConfirmOverwrite}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          color={DEFAULT_COLOR_THEME}
          sx={{
            display: `flex`,
            justifyContent: `start`,
            gap: 1,
            alignItems: `center`,
            fontWeight: 600,
          }}
        >
          A/B TEST
        </Typography>

        <Box
          sx={{
            display: `flex`,
            gap: 2,
            alignItems: `center`,
            justifyContent: `center`,
            minHeight: 200,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              paddingY: 0,
              paddingX: 2,
              height: 120,
              width: '100%',
              display: `inline`,
            }}
            onClick={() => {
              setEditPages(['Template', 'Keywords', 'Preview']);
              setIsCreatingAds(true);
              setIsExisting(true);
              setAbTestModalOpen(false);
              setAbTestTo('campaign');
            }}
          >
            Create{' '}
            <Typography
              sx={{
                display: `inline`,
                fontWeight: 600,
              }}
            >
              New Ad Group
            </Typography>{' '}
            to an existing Campaign
          </Button>

          <Button
            variant="contained"
            color="primary"
            sx={{
              paddingY: 0,
              paddingX: 2,
              height: 120,
              width: '100%',
              display: `inline`,
            }}
            onClick={() => {
              setEditPages(['Template', 'Preview']);
              setIsCreatingAds(true);
              setIsExisting(false);
              setAbTestModalOpen(false);
              setAbTestTo('adgroup');
            }}
          >
            Create{' '}
            <Typography
              sx={{
                display: `inline`,
                fontWeight: 600,
              }}
            >
              New Ad
            </Typography>{' '}
            to an existing Ad Group
          </Button>
        </Box>

        <Box
          sx={{
            display: `flex`,
            justifyContent: `flex-end`,
            gap: 2,
          }}
        >
          <Button onClick={handleCloseAbtestModal}>Cancel</Button>
        </Box>
      </Box>
    </Modal>
  );

  const PreviewDrawer = (
    <Box
      sx={{ width: 440, ...scrollBarGreen }}
      role="presentation"
      onClick={() => {
        setOpen(false);
      }}
    >
      <Box
        bgcolor={`#eeeeee`}
        sx={{
          position: `sticky`,
          top: 0,
        }}
      >
        <Typography variant="h6" sx={{ padding: 2 }}>
          Ad Details
        </Typography>
      </Box>
      <Divider />
      <Box paddingTop={2}>
        <Typography sx={{ paddingLeft: 2, fontWeight: 600 }}>Name:</Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: DEFAULT_COLOR_THEME,
            paddingLeft: 4,
            marginBottom: 2,
          }}
        >
          {selectedTemplate.name}
        </Typography>
        <Typography sx={{ paddingLeft: 2, fontWeight: 600 }}>
          Product and Service:
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: DEFAULT_COLOR_THEME,
            paddingLeft: 4,
            marginBottom: 2,
          }}
        >
          {selectedTemplate.product_and_service}
        </Typography>

        <Typography sx={{ paddingLeft: 2, fontWeight: 600 }}>
          Category:
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: DEFAULT_COLOR_THEME,
            paddingLeft: 4,
            marginBottom: 2,
          }}
        >
          {selectedTemplate.category}
        </Typography>

        <Divider />

        <GoogleTemplatesPreview
          category={selectedTemplate?.category}
          headlines={selectedTemplate.headlines}
          descriptions={selectedTemplate.descriptions}
          sitelinks={selectedTemplate.sitelinks}
          url={selectedTemplate.final_url}
          showToolbar={false}
          images={
            selectedTemplate?.images
              ? selectedTemplate?.images.map((img: any) => img.url)
              : []
          }
          business_logo={selectedTemplate?.business_logo?.url}
        />
      </Box>

      <Divider />

      {Object.keys(selectedTemplate).length > 0 ? (
        <Box sx={{ paddingLeft: 2, paddingTop: 2 }}>
          <GoogleTemplateSummary template={selectedTemplate} ad={selectedAd} />
        </Box>
      ) : null}

      <Divider />
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: `#eeeeee`,
          display: `flex`,
          justifyContent: `space-between`,
          paddingX: 1,
          paddingY: 2,
          alignItems: `center`,
          width: `100%`,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            paddingY: 0,
            paddingX: 2,
            height: 30,
            display: 'flex',
            gap: 1,
            width: `fit-content`,
          }}
          onClick={() => {
            // setEditPages(['Template', 'Preview']);
            // setIsCreatingAds(true);
            // setIsExisting(true);
            setAbTestModalOpen(true);
          }}
        >
          <MdAdd size={20} /> A/B Test
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{
            paddingY: 0,
            paddingX: 2,
            height: 30,
            display: 'flex',
            gap: 1,
            width: `fit-content`,
          }}
          onClick={() => {
            setEditModalOpen(true);
          }}
        >
          <MdOutlineEdit size={20} />
          Edit
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{
            paddingY: 0,
            paddingX: 2,
            height: 30,
            display: 'flex',
            gap: 1,
            width: `fit-content`,
          }}
          disabled={selectedAd?.primaryStatusReasons?.includes(
            'AD_GROUP_AD_PAUSED',
          )}
          onClick={pauseAd}
        >
          <MdOutlineCancel size={20} /> Stop Ads
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
      }}
    >
      <Box sx={{ width: '100%', height: '100%' }}>
        <CircularLoading loading={isLoading} />

        {!isCreatingAds && !isEditingAd ? (
          <Box sx={{ paddingTop: 4 }}>
            <PageHeader title="Google Ads" />
            <Box
              sx={{
                display: `flex`,
                justifyContent: `flex-end`,
                mb: 2,
                paddingX: 4,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  paddingY: 0,
                  paddingX: 2,
                  height: 30,
                  display: 'none',
                }}
                onClick={() => {
                  // fetchGoogleAccountDetails({
                  //   brandId: brand._id,
                  // }).then((res) => {
                  //   console.log('res', res);
                  // });

                  googleTestFunction({
                    brandId: brand._id,
                  }).then((res) => {
                    console.log('res', res);
                    // res.query.map(
                    //   (r: any) =>
                    //     `https://youtu.be/${r?.asset?.youtubeVideoAsset?.youtubeVideoId}`,
                    // );
                  });
                }}
              >
                TEMP???
              </Button>

              <Button
                variant="contained"
                color="primary"
                sx={{ paddingY: 0, paddingX: 2, height: 30 }}
                onClick={() => {
                  setEditPages([
                    'Template',
                    'Location',
                    'Negative Keywords',
                    'Keywords',
                    'Budget',
                    'Preview',
                  ]);
                  setSelectedAd(null);
                  setIsCreatingAds(true);
                  setAbTestTo('');
                }}
              >
                Create New Ad
              </Button>
            </Box>
            <Box sx={{ paddingX: 4 }}>
              {adsCollection?.length > 0 ? (
                <Box
                  sx={{
                    '& .green-header': {
                      backgroundColor: DEFAULT_COLOR_THEME,
                      color: 'white',
                      fontWeight: 'bold',
                    },
                    '& .campaign': {
                      backgroundColor: '#B6c61a',
                    },
                    '& .campaign:hover': {
                      backgroundColor: '#B6c61a',
                    },
                    '& .adset': {
                      backgroundColor: '#ffffff',
                    },
                    '& .adset:hover': {
                      backgroundColor: '#ffffff',
                    },
                  }}
                >
                  {tableRows?.length > 0 ? (
                    <DataGridPremium
                      treeData
                      hideFooter
                      disableRowSelectionOnClick
                      density="compact"
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'primaryStatus', sort: 'asc' }],
                        },
                      }}
                      getTreeDataPath={(row) => (row.path ? row.path : [])}
                      isGroupExpandedByDefault={(row) => {
                        let isExpanded = false;
                        tableRows?.forEach((r: any) => {
                          if (
                            r.path?.length === 1 &&
                            r.name === row.groupingKey &&
                            !r.primaryStatus?.includes('PAUSED')
                          ) {
                            isExpanded = true;
                          }
                        });
                        return isExpanded;
                      }}
                      onRowClick={(row) => {
                        console.log('row', row);
                        if (
                          row.row.path.length === 1 &&
                          row.row.type !== 'PERFORMANCE_MAX'
                        )
                          return;
                        setSelectedTemplate(row.row.template);
                        setSelectedAd(row.row);
                        setOpen(true);
                      }}
                      getRowClassName={(params) => {
                        if (params.row.path?.length === 1) {
                          return 'campaign';
                        }
                        if (params.row.path?.length === 2) {
                          return 'adset';
                        }
                        return '';
                      }}
                      sx={{
                        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                          {
                            outline: 'none',
                          },
                        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                          {
                            outline: 'none',
                          },
                        // [`& .${gridClasses.row}`]: {
                        //   backgroundColor: 'blue'
                        // },
                      }}
                      rows={tableRows?.map((row: any, index: any) => ({
                        ...row,
                        id: `${index}`,
                      }))}
                      columns={[
                        {
                          field: 'primaryStatus',
                          headerName: 'Status',
                          headerClassName: 'green-header',
                          width: 200,
                          valueSetter: (params) => {
                            return params.row.status;
                          },
                          renderCell: (params) =>
                            params.row?.status ? (
                              <Tooltip
                                disableInteractive
                                title={
                                  <Box>
                                    <Typography sx={{ fontSize: `0.8rem` }}>
                                      Status: {params.row?.status}
                                    </Typography>
                                    <Typography sx={{ fontSize: `0.8rem` }}>
                                      Primary Status:{' '}
                                      {params.row?.primaryStatus}
                                    </Typography>
                                    <Typography sx={{ fontSize: `0.8rem` }}>
                                      Primary Status Reasons:{' '}
                                      {params.row?.primaryStatusReasons}
                                    </Typography>
                                  </Box>
                                }
                              >
                                <Box
                                  sx={{
                                    display: `flex`,
                                    alignItems: `center`,
                                    justifyContent: `start`,
                                    gap: 1,
                                  }}
                                >
                                  {params.row?.primaryStatusReasons?.includes(
                                    'PAUSED',
                                  ) ? (
                                    <>
                                      <MdOutlinePauseCircleOutline
                                        size={24}
                                        color={`#777777`}
                                      />
                                      PAUSED
                                    </>
                                  ) : (
                                    <>
                                      <MdPlayCircle
                                        size={24}
                                        color={DEFAULT_COLOR_THEME}
                                      />
                                      ACTIVE
                                    </>
                                  )}
                                </Box>
                              </Tooltip>
                            ) : null,
                        },
                        {
                          field: 'budget',
                          headerName: 'Budget',
                          headerClassName: 'green-header',
                          width: 100,
                          renderCell: (params) =>
                            params?.row?.budget === undefined ? null : params
                                ?.row?.path.length === 1 ? (
                              <>${formatNumber(params.row.budget)}</>
                            ) : null,
                        },
                        {
                          field: 'type',
                          headerName: 'Type',
                          headerClassName: 'green-header',
                          width: 300,
                          renderCell: (params) => <>{params.row?.type}</>,
                        },
                        {
                          field: 'dateStart',
                          headerName: 'Start Date/Time',
                          headerClassName: 'green-header',
                          width: 300,
                        },
                      ]}
                      groupingColDef={{
                        headerName: 'Campaigns',
                        headerClassName: 'green-header',
                        width: 300,
                        flex: 1,
                      }}
                    />
                  ) : null}
                </Box>
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    minHeight: '200px',
                    border: `2px solid ${DEFAULT_COLOR_THEME}`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 3,
                  }}
                >
                  <Box sx={{ maxWidth: `500px`, textAlign: `center` }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: DEFAULT_COLOR_THEME,
                        fontWeight: 'bold',
                      }}
                    >
                      No Ads Found
                    </Typography>
                    Please create a New Ad by Clicking the Button on the upper
                    right corner
                  </Box>
                </Box>
              )}
            </Box>

            <Drawer
              anchor="right"
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              {PreviewDrawer}
            </Drawer>
            <Box mt={12} display={`none`}>
              <Typography
                sx={{
                  color: DEFAULT_COLOR_THEME,
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                }}
              >
                Ads not managed by us:
              </Typography>
              <Box>
                {adDb?.map((ad: any, index: number) => (
                  <Typography key={index}>
                    {ad.name} - {ad.status} - {ad.primaryStatus} -{' '}
                    {ad.primaryStatusReasons} - {ad.type}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
        ) : null}
        {isCreatingAds ? (
          !isMultipleLocations ? (
            <>
              <Box sx={{ paddingTop: 4 }}>
                <PageHeader title="Google Ads" />
                <Box
                  sx={{
                    textAlign: `center`,
                    position: `relative`,
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {isExisting ? 'Adding to Ad Group' : 'Creating New Ad'}:
                    {` `}
                    {isExisting ? selectedTemplate?.name : ''}
                  </Box>
                </Box>
                <GGAdzCreation
                  brand={brand}
                  doneCreating={doneCreatingHandler}
                  adsCollection={adsCollection}
                  baseAd={selectedAd}
                  adsData={adsData}
                  editPages={editPages}
                  abTestTo={abTestTo}
                  cancelCreation={() => {
                    setIsEditingAd(false);
                    setIsExisting(false);
                    setIsCreatingAds(false);
                    syncGoogleAds();
                  }}
                />
              </Box>
            </>
          ) : (
            <Box sx={{ width: '100%', height: '100%' }}>
              <GGAdzMultiUnitCreation
                brand={brand}
                onCancel={() => {
                  setIsCreatingAds(false);
                  setIsEditingAd(false);
                  setIsExisting(false);
                }}
              />
            </Box>
          )
        ) : null}
        {isEditingAd ? (
          <>
            <Box sx={{ paddingTop: 4 }}>
              <PageHeader title="Google Ads" />
              <Box
                sx={{
                  paddingX: 2,
                  textAlign: `center`,
                  position: `relative`,
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                  }}
                >
                  {isEditingAd ? 'Editing Ad' : 'Creating Ad'}:{` `}
                  {selectedTemplate?.name}
                </Box>
              </Box>
              <GGTemplateCreation
                creationComplete={doneCreatingHandler}
                brandId={brand?._id}
                defaultRefreshToken={defaultRefreshToken}
                isExisting={isExisting}
                template={selectedTemplate}
                editPages={editPages}
                ad={selectedAd}
                cancelCreation={() => {
                  setIsEditingAd(false);
                  setIsExisting(false);
                  setIsCreatingAds(false);
                  syncGoogleAds();
                }}
              />
            </Box>
          </>
        ) : null}
        {EditModal}

        {ABTestModal}
      </Box>
    </Box>
  );
};

export default GGAdzIndex;
