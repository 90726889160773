import { Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { defaultProps } from '../../../utils/helpers/TableHelpers';

export const sendLeadSummaryColumns = (isMobile: boolean) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerClassName: styles.header,
      headerName: 'ID',
      width: 150,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { id } = params.row;

        return (
          <Tooltip
            title={<Typography variant="body2">{id}</Typography>}
            disableInteractive
          >
            <div className={styles.name}>{id}</div>
          </Tooltip>
        );
      },
    },
    {
      ...defaultProps(isMobile, 180),
      field: 'name',
      headerClassName: styles.header,
      headerName: 'Name',
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { name } = params.row;

        return (
          <Tooltip
            title={<Typography variant="body2">{name}</Typography>}
            disableInteractive
          >
            <div className={styles.name}>{name}</div>
          </Tooltip>
        );
      },
    },
    {
      field: 'email',
      headerClassName: styles.header,
      headerName: 'Email',
      width: 100,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { email } = params.row;

        return (
          <Tooltip
            title={
              <Typography variant="body2">
                {email ? 'Sent' : 'Not Sent'}
              </Typography>
            }
            disableInteractive
          >
            <div
              className={`${styles['name']} ${email ? '' : styles['-error']}`}
            >
              {email ? 'Sent' : 'Not Sent'}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'zapier',
      headerClassName: styles.header,
      headerName: 'Zapier',
      width: 100,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { zapier } = params.row;

        return (
          <Tooltip
            title={
              <Typography variant="body2">
                {zapier ? 'Sent' : 'Not Sent'}
              </Typography>
            }
            disableInteractive
          >
            <div
              className={`${styles['name']} ${zapier ? '' : styles['-error']}`}
            >
              {zapier ? 'Sent' : 'Not Sent'}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'clientTether',
      headerClassName: styles.header,
      headerName: 'Client Tether',
      width: 100,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { clientTether, clientTetherEnabled } = params.row;

        return (
          <Tooltip
            title={
              <Typography variant="body2">
                {!clientTetherEnabled
                  ? 'Disabled'
                  : clientTether
                  ? 'Sent'
                  : 'Not Sent'}
              </Typography>
            }
            disableInteractive
          >
            <div
              className={`${styles['name']} ${
                clientTether ? '' : styles['-error']
              }`}
            >
              {!clientTetherEnabled
                ? 'Disabled'
                : clientTether
                ? 'Sent'
                : 'Not Sent'}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'hubspot',
      headerClassName: styles.header,
      headerName: 'HubSpot',
      width: 100,
      sortingOrder: ['desc', 'asc'],
      renderCell: (params: any) => {
        const { hubspot, hubspotEnabled } = params.row;

        return (
          <Tooltip
            title={
              <Typography variant="body2">
                {!hubspotEnabled ? 'Disabled' : hubspot ? 'Sent' : 'Not Sent'}
              </Typography>
            }
            disableInteractive
          >
            <div
              className={`${styles['name']} ${hubspot ? '' : styles['-error']}`}
            >
              {!hubspotEnabled ? 'Disabled' : hubspot ? 'Sent' : 'Not Sent'}
            </div>
          </Tooltip>
        );
      },
    },
  ];

  return columns;
};
