import { DEFAULT_COLOR_THEME } from '../Styling';

export const formatNumber = (n: string | number) => {
  // Add commas for thousands, millions, etc. example n===1000, return 1,000
  if (!n) return 0;
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const googleEnums = {
  keywords: ['UNSPECIFIED', 'UNKNOWN', 'EXACT', 'PHRASE', 'BROAD'],
  advertisingChannel: [
    'UNSPECIFIED',
    'UNKNOWN',
    'SEARCH',
    'DISPLAY',
    'SHOPPING',
    'HOTEL',
    'VIDEO',
    'MULTI CHANNEL',
    'LOCAL',
    'SMART',
    'PERFORMANCE MAX',
    'LOCAL SERVICES',
    'DISCOVERY',
    'TRAVEL',
  ],
  biddingStrategyType: [
    'UNSPECIFIED',
    'UNKNOWN',
    'COMMISSION',
    'ENHANCED_CPC',
    'FIXED_CPM	',
    'INVALID',
    'MANUAL_CPA',
    'MANUAL_CPC',
    'MANUAL_CPM',
    'MANUAL_CPV',
    'MAXIMIZE_CONVERSIONS',
    'MAXIMIZE_CONVERSION_VALUE',
    'PAGE_ONE_PROMOTED',
    'PERCENT_CPC',
    'TARGET_CPA',
    'TARGET_CPM',
    'TARGET_CPV',
    'TARGET_IMPRESSION_SHARE',
    'TARGET_OUTRANK_SHARE',
    'TARGET_ROAS',
    'TARGET_SPEND',
  ],
  biddingStrategySystemStatus: [
    'UNSPECIFIED',
    'UNKNOWN',
    'ENABLED',
    'LEARNING_NEW',
    'LEARNING_SETTING_CHANGE',
    'LEARNING_BUDGET_CHANGE',
    'LEARNING_COMPOSITION_CHANGE',
    'LEARNING_CONVERSION_TYPE_CHANGE',
    'LEARNING_CONVERSION_SETTING_CHANGE',
    'LIMITED_BY_CPC_BID_CEILING',
    'LIMITED_BY_CPC_BID_FLOOR',
    'LIMITED_BY_DATA',
    'LIMITED_BY_BUDGET',
    'LIMITED_BY_LOW_PRIORITY_SPEND',
    'LIMITED_BY_LOW_QUALITY',
    'LIMITED_BY_INVENTORY',
    'MISCONFIGURED_ZERO_ELIGIBILITY',
    'MISCONFIGURED_CONVERSION_TYPES',
    'MISCONFIGURED_CONVERSION_SETTINGS',
    'MISCONFIGURED_SHARED_BUDGET',
    'MISCONFIGURED_STRATEGY_TYPE',
    'PAUSED',
    'UNAVAILABLE',
    'MULTIPLE_LEARNING',
    'MULTIPLE_LIMITED',
    'MULTIPLE_MISCONFIGURED',
    'MULTIPLE',
  ],
  campaignStatus: ['UNSPECIFIED', 'UNKNOWN', 'ENABLED', 'PAUSED', 'REMOVED'],
};

export const GoogleCampaignStatus = {
  UNSPECIFIED: 'UNSPECIFIED',
  UNKNOWN: 'UNKNOWN',
  ENABLED: 'ENABLED',
  PAUSED: 'PAUSED',
  REMOVED: 'REMOVED',
};

export const googleHelpers = {
  micros: (value: number) => {
    if (!value || value === 0) {
      return 0;
    }
    const val = value / 1000000;
    if (isNaN(val)) return 0;
    return val;
  },
};

export const scrollBarGreen = {
  '&::-webkit-scrollbar': {
    width: '10px',
  },

  '&::-webkit-scrollbar-track': {
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    background: '#a1c8bb',
  },

  '&::-webkit-scrollbar-thumb': {
    background: DEFAULT_COLOR_THEME,
    borderRadius: '18px',

    '&:hover': {
      background: DEFAULT_COLOR_THEME,
    },
  },
};

export const GoogleAssetsContainer = {
  height: 'calc(100vh - 260px);',
  padding: '0.5rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  ...scrollBarGreen,
};

export const GoogleAssestsSidebarVisibilityOnTabs: {
  [key: string]: string[];
} = {
  Home: ['keyword set'],
};

export const GoogleAssetsMainMenu = [
  // oldones
  // 'Account',
  // 'Business',
  // 'Search',
  // //'Display',
  // //'Performance Max',
  'Home',
];

export const GoogleAssetsSubMenu: {
  [key: string]: string[];
} = {
  Home: [
    'account',
    'business',
    `templates`,
    `keywords`,
    `location sets`,
    `existing campaigns`,
    `conversion`,
    // `callouts`,
  ],
  Account: ['account', 'images'],
  Business: ['business', 'competitors', 'products and services'],
  Search: [
    'keywords',
    //'images',
    //'videos',
    //'logo',
    'headlines',
    'descriptions',
    'sitelinks',
    // 'callouts',
    // 'snippets',
  ],
  Display: [],
  'Performance Max': [],
  Local: ['Local'],
};

export const googleResourceLimits = {
  //items = number of items allowed
  //length = max length of characters each item
  callouts: {
    items: 10,
    length: 25,
  },
  headlines: {
    search: {
      items: 15,
      length: 30,
    },
    display: {
      items: 5,
      length: 30,
    },
    performancemax: {
      items: 15,
      length: 30,
    },
  },
  descriptions: {
    search: {
      items: 4,
      length: 90,
    },
    display: {
      items: 5,
      length: 90,
    },
    performancemax: {
      items: 5,
      length: 90,
    },
  },
  sitelinks: {
    items: 10,
    length: {
      headline: 25,
      description1: 35,
      description2: 35,
      finalUrl: 2048,
    },
  },
  videos: {
    items: 5,
    length: 2048,
  },
  images: {
    display: 15,
    search: 5,
    performancemax: 20,
  },
  business_name: 25,
  long_headline: 90,
  keywords: {
    performancemax: 25,
  },
};

export const flows: any = {
  search: [
    'Product & Service',
    'URL',
    'Phone Number',
    'Headlines',
    'Descriptions',
    'Callouts',
    'Sitelinks',
    //'Business Logo',
    'Images',
    'Preview',
  ],
  display: [
    'Product & Service',
    'URL',
    'Business Name',
    'Long Headline',
    'Headlines',
    'Descriptions',
    'Websites',
    'Demographics',
    'Topics & Interests',
    //'Business Logo',
    'Images',
    'Videos',
    'Preview',
  ],
  performancemax: [
    //'Images', //removelater
    'Product & Service',
    'URL',
    'Business Name',
    'Long Headline',
    'Headlines',
    'Descriptions',
    'Websites',
    'Demographics',
    'Topics & Interests',
    'Business Logo',
    'Images',
    'Videos',
    'Preview',
  ],
};

export const biddingStrategies = [
  {
    biddingType: 'COMMISSION',
    description:
      'Commission is an automatic bidding strategy in which the advertiser pays a certain portion of the conversion value.',
  },
  {
    biddingType: 'ENHANCED_CPC',
    description:
      'Enhanced CPC is a bidding strategy that raises bids for clicks that seem more likely to lead to a conversion and lowers them for clicks where they seem less likely.',
  },
  {
    biddingType: 'FIXED_CPM',
    description: 'Fixed CPM is a manual bidding strategy with a fixed CPM.',
  },
  {
    biddingType: 'MANUAL_CPA',
    description:
      'Manual bidding strategy that allows advertiser to set the bid per advertiser-specified action.',
  },
  {
    biddingType: 'MANUAL_CPC',
    description: 'Manual click based bidding where user pays per click.',
  },
  {
    biddingType: 'MANUAL_CPM',
    description:
      'Manual impression based bidding where user pays per thousand impressions.',
  },
  {
    biddingType: 'MANUAL_CPV',
    description:
      'A bidding strategy that pays a configurable amount per video view.',
  },
  {
    biddingType: 'MAXIMIZE_CONVERSIONS',
    description:
      'A bidding strategy that automatically maximizes number of conversions given a daily budget.',
  },
  {
    biddingType: 'MAXIMIZE_CONVERSION_VALUE',
    description:
      'An automated bidding strategy that automatically sets bids to maximize revenue while spending your budget.',
  },
  {
    biddingType: 'PAGE_ONE_PROMOTED',
    description:
      'Page-One Promoted bidding scheme, which sets max cpc bids to target impressions on page one or page one promoted slots on google.com. This enum value is deprecated.',
  },
  {
    biddingType: 'PERCENT_CPC',
    description:
      'Percent Cpc is bidding strategy where bids are a fraction of the advertised price for some good or service.',
  },
  {
    biddingType: 'TARGET_CPA',
    description:
      'Target CPA is an automated bid strategy that sets bids to help get as many conversions as possible at the target cost-per-acquisition (CPA) you set.',
  },
  {
    biddingType: 'TARGET_CPM',
    description:
      'Target CPM is an automated bid strategy that sets bids to help get as many impressions as possible at the target cost per one thousand impressions (CPM) you set.',
  },
  {
    biddingType: 'TARGET_CPV',
    description:
      'Target CPV is an automated bidding strategy that sets bids to optimize performance given the average target cost per view.',
  },
  {
    biddingType: 'TARGET_IMPRESSION_SHARE',
    description:
      'An automated bidding strategy that sets bids so that a certain percentage of search ads are shown at the top of the first page (or other targeted location).',
  },
  {
    biddingType: 'TARGET_OUTRANK_SHARE',
    description:
      'Target Outrank Share is an automated bidding strategy that sets bids based on the target fraction of auctions where the advertiser should outrank a specific competitor. This enum value is deprecated.',
  },
  {
    biddingType: 'TARGET_ROAS',
    description:
      'Target ROAS is an automated bidding strategy that helps you maximize revenue while averaging a specific target Return On Average Spend (ROAS).',
  },
  {
    biddingType: 'TARGET_SPEND',
    description:
      'Target Spend is an automated bid strategy that sets your bids to help get as many clicks as possible within your budget.',
  },
];

export const checkAspectRatio = (
  aspectRatio: string,
  dimensions: { width: number; height: number },
): boolean => {
  // If aspectRatio doesn't contain ":", append ":1" to it
  if (!aspectRatio.includes(':')) {
    aspectRatio += ':1';
  }

  // Split the aspect ratio into width and height parts
  const [ratioWidth, ratioHeight] = aspectRatio.split(':').map(Number);

  // Calculate the aspect ratio of the provided dimensions
  const dimensionsRatio = dimensions.width / dimensions.height;

  // Calculate the expected aspect ratio
  const expectedRatio = ratioWidth / ratioHeight;

  // Check if the aspect ratios match (allowing for a small margin of error due to floating point precision)
  const epsilon = 0.00001; // Margin of error
  return Math.abs(dimensionsRatio - expectedRatio) < epsilon;
};
