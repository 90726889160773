import React, { useEffect, useState } from 'react';
import { AdLead, Brand, CampaignAd } from '../../../types';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import styles from '../../../assets/styles/components/Tables/Tables.module.scss';
import { leadColumns } from './LeadListTableColumn';
import { BusinessType } from '../../../utils/helpers/BrandHelpers';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { CustomLoadingOverlay } from '../../Loading';
import {
  getEmail,
  getFirstName,
  getLastName,
  getLeadDetails,
  getPhoneNumber,
} from '../../../utils/helpers/LeadHelpers';

interface LeadListTableProps {
  leads: AdLead[];
  loading: boolean;
  onOpenLeadDetails: (lead: AdLead) => void;
  ads: CampaignAd[];
  brand: Brand;
  onSelectLead: (value: number[]) => void;
  selectedLeadIds: number[];
}

const LeadListTable: React.FC<LeadListTableProps> = ({
  leads,
  loading,
  onOpenLeadDetails,
  ads,
  brand,
  onSelectLead,
  selectedLeadIds,
}) => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const isFrandev: boolean = brand?.businessType === BusinessType.FRANDEV;

  const [rows, setRows] = useState<any[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  useEffect(() => {
    getRows();
  }, [leads]);

  const getRows = () => {
    const rows = leads?.map((lead: AdLead) => {
      const ad = ads.find((ad: CampaignAd) => ad.id === lead.adId);

      return {
        id: lead.leadId,
        adName: ad?.name || '',
        email: getEmail(lead),
        firstName: getFirstName(lead),
        lastName: getLastName(lead),
        details: getLeadDetails(lead),
        investmentQualifiedLead: lead.investmentQualifiedLead,
        backgroundQualifiedLead: lead.backgroundQualifiedLead,
        experienceQualifiedLead: lead.experienceQualifiedLead,
        createdAt: lead.leadCreatedAt,
        phoneNumber: getPhoneNumber(lead),
        lead,
        onOpen: onOpenLeadDetails,
        investmentPresent: lead.investmentPresent || false,
        backgroundPresent: lead.backgroundPresent || false,
        experiencePresent: lead.experiencePresent || false,
        investmentQualified: lead.investmentQualified,
        backgroundQualified: lead.backgroundQualified,
        experienceQualified: lead.experienceQualified,
      };
    }, []);

    setRows(rows);
  };

  return (
    <Box className={styles.table}>
      <DataGridPremium
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
        }}
        density="compact"
        rows={rows}
        autoHeight
        hideFooterSelectedRowCount
        loading={loading}
        columns={leadColumns(isFrandev, xsOnly)}
        checkboxSelection
        onRowSelectionModelChange={(value) => onSelectLead(value as number[])}
        rowSelectionModel={selectedLeadIds}
        slots={{ loadingOverlay: CustomLoadingOverlay }}
        pagination
        pageSizeOptions={[25, 50, 100]}
        onPaginationModelChange={setPaginationModel}
        paginationModel={paginationModel}
      />
    </Box>
  );
};

export default LeadListTable;
