import {
  Autocomplete,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CrmSetting } from '../../../../../types/ICrm';
import {
  Brand,
  ChangeEventType,
  CustomCrmFieldMapForm,
  CustomCrmMapFormField,
  FBLeadGenQuestionForm,
} from '../../../../../types';
import {
  CLIENT_TETHER_DEFAULT_MAP,
  CLIENT_TETHER_PROPERTIES,
  CrmProvider,
  DEFAULT_COLOR_THEME,
  HUBSPOT_DEFAULT_MAP,
  ScrollStyle,
} from '../../../../../utils';
import {
  HubSpotProperty,
  HubSpotPropertyOption,
} from '../../../../../types/IHubSpot';
import { getHubSpotProperties } from '../../../../../services/hubspot';
import CircularLoading from '../../../../CircularLoading';
import { PrimaryButton } from '../../../../Buttons';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { orderArrayOfObject } from '../../../../../utils/arrayFormatter';
import { constantStringToHumanized } from '../../../../../utils/stringModifier';

const GroupHeader = styled('div')(() => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: 'white',
  backgroundColor: DEFAULT_COLOR_THEME,
}));

const GroupItems = styled('ul')({
  padding: 0,
});

interface LeadFormCrmMappingFormProps {
  formValues: CustomCrmFieldMapForm[];
  setFormValues: Dispatch<SetStateAction<CustomCrmFieldMapForm[]>>;
  brand: Brand;
  crmSettings: CrmSetting;
  questions: FBLeadGenQuestionForm[];
}

const LeadFormCrmMappingForm: React.FC<LeadFormCrmMappingFormProps> = ({
  formValues,
  setFormValues,
  brand,
  crmSettings,
  questions,
}) => {
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [hubSpotProperties, setHubSpotProperties] = useState<HubSpotProperty[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);

  const questionFields = questions?.map((question: FBLeadGenQuestionForm) => {
    if (question.type === 'CUSTOM') {
      return question.key;
    }

    if (question.type === 'MARITIAL_STATUS') {
      return 'marital_status';
    }

    if (question.type === 'ZIP') {
      return 'zip_code';
    }

    return question.type.toLowerCase();
  }, []);

  useEffect(() => {
    if (crmSettings?.providers?.includes(CrmProvider.HUBSPOT)) {
      fetchHubSpotProperties();
    }
  }, [crmSettings?.providers]);

  const fetchHubSpotProperties = async () => {
    try {
      setLoading(true);
      const response = await getHubSpotProperties(brand?._id, 'contacts');

      const propertyData: HubSpotProperty[] = response.data;

      const filteredData = propertyData?.filter(
        (property: HubSpotProperty) =>
          !property.hidden && !property?.modificationMetadata?.readOnlyValue,
      );

      const sortedResponse = orderArrayOfObject(filteredData, 'name');

      setHubSpotProperties(sortedResponse);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const parseProviderName = (provider: string) => {
    if (provider === CrmProvider.HUBSPOT) {
      return 'HubSpot';
    }

    return 'Client Tether';
  };

  const handleOnChange = (e: ChangeEventType, provider: string) => {
    let temp: CustomCrmFieldMapForm[] = [...formValues];
    const isEnabled = e.target.value === 'yes';
    const index = temp?.findIndex(
      (form: CustomCrmFieldMapForm) => form.provider === provider,
    );

    if (index < 0) {
      const params: CustomCrmFieldMapForm = {
        provider,
        enabled: isEnabled,
        fields: getDefaultProperties(provider),
      };

      temp = [...temp, params];
    } else {
      temp[index].enabled = isEnabled;

      if (isEnabled) {
        temp[index].fields = getDefaultProperties(provider);
      } else {
        temp[index].fields = [];
      }
    }

    setFormValues(temp);
  };

  const handleOnSelectCrmField = (
    value: HubSpotProperty,
    provider: string,
    index: number,
  ) => {
    let temp: CustomCrmFieldMapForm[] = [...formValues];
    const formIndex = temp?.findIndex(
      (form: CustomCrmFieldMapForm) => form.provider === provider,
    );

    temp[formIndex].fields[index] = {
      ...temp[formIndex].fields[index],
      crmField: value.name,
    };

    setFormValues(temp);
  };

  const handleOnSelectFormField = (
    value: string,
    provider: string,
    index: number,
  ) => {
    let temp: CustomCrmFieldMapForm[] = [...formValues];
    const formIndex = temp?.findIndex(
      (form: CustomCrmFieldMapForm) => form.provider === provider,
    );

    temp[formIndex].fields[index] = {
      ...temp[formIndex].fields[index],
      value: `${temp[formIndex].fields[index].value || ''}<<${value}>>`,
    };

    setFormValues(temp);
  };

  const handleOnChangeValue = (args: {
    event: ChangeEventType;
    provider: string;
    index: number;
  }) => {
    const { event, provider, index } = args;

    let temp: CustomCrmFieldMapForm[] = [...formValues];
    const formIndex = temp?.findIndex(
      (form: CustomCrmFieldMapForm) => form.provider === provider,
    );

    temp[formIndex].fields[index] = {
      ...temp[formIndex].fields[index],
      value: event.target.value,
    };

    setFormValues(temp);
  };

  const handleOnSelectOption = (
    value: HubSpotPropertyOption,
    provider: string,
    index: number,
  ) => {
    let temp: CustomCrmFieldMapForm[] = [...formValues];
    const formIndex = temp?.findIndex(
      (form: CustomCrmFieldMapForm) => form.provider === provider,
    );

    temp[formIndex].fields[index] = {
      ...temp[formIndex].fields[index],
      value: value.value,
    };

    setFormValues(temp);
  };

  const handleAddField = (provider: string) => {
    let temp: CustomCrmFieldMapForm[] = [...formValues];
    const formIndex = temp?.findIndex(
      (form: CustomCrmFieldMapForm) => form.provider === provider,
    );

    temp[formIndex].fields = [
      ...temp[formIndex].fields,
      { crmField: '', value: '' },
    ];

    setFormValues(temp);
  };

  const handleRemoveField = (provider: string, fieldIndex: number) => {
    let temp: CustomCrmFieldMapForm[] = [...formValues];
    const formIndex = temp?.findIndex(
      (form: CustomCrmFieldMapForm) => form.provider === provider,
    );

    const fields = temp[formIndex].fields.filter(
      (_, index: number) => index !== fieldIndex,
    );

    temp[formIndex].fields = fields;

    setFormValues(temp);
  };

  const getDefaultProperties = (provider: string) => {
    let fields: CustomCrmMapFormField[] = [];
    const filteredFields = questions?.filter(
      (question: FBLeadGenQuestionForm) => question.type !== 'CUSTOM',
    );
    filteredFields?.forEach((question: FBLeadGenQuestionForm) => {
      const field = (
        provider === CrmProvider.HUBSPOT
          ? HUBSPOT_DEFAULT_MAP
          : CLIENT_TETHER_DEFAULT_MAP
      ).find((field: any) => field.formField === question.type.toLowerCase());
      if (!field) return;

      const value = { ...field, value: `<<${field.formField}>>` };

      fields = [...fields, value];
    });

    if (fields.length > 0) {
      return fields;
    }

    return [{ crmField: '', value: '' }];
  };

  const getHubSpotGroupName = (groupName: string) => {
    if (groupName === 'contactinformation') {
      return 'Contact Information';
    }

    if (groupName === 'socialmediainformation') {
      return 'Social Media Information';
    }

    if (groupName === 'analyticsinformation') {
      return 'Analytics Information';
    }

    if (groupName === 'conversioninformation') {
      return 'Conversion Information';
    }

    if (groupName === 'emailinformation') {
      return 'Email Information';
    }

    if (groupName === 'contactscripted') {
      return 'Contacts Scripted';
    }

    if (groupName === 'contactlcs') {
      return 'Contact LCS';
    }

    return constantStringToHumanized(groupName);
  };

  const renderCrmFieldDropdown = (
    provider: string,
    field: CustomCrmMapFormField,
    index: number,
    form: CustomCrmFieldMapForm,
  ) => {
    if (provider === CrmProvider.HUBSPOT) {
      return (
        <Autocomplete
          key={`${provider}-${index}-crm-field`}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.name}>
                {option.label}
              </li>
            );
          }}
          options={hubSpotProperties?.sort(
            (a, b) => -b.groupName?.localeCompare(a.groupName),
          )}
          value={
            hubSpotProperties?.find(
              (property: HubSpotProperty) => property?.name === field?.crmField,
            ) || null
          }
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={`CRM Field Name #${index + 1}`}
              placeholder="Select a CRM field name"
              InputLabelProps={{ shrink: true }}
              fullWidth
              size="small"
              //error={errors?.users && i === 0 ? true : false}
              //helperText={errors?.users && i === 0 ? errors?.users : ''}
            />
          )}
          disableClearable
          onChange={async (e: any, value: any) =>
            handleOnSelectCrmField(value, provider, index)
          }
          groupBy={(option) => option.groupName}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{getHubSpotGroupName(params.group)}</GroupHeader>

              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
          getOptionDisabled={(option) => {
            const fieldNames = form?.fields?.map(
              (field: CustomCrmMapFormField) => field?.crmField,
            );

            return fieldNames?.includes(option.name);
          }}
          ListboxProps={{
            sx: ScrollStyle(),
          }}
        />
      );
    }

    return (
      <Autocomplete
        key={`${provider}-${index}-crm-field`}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.name}>
              {option.label}
            </li>
          );
        }}
        options={CLIENT_TETHER_PROPERTIES.sort(
          (a, b) => -b.label?.localeCompare(a.label),
        )}
        value={
          CLIENT_TETHER_PROPERTIES?.find(
            (property: any) => property.name === field?.crmField,
          ) || null
        }
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={`CRM Field Name #${index + 1}`}
            placeholder="Select a CRM field name"
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
            //error={errors?.users && i === 0 ? true : false}
            //helperText={errors?.users && i === 0 ? errors?.users : ''}
          />
        )}
        disableClearable
        onChange={async (e: any, value: any) =>
          handleOnSelectCrmField(value, provider, index)
        }
        getOptionDisabled={(option) => {
          const fieldNames = form?.fields?.map(
            (field: CustomCrmMapFormField) => field?.crmField,
          );

          return fieldNames?.includes(option.name);
        }}
        ListboxProps={{
          sx: ScrollStyle(),
        }}
      />
    );
  };

  const renderCrmValueField = (args: {
    field: CustomCrmMapFormField;
    provider: string;
    index: number;
    withOptions?: boolean;
    options?: HubSpotPropertyOption[];
  }) => {
    const { field, provider, index, withOptions, options } = args;
    const isHubSpot = provider === CrmProvider.HUBSPOT;

    if (isHubSpot && withOptions) {
      return (
        <Autocomplete
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                {option.value}
              </li>
            );
          }}
          options={options}
          value={
            options?.find(
              (property: HubSpotPropertyOption) =>
                property?.value === field?.value,
            ) || null
          }
          getOptionLabel={(option) => option.value}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={`Value #${index + 1}`}
              placeholder="Select one of the valid option"
              InputLabelProps={{ shrink: true }}
              fullWidth
              size="small"
              name={`body-value-${index}`}
            />
          )}
          disableClearable
          onChange={async (e: any, value: any) =>
            handleOnSelectOption(value, provider, index)
          }
          ListboxProps={{
            sx: ScrollStyle(),
          }}
        />
      );
    }

    return (
      <TextField
        fullWidth
        required
        variant="standard"
        type="text"
        name={`body-value-${index}`}
        label={`Value #${index + 1}`}
        multiline
        onChange={(e: ChangeEventType) => {
          handleOnChangeValue({ event: e, provider, index });
        }}
        value={field?.value || ''}
        InputLabelProps={{
          shrink: true,
        }}
        size="small"
        placeholder="Type or select form field map value"
      />
    );
  };

  return (
    <Grid container spacing={2}>
      <CircularLoading loading={loading} />

      <Grid item xs={12}>
        {crmSettings?.providers?.map((provider: string) => {
          const form = formValues?.find(
            (form: CustomCrmFieldMapForm) => form.provider === provider,
          );

          return (
            <Grid container spacing={2} key={provider} mb={2}>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  sx={{
                    justifyContent: 'flex-start',
                    color: '#096F4D',
                    display: 'flex',
                    margin: 0,
                    alignItems: 'center',
                  }}
                >
                  {`Setup your ${parseProviderName(provider)} field mapping`}
                </Typography>

                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="style"
                    name="style"
                    value={form?.enabled ? 'yes' : 'no'}
                    onChange={(e: ChangeEventType) =>
                      handleOnChange(e, provider)
                    }
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio size="small" />}
                      label="Yes"
                    />

                    <FormControlLabel
                      value="no"
                      control={<Radio size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {form?.enabled ? (
                <>
                  <Grid item xs={12}>
                    {form?.fields?.map(
                      (field: CustomCrmMapFormField, index: number) => {
                        const isHubSpot = provider === CrmProvider.HUBSPOT;
                        let withOptions = false;
                        let options: HubSpotPropertyOption[] = [];

                        if (isHubSpot) {
                          const property = hubSpotProperties?.find(
                            (property: HubSpotProperty) =>
                              property.name === field?.crmField,
                          );
                          withOptions = property?.options?.length > 0;
                          options =
                            property?.options?.filter(
                              (option: HubSpotPropertyOption) => !option.hidden,
                            ) || [];
                        }

                        return (
                          <Card
                            sx={{ marginBottom: '10px' }}
                            key={`${field?.crmField}-${index}`}
                          >
                            <CardContent
                              sx={{
                                paddingBottom: '5px !important',
                                paddingTop: '10px !important',
                              }}
                            >
                              <Grid
                                container
                                spacing={2}
                                key={`${provider}-${index}`}
                                mb={1}
                                columns={13}
                              >
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                      <Autocomplete
                                        renderOption={(props, option) => {
                                          return (
                                            <li {...props} key={option}>
                                              {option}
                                            </li>
                                          );
                                        }}
                                        options={[
                                          ...(questionFields || []),
                                          'AD_NAME',
                                          'PLATFORM',
                                        ]?.sort((a, b) => -b.localeCompare(a))}
                                        value={null}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                            label="Question Field"
                                            placeholder="Select a question field"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            size="small"
                                          />
                                        )}
                                        disableClearable
                                        onChange={async (e: any, value: any) =>
                                          handleOnSelectFormField(
                                            value,
                                            provider,
                                            index,
                                          )
                                        }
                                        ListboxProps={{
                                          sx: ScrollStyle(),
                                        }}
                                        disabled={withOptions}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  {renderCrmFieldDropdown(
                                    provider,
                                    field,
                                    index,
                                    form,
                                  )}
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  {renderCrmValueField({
                                    field,
                                    provider,
                                    index,
                                    withOptions,
                                    options,
                                  })}
                                </Grid>

                                {form?.fields?.length === 1 &&
                                index === 0 ? null : (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={1}
                                    sx={{
                                      justifyContent: xsOnly
                                        ? 'flex-end'
                                        : 'unset',
                                      display: 'flex',
                                    }}
                                  >
                                    <IconButton
                                      onClick={() =>
                                        handleRemoveField(provider, index)
                                      }
                                    >
                                      <DeleteIcon sx={{ color: 'red' }} />
                                    </IconButton>
                                  </Grid>
                                )}
                              </Grid>
                            </CardContent>
                          </Card>
                        );
                      },
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      justifyContent: 'flex-start',
                      display: 'flex',
                    }}
                  >
                    <PrimaryButton
                      title="Add Field"
                      type="button"
                      variant="text"
                      startIcon={<AddIcon />}
                      color={DEFAULT_COLOR_THEME}
                      bold
                      handleOnClick={() => handleAddField(provider)}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default LeadFormCrmMappingForm;
