import axios from 'axios';
import { apiBaseUrl } from './api';

export const hubspotOauth2 = async (brandId: string, authCode: string) => {
  const data = await axios.post(`${apiBaseUrl}/crm/hubspot/oauth`, {
    brandId,
    authCode,
  });

  return data?.data;
};

export const getHubSpotPipelines = async (brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/crm/hubspot/pipelines`, {
    params: { brandId },
  });

  return data?.data;
};

export const getHubSpotContactLifecycle = async (brandId: string) => {
  const data = await axios.get(`${apiBaseUrl}/crm/hubspot/lifecycle-stages`, {
    params: { brandId },
  });

  return data?.data;
};

export const getHubSpotPipelineReportStage = async (
  brandId: string,
  provider: string,
) => {
  const data = await axios.get(`${apiBaseUrl}/crm/hubspot/report/stage`, {
    params: { brandId, provider },
  });

  return data?.data;
};

export const getHubSpotPipelineReportAds = async (
  brandId: string,
  provider: string,
) => {
  const data = await axios.get(`${apiBaseUrl}/crm/hubspot/report/ads`, {
    params: { brandId, provider },
  });

  return data?.data;
};

export const getHubSpotProperties = async (
  brandId: string,
  objectType: string,
) => {
  const data = await axios.get(`${apiBaseUrl}/crm/hubspot/properties`, {
    params: { brandId, objectType },
  });

  return data?.data;
};

export const syncHubSpotLeads = async (brandId: string) => {
  const data = await axios.post(
    `${apiBaseUrl}/crm/hubspot/contacts/sync`,
    {},
    { params: { brandId } },
  );

  return data?.data;
};

export const getDealsById = async (brandId: string, dealId: string) => {
  const data = await axios.get(`${apiBaseUrl}/crm/hubspot/deals/${dealId}`, {
    params: { brandId },
  });

  return data?.data;
};
