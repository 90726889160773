import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminLayout, { PublicLayout, SalespersonLayout } from '../layout';
import Agencies from '../pages/Agencies';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import Login from '../pages/Auth/Login';
import Profile from '../pages/Auth/Profile';
import Brands from '../pages/Brands';
import Chats from '../pages/Chats';
import Settings from '../pages/Settings';
import Users from '../pages/Users';
import Join from '../pages/Auth/Join';
import ResetPassword from '../pages/Auth/ResetPassword';
import Privacy from '../pages/Public/Privacy';
import Terms from '../pages/Public/Terms';
import PaymentSuccessPage from '../pages/Payment/PaymentSuccessPage';
import Billings from '../pages/Billing';
import BrandForm from '../components/Forms/BrandForm';
import UserForm from '../components/Forms/UserForm';
import AgencyForm from '../components/Forms/AgencyForm';
import ChatDialog from '../pages/ChatDialog';
import Reports from '../pages/Reports';
import Campaigns from '../pages/Campaigns';
import Dashboard from '../pages/Dashboard';
import { NoContent } from '../pages/NoContent';
import Budgets from '../pages/Budgets';
import AddBudgetForm from '../components/Forms/AddBudgetForm';
import Locations from '../pages/Locations';
import Home from '../pages/Home';
import LocationForm from '../components/Forms/LocationForm';
import { useSelector } from 'react-redux';
import { diyAdzUrl } from '../services/api';
import { DiyMarketingManagerLayout } from '../layout/DiyAdzLayout';
import Templates from '../pages/Templates';
import TemplateForm from '../components/Forms/Diy/TemplateForm';
import Adz from '../pages/Ads/adz';
import DiyForm from '../components/Forms/Diy/DiyForm';
import { AuthContext } from '../context';
import { ADMIN, CrmProvider, IS_DIY_ADZ, SUPER_ADMIN } from '../utils';
import Tools from '../pages/Tools';
import { Brand } from '../types';
import Scoreboard from '../pages/Scoreboard';
import { Box } from '@mui/material';
import ABTestForm from '../components/Forms/Diy/ABTestForm';
import Leads from '../pages/Leads';
import AiReport from '../pages/AiReport';
import AIReportSettings from '../components/Forms/AI/AIReportSettings';
import EditDiyForm from '../components/Forms/Diy/EditDiyForm';
import Assetz from '../pages/Assets';
import FacebookLeadForm from '../components/Forms/Assetz/Facebook/Leads/FacebookLeadForm';
import FacebookSendLeadForm from '../components/Forms/Assetz/Facebook/Leads/FacebookSendLeadForm';
import ChatzBudgets from '../pages/Budgets/ChatzBudgetReport';
import AddChatzBudgetForm from '../components/Forms/AddChatzBudgetForm';
import FacebookLeadFormSetup from '../components/Forms/Assetz/Facebook/Leads/FacebookLeadFormSetup';
import FacebookQLeadsForm from '../components/Forms/Assetz/Facebook/Leads/FacebookQLeadsForm';
import Pipelinez from '../pages/Pipelinez';
import { CrmSetting } from '../types/ICrm';
import { getCrmSettingsByBrand } from '../services/crm';
import Alertz from '../pages/Alerts';
import Monitoring from '../pages/Monitoring';
import Trendz from '../pages/Trends';

const AppRoutes: React.FC = () => {
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const baseUrl = window.location.host;
  const isDiyAdz = baseUrl === diyAdzUrl;
  const { state } = useContext(AuthContext);
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAdmin = state.role === ADMIN;
  const withAgencyAccess = state.withAgencyAccess;
  const capabilities = state.capabilities;
  const [crmSettings, setCrmSettings] = useState<CrmSetting>(null);

  useEffect(() => {
    if (location || brand) getCrmSettings();
  }, [location, brand]);

  const getCrmSettings = async () => {
    try {
      const response = await getCrmSettingsByBrand((location || brand)?._id);

      setCrmSettings(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const isClientTetherEnabled =
    crmSettings?.providers?.includes(CrmProvider.CLIENT_TETHER) &&
    crmSettings?.clientTetherSettings?.accessToken &&
    crmSettings?.clientTetherSettings?.webKey &&
    crmSettings?.clientTetherSettings?.salesCycles?.length > 0;

  const isHubSpotEnabled =
    crmSettings?.providers?.includes(CrmProvider.HUBSPOT) &&
    crmSettings?.hubspotSettings?.accessToken &&
    crmSettings?.hubspotSettings?.pipelineId;

  const chatzAppRoutes = () => {
    return (
      <>
        <Route element={<AdminLayout />}>
          <Route path="users">
            <Route index element={<Users />} />
            <Route path="create" element={<UserForm />} />
            <Route path="edit" element={<UserForm />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="/">
            <Route index element={<Home />} />
          </Route>

          <Route path="agencies">
            <Route index element={<Agencies />} />
            <Route path="create" element={<AgencyForm />} />
            <Route path="edit" element={<AgencyForm />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          {/**
           * Temporary Removed
           * This page is transfered inside the Brand page /brands
           * <Route path="/budgets" element={<Budgets />} />
           */}
        </Route>

        <Route element={<SalespersonLayout />}>
          <Route path="chats">
            <Route index element={<Chats />} />
            <Route path="dialog" element={<ChatDialog />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="brands">
            <Route index element={<Brands />} />
            <Route path="create" element={<BrandForm />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="locations">
            <Route index element={<Locations />} />
            <Route path="create" element={<LocationForm />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="reports">
            <Route index element={<Reports />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="campaigns">
            <Route index element={<Campaigns />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="profile">
            <Route index element={<Profile />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="settings">
            <Route index element={<Settings />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="tools">
            <Route index element={<Tools />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="preferences">
            {location ? (
              <Route index element={<LocationForm />} />
            ) : (
              <Route index element={<BrandForm />} />
            )}
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="billings">
            <Route index element={<Billings />} />
            <Route path="payment-success" element={<PaymentSuccessPage />} />
            <Route path="payment-method">
              <Route
                index
                element={
                  <Box component="div" sx={{ marginTop: '50px' }}>
                    No Content
                  </Box>
                }
              />
              <Route path="added" element={<PaymentSuccessPage />} />
              <Route path="*" element={<NoContent />} />
            </Route>
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="dashboard">
            <Route index element={<Dashboard />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="budgets">
            <Route index element={<ChatzBudgets />} />
            <Route path="set-up" element={<AddChatzBudgetForm />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="*" element={<NoContent />} />
        </Route>
      </>
    );
  };

  const diyAdzRoutes = () => {
    return (
      <>
        <Route element={<DiyMarketingManagerLayout />}>
          <Route path="/">
            <Route index element={<Home />} />
          </Route>

          <Route path="reportz">
            <Route path="kpiz">
              <Route index element={<Reports />} />
              <Route path="*" element={<NoContent />} />
            </Route>

            <Route path="trendz">
              <Route index element={<Trendz />} />
              <Route path="*" element={<NoContent />} />
            </Route>

            <Route path="scorecardz">
              <Route index element={<Scoreboard />} />
              <Route path="*" element={<NoContent />} />
            </Route>

            <Route path="alertz">
              <Route index element={<Alertz />} />
              <Route path="*" element={<NoContent />} />
            </Route>

            <Route path="ai">
              <Route index element={<NoContent />} />
              <Route path="*" element={<NoContent />} />
              <Route path="insightz">
                <Route index element={<AiReport />} />
                <Route path="*" element={<NoContent />} />
                <Route path="settings">
                  <Route index element={<AIReportSettings />} />
                  <Route path="*" element={<NoContent />} />
                </Route>
              </Route>
            </Route>

            <Route path="leadz">
              <Route index element={<Leads />} />
              <Route path="*" element={<NoContent />} />
            </Route>

            {isClientTetherEnabled || isHubSpotEnabled ? (
              <Route path="pipelinez">
                <Route index element={<Pipelinez />} />
                <Route path="*" element={<NoContent />} />
              </Route>
            ) : null}

            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="profile">
            <Route index element={<Profile />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          {isAdmin || isSuperAdmin || capabilities?.createTemplate ? (
            <Route path="templatez">
              <Route index element={<Templates />} />
              <Route path="create" element={<TemplateForm />} />
              <Route path="edit">
                <Route index element={<TemplateForm />} />
                <Route path=":templateId" element={<TemplateForm />} />
                <Route path="*" element={<NoContent />} />
              </Route>
              <Route path="duplicate">
                <Route index element={<TemplateForm />} />
                <Route path=":templateId" element={<TemplateForm />} />
                <Route path="*" element={<NoContent />} />
              </Route>
              <Route path="*" element={<NoContent />} />
            </Route>
          ) : null}

          <Route path="adz">
            <Route index element={<Adz />} />
            <Route path="diy">
              <Route index element={<DiyForm />} />
              <Route path="*" element={<NoContent />} />
            </Route>
            <Route path="ab-test">
              <Route index element={<NoContent />} />
              <Route path=":adId" element={<ABTestForm />} />
              <Route path="*" element={<NoContent />} />
            </Route>
            <Route path="edit">
              <Route index element={<NoContent />} />
              <Route path=":adId" element={<EditDiyForm />} />
              <Route path="*" element={<NoContent />} />
            </Route>
            <Route path="*" element={<NoContent />} />
          </Route>

          {capabilities?.addUser || isAdmin || isSuperAdmin ? (
            <Route path="userz">
              <Route index element={<Users />} />
              <Route path="create" element={<UserForm />} />
              <Route path="edit" element={<UserForm />} />
              <Route path="*" element={<NoContent />} />
            </Route>
          ) : null}

          {isSuperAdmin ||
          isAdmin ||
          (!(isSuperAdmin || isAdmin) && capabilities?.createBrands) ? (
            <Route path="brandz">
              <Route index element={<Brands />} />
              <Route path="create" element={<BrandForm />} />
              <Route path="*" element={<NoContent />} />
            </Route>
          ) : null}

          <Route path="billingz">
            <Route index element={<Billings />} />
            <Route path="payment-success" element={<PaymentSuccessPage />} />
            <Route path="payment-method">
              <Route
                index
                element={
                  <Box component="div" sx={{ marginTop: '50px' }}>
                    No Content
                  </Box>
                }
              />
              <Route path="added" element={<PaymentSuccessPage />} />
              <Route path="*" element={<NoContent />} />
            </Route>
            <Route path="*" element={<NoContent />} />
          </Route>

          {isSuperAdmin || isAdmin || capabilities?.addLocations ? (
            <Route path="locationz">
              <Route index element={<Locations />} />
              <Route path="create" element={<LocationForm />} />
              <Route path="*" element={<NoContent />} />
            </Route>
          ) : null}

          <Route path="preferencez">
            {location ? (
              <Route index element={<LocationForm />} />
            ) : (
              <Route index element={<BrandForm />} />
            )}
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="profile">
            <Route index element={<Profile />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          {isAdmin || isSuperAdmin ? (
            <>
              <Route path="settingz">
                <Route index element={<Settings />} />
                <Route path="*" element={<NoContent />} />
              </Route>

              <Route path="toolz">
                <Route index element={<Tools />} />
                <Route path="*" element={<NoContent />} />
              </Route>

              <Route path="monitoring">
                <Route index element={<Monitoring />} />
                <Route path="*" element={<NoContent />} />
              </Route>
            </>
          ) : null}

          {/**
           * TODO: Re-enable if necessary
           <Route path="ask-z">
             <Route index element={<AskZ />} />
             <Route path="*" element={<NoContent />} />
           </Route>
          */}

          {isAdmin || isSuperAdmin || withAgencyAccess ? (
            <Route path="agenciez">
              <Route index element={<Agencies />} />
              {isSuperAdmin || isAdmin ? (
                <Route path="create" element={<AgencyForm />} />
              ) : null}
              <Route path="edit" element={<AgencyForm />} />
              <Route path="*" element={<NoContent />} />
            </Route>
          ) : null}

          <Route path="assetz">
            <Route index element={<Assetz />} />
            <Route path="templates">
              {/* <Route index element={<GGTemplateCreation />} /> */}
            </Route>
            <Route path="form">
              <Route index element={<NoContent />} />
              <Route path="create">
                <Route index element={<FacebookLeadForm />} />
                <Route path="*" element={<NoContent />} />
              </Route>

              <Route path="set-up">
                <Route index element={<NoContent />} />
                <Route path="*" element={<NoContent />} />
                <Route path=":leadFormId">
                  <Route index element={<FacebookLeadFormSetup />} />
                  <Route path="*" element={<NoContent />} />
                </Route>
              </Route>

              <Route path="send-lead">
                <Route index element={<NoContent />} />
                <Route path="*" element={<NoContent />} />
                <Route path=":leadFormId">
                  <Route index element={<FacebookSendLeadForm />} />
                  <Route path="*" element={<NoContent />} />
                </Route>
              </Route>

              <Route path="q-leads">
                <Route index element={<NoContent />} />
                <Route path="*" element={<NoContent />} />
                <Route path=":leadFormId">
                  <Route index element={<FacebookQLeadsForm />} />
                  <Route path="*" element={<NoContent />} />
                </Route>
              </Route>

              <Route path="duplicate">
                <Route index element={<NoContent />} />
                <Route path="*" element={<NoContent />} />
                <Route path=":leadFormId">
                  <Route index element={<FacebookLeadForm />} />
                  <Route path="*" element={<NoContent />} />
                </Route>
              </Route>

              <Route path="*" element={<NoContent />} />
            </Route>
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="campaignz">
            <Route index element={<Campaigns />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="budgetz">
            <Route index element={<Budgets />} />
            <Route path="set-up" element={<AddBudgetForm />} />
            <Route path="*" element={<NoContent />} />
          </Route>

          <Route path="*" element={<NoContent />} />
        </Route>
      </>
    );
  };

  return (
    <Routes>
      {/** CHATZ APP ROUTES */}
      {!isDiyAdz ? chatzAppRoutes() : null}

      {/** DIY ADZ ROUTES */}
      {isDiyAdz ? diyAdzRoutes() : null}

      <Route element={<PublicLayout />}>
        <Route path="/privacy" element={<Privacy />} />

        <Route path="/terms" element={<Terms />} />

        <Route path="login">
          <Route index element={<Login />} />
          {!IS_DIY_ADZ ? <Route path=":role" element={<Login />} /> : null}
        </Route>

        <Route path="/join/:token" element={<Join />} />

        <Route path="/reset-password/:token" element={<ResetPassword />} />

        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="*" element={<NoContent />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
