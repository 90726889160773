import React, { Fragment, useEffect, useState } from 'react';
import styles from '../../../../../assets/styles/components/Forms/Form.module.scss';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CircularLoading from '../../../../CircularLoading';
import {
  Brand,
  ChangeEventType,
  CustomCrmFieldMapForm,
  LeadFormData,
  LeadNotification,
  LeadNotificationBodyField,
} from '../../../../../types';
import {
  createOrUpdateLeadNotification,
  getDefaultLeadNotification,
  getFormLeadNotification,
  getSingleLeadForms,
  sendTestLeadZapier,
} from '../../../../../services/lead';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../../../PageHeader';
import LeadFormNotification from './LeadFormNotification';
import { useForm } from 'react-hook-form';
import { FloatingButton, PrimaryButton } from '../../../../Buttons';
import { errorMessageParser } from '../../../../../utils/helpers/ToastHelper';
import { toggleAlert } from '../../../../../redux/actions';
import {
  buildDefaultNotificationFields,
  buildNotificationBodyFields,
  getQuestionKeys,
} from '../../../../../utils/helpers/LeadHelpers';
import { getSingleDiySetting } from '../../../../../services/diy';
import { adsProvider } from '../../../../../utils/constants/facebookAds';
import { topfireMediaId } from '../../../../../services/api';
import { DiySettings } from '../../../../../types/IDiy';
import { HtmlTooltip } from '../../../../Tooltip';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { securedUrlPattern } from '../../../../../utils/validation';
import LeadFormCrmMappingForm from './LeadFormCrmMappingForm';
import { CrmSetting } from '../../../../../types/ICrm';
import {
  createOrUpdateCustomFieldMapping,
  getCrmSettingsByBrand,
  getCustomFieldMapping,
} from '../../../../../services/crm';

const FacebookSendLeadForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const isTFM =
    ((location || brand)?.__type === 'location'
      ? (location || brand)?.franchisor?.agency?._id
      : (location || brand)?.agency?._id) === topfireMediaId;
  const campaignProvider: string =
    useSelector((state: any) => state?.campaignProvider?.campaignProvider) ||
    adsProvider.FACEBOOK;
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const params = useParams();
  const leadFormId = params.leadFormId;
  const {
    formState: { errors },
    handleSubmit,
    register,
    clearErrors,
    setValue,
    setError,
  } = useForm({
    mode: 'onSubmit',
  });

  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [leadForm, setLeadForm] = useState<LeadFormData>(null);
  const [formValues, setFormValues] = useState<LeadNotification>({
    recipients: [],
    cc: [],
    bcc: [],
    subject: '',
    bodyFields: [],
    brand: {
      _id: '',
    },
    zapierWebhookUrl: '',
    enableZapierWebhook: false,
    default: false,
  });
  const [enableSendLead, setEnableSendLead] = useState<boolean>(false);
  const [enableZapierWebhook, setEnableZapierWebhook] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [diySettings, setDiySettings] = useState<DiySettings>(null);
  const [testZapierLoading, setTestZapierLoading] = useState<boolean>(false);
  const [notificationLoading, setNotificationLoading] =
    useState<boolean>(false);
  const [defaultNotification, setDefaultNotification] =
    useState<LeadNotification>(null);
  const [defaultBodyFields, setDefaultBodyFields] = useState<
    LeadNotificationBodyField[]
  >([]);
  const [defaultBodyFieldsErrors, setDefaultBodyFieldsErrors] = useState<any[]>(
    [],
  );
  const [showClearDefaultFields, setShowClearDefaultFields] =
    useState<boolean>(false);
  const [defaultZapierErrors, setDefaultZapierErrors] = useState<string[]>([]);
  const [crmSettings, setCrmSettings] = useState<CrmSetting>(null);
  const [crmMapForms, setCrmMapForms] = useState<CustomCrmFieldMapForm[]>([]);

  useEffect(() => {
    if (location || brand) {
      getDiySettings();
      getCrmSettings();
    }
  }, [location, brand]);

  useEffect(() => {
    if (leadFormId) {
      getLeadForm();
      getCustomFieldMap();
    }
  }, [leadFormId]);

  useEffect(() => {
    if (leadForm && (location || brand)) {
      getLeadNotification();
    }
  }, [leadForm, location, brand]);

  useEffect(() => {
    if (defaultZapierErrors.length > 0) {
      const fields = defaultZapierErrors.join(', ');
      const errorMsg = `Your Zapier Webhook URL might not work due to missing fields (${fields}). Update your Zapier Webhook or create a new one.`;
      setError('zapierWebhookUrl', { type: 'custom', message: errorMsg });
    }
  }, [defaultZapierErrors]);

  useEffect(() => {
    if (defaultNotification?.bodyFields?.length > 0) {
      setEnableSendLead(true);
    }

    if (defaultNotification?.zapierWebhookUrl) {
      setEnableZapierWebhook(true);
    }
  }, [defaultNotification]);

  const getLeadForm = async () => {
    try {
      setFetchLoading(true);

      const response = await getSingleLeadForms(leadFormId);

      setLeadForm(response.data);
      setEnableSendLead(response.data.enableSendLead);
      setEnableZapierWebhook(response.data.enableZapierWebhook);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getLeadNotification = async () => {
    try {
      setNotificationLoading(true);

      const response = await getFormLeadNotification(
        leadFormId,
        (location || brand)?._id,
      );

      if (!response.data) {
        const defaultNotification = await getDefaultLeadNotification(
          (location || brand)?._id,
        );

        setDefaultNotification(defaultNotification.data);

        let temp: LeadNotification = {
          ...formValues,
          recipients: [],
          subject: `${isTFM ? 'TopFire Media' : 'Sales Chatz'} Facebook Lead: ${
            (location || brand)?.__type === 'location'
              ? `${(location || brand)?.franchisor?.brand}-${
                  (location || brand)?.brand
                }`
              : (location || brand)?.brand
          }`,
        };

        const defaultBodyFields: any[] = buildNotificationBodyFields(
          leadForm?.fields?.questions || [],
          campaignProvider,
        );

        if (defaultNotification.data) {
          setShowClearDefaultFields(true);
          setDefaultBodyFields(defaultBodyFields);
          const questionkeys = getQuestionKeys(leadForm?.fields.questions);

          const { bodyFields, errors } = buildDefaultNotificationFields(
            defaultNotification.data.bodyFields,
            questionkeys,
            setValue,
          );

          temp = {
            ...temp,
            bodyFields,
            subject: defaultNotification.data.subject,
            bcc: defaultNotification.data.bcc || [],
          };

          if (defaultNotification.data.zapierWebhookUrl) {
            setValue(
              `zapierWebhookUrl`,
              defaultNotification.data.zapierWebhookUrl,
            );

            temp = {
              ...temp,
              zapierWebhookUrl: defaultNotification.data.zapierWebhookUrl,
            };

            const defaultNotificationLeadForm = await getSingleLeadForms(
              defaultNotification.data.leadForm,
            );
            if (defaultNotificationLeadForm.data) {
              const defaultLeadFormQuestionKeys = getQuestionKeys(
                defaultNotificationLeadForm.data.fields.questions,
              );

              const errors = defaultLeadFormQuestionKeys.filter(
                (key: any) => !questionkeys.includes(key),
              );

              if (errors.length > 0) {
                const zapierErrors = errors.map((error: any) => {
                  const placeholders = error.match(/<<[^>]+>>/g) || [];

                  const cleanedPlaceholders = placeholders.map(
                    (placeholder: any) => placeholder.replace(/<<|>>/g, ''),
                  );

                  return cleanedPlaceholders;
                });

                setDefaultZapierErrors(zapierErrors);
              }
            }
          }

          setDefaultBodyFieldsErrors(errors);
        } else {
          temp = { ...temp, bodyFields: defaultBodyFields };
        }

        setFormValues(temp);
      } else {
        setFormValues(response.data);

        setDefaultValidationValue(response.data);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setNotificationLoading(false);
    }
  };

  const getDiySettings = async () => {
    try {
      setFetchLoading(true);

      const response = await getSingleDiySetting((location || brand)?._id);

      setDiySettings(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getCrmSettings = async () => {
    try {
      const response = await getCrmSettingsByBrand((location || brand)?._id);

      setCrmSettings(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const getCustomFieldMap = async () => {
    try {
      const response = await getCustomFieldMapping(leadFormId);

      setCrmMapForms(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const setDefaultValidationValue = (leadNotification: LeadNotification) => {
    Object.keys(leadNotification || {}).forEach((key: string) => {
      if (key === 'bodyFields') {
        leadNotification[key]?.forEach(
          (body: LeadNotificationBodyField, index: number) => {
            setValue(`body-label-${index}`, body.label);
            setValue(`body-value-${index}`, body.value);
          },
        );
      } else {
        setValue(key, leadNotification[key] || '');
      }
    });
  };

  const handleOnSubmit = async () => {
    try {
      setLoading(true);

      let temp: LeadNotification = {
        ...formValues,
        brand: {
          _id: (location || brand)?._id,
        },
        leadForm: {
          _id: leadForm?._id,
        },
        enableSendLead,
        enableZapierWebhook,
      };

      await createOrUpdateLeadNotification(temp);

      if (crmMapForms.length > 0) {
        await createOrUpdateCustomFieldMapping(leadForm?._id, crmMapForms);
      }

      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Successfully setup your lead notification',
        }),
      );
      navigate('/assetz');
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleTestZapier = async () => {
    setTestZapierLoading(true);
    try {
      const response = await sendTestLeadZapier(
        (location || brand)?._id,
        formValues,
        leadForm?.fields?.questions,
      );

      dispatch(toggleAlert({ toggle: true, message: response.data.message }));
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setTestZapierLoading(false);
    }
  };

  return (
    <Box className={`${styles.form} ${xsOnly ? `${styles['-mobile']}` : ''}`}>
      <CircularLoading loading={fetchLoading || notificationLoading} />

      <Box
        component="div"
        sx={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          margin: 0,
          marginBottom: '20px',
        }}
      >
        <Box component="div" sx={{ paddingBottom: 0 }}>
          <PageHeader
            title="SET YOUR LEAD NOTIFICATION"
            textAlign="left"
            marginBottom="0"
          />
        </Box>
      </Box>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sx={{ minHeight: 'calc(100vh - 222px)' }}>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ color: '#096F4D' }}>
                    Setup your email notification
                  </Typography>

                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="style"
                      name="style"
                      value={enableSendLead ? 'yes' : 'no'}
                      onChange={(e: ChangeEventType) => {
                        setEnableSendLead(e.target.value === 'yes');
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {enableSendLead ? (
            <LeadFormNotification
              formValues={formValues}
              setFormValues={setFormValues}
              register={register}
              clearErrors={clearErrors}
              errors={errors}
              questions={leadForm?.fields?.questions || []}
              brand={location || brand}
              setValue={setValue}
              setErrors={setError}
              handleSubmit={handleSubmit}
              diySettings={diySettings}
              setDiySettings={setDiySettings}
              defaultNotification={defaultNotification}
              errorFields={defaultBodyFieldsErrors}
              setErrorFields={setDefaultBodyFieldsErrors}
              defaultBodyFields={defaultBodyFields}
              showClearDefaultFields={showClearDefaultFields}
              setShowClearDefaultFields={setShowClearDefaultFields}
            />
          ) : null}

          <Grid container spacing={2} my={2}>
            <Grid item xs={12} sm={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{
                      justifyContent: 'flex-start',
                      color: '#096F4D',
                      display: 'flex',
                      margin: 0,
                      alignItems: 'center',
                    }}
                  >
                    {'Setup your Zapier Webhook URL'}

                    <HtmlTooltip
                      title={
                        <Fragment>
                          <Box
                            component="div"
                            sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                          >
                            Zapier Catch Hook URL
                          </Box>

                          <Box component="div" sx={{ marginBottom: '10px' }}>
                            The Zapier Webhook URL is a special web address that
                            helps you manage information from Facebook. When
                            someone fills out a form on Facebook, Facebook sends
                            the form details to this special address. Zapier can
                            then take this information and do things
                            automatically for you, like adding the details to a
                            spreadsheet, sending you an email, or updating your
                            customer records. This makes it easier for you to
                            keep track of new leads and ensures you don't miss
                            any important information.
                          </Box>

                          <Box component="div" sx={{ marginBottom: '10px' }}>
                            <a
                              href="https://help.zapier.com/hc/en-us/articles/8496288690317-Trigger-Zaps-from-webhooks"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Learn More
                            </a>
                          </Box>
                        </Fragment>
                      }
                    >
                      <InfoTwoToneIcon
                        sx={{ fontSize: '18px', color: '#096F4D' }}
                      />
                    </HtmlTooltip>
                  </Typography>

                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="style"
                      name="style"
                      value={enableZapierWebhook ? 'yes' : 'no'}
                      onChange={(e: ChangeEventType) => {
                        setEnableZapierWebhook(e.target.value === 'yes');
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />

                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {enableZapierWebhook ? (
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      {...(enableZapierWebhook
                        ? register('zapierWebhookUrl', {
                            required: true,
                            pattern: securedUrlPattern,
                          })
                        : {})}
                      fullWidth
                      required
                      variant="standard"
                      type="text"
                      name="zapierWebhookUrl"
                      label="Zapier Webhook URL"
                      onChange={(e: ChangeEventType) => {
                        clearErrors('zapierWebhookUrl');
                        setDefaultZapierErrors([]);
                        setFormValues({
                          ...formValues,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      value={formValues?.zapierWebhookUrl}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      error={!!errors.zapierWebhookUrl}
                      helperText={
                        errors?.zapierWebhookUrl?.message ||
                        (errors?.zapierWebhookUrl &&
                          'Website URL is required') ||
                        'Enter your Zapier Catch Webhook URL'
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <PrimaryButton
                      title="Test Zapier Webhook"
                      type="button"
                      handleOnClick={handleSubmit(handleTestZapier)}
                      loading={testZapierLoading}
                      disabled={defaultZapierErrors.length > 0}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          {!notificationLoading && (enableSendLead || enableZapierWebhook) ? (
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          defaultChecked={formValues?.default}
                        />
                      }
                      label="Set as default"
                      value={formValues?.default}
                      onChange={(e: any) => {
                        setFormValues({
                          ...formValues,
                          default: e.target.checked,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          {crmSettings?.providers?.length > 0 ? (
            <Grid container spacing={2} my={2}>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <LeadFormCrmMappingForm
                      formValues={crmMapForms}
                      setFormValues={setCrmMapForms}
                      brand={location || brand}
                      crmSettings={crmSettings}
                      questions={leadForm?.fields?.questions}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>

        <FloatingButton
          title="Cancel"
          onClick={() => navigate('/assetz')}
          theme="red"
          right="135px"
          loading={loading}
          fixed
        />

        <FloatingButton
          title="Save"
          onClick={handleSubmit(handleOnSubmit)}
          loading={loading}
          fixed
        />
      </Grid>
    </Box>
  );
};

export default FacebookSendLeadForm;
