import {
  Alert,
  Card,
  Grid,
  Grow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import TopNavBar from '../components/Navigation/TopNavBar';
import { AuthContext } from '../context';
import {
  ADMIN,
  AGENCY,
  AUTH_TOKEN,
  LOGGED_IN_USER,
  SUPER_ADMIN,
} from '../utils';
import SideNavBar from '../components/Navigation/SideNavBar';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Brand, SubscribedApp } from '../types';
import CircularLoading from '../components/CircularLoading';
import Toast from '../components/Notification/Toast';
import { isExpired, resetSession } from '../utils/tokenValidator';
import {
  setAgencies,
  setAgency,
  setBrand,
  setBrandFilter,
  setBrands,
  setBrandTimezone,
  setBudgetReportProvider,
  setCampaignProvider,
  setLocation,
  setLocations,
  toggleAlert,
} from '../redux/actions';
import MobileFilters from '../components/Navigation/MobileFilters';
import { diyAdzDrawers } from '../components/Navigation/Drawers';
import { DEFAULT_COLOR_THEME } from '../utils/Styling';
import { adsProvider } from '../utils/constants/facebookAds';
import { getCrmSettingsByBrand } from '../services/crm';
import { CrmSetting } from '../types/ICrm';
import { PrimaryButton } from '../components/Buttons';
import { DiySettings } from '../types/IDiy';
import { chatzAppId } from '../services/api';
import { errorMessageParser } from '../utils/helpers/ToastHelper';
import { getSubscribedApps, subscribeToApp } from '../services/page';
import { getSingleDiySetting } from '../services/diy';

const DRAWER_WIDTH = 240;

export const DiyMarketingManagerLayout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const brandId = searchParams.get('brandId') || null;
  const locationId = searchParams.get('locationId') || null;
  const redirectUrl = searchParams.get('redirectUrl') || null;
  const hubSpotAuthCode = searchParams.get('code') || null;
  const theme = useTheme();
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [subscribeLoading, setSubscribeLoading] = useState<boolean>(false);
  const [fetchAppsLoading, setFetchAppsLoading] = useState<boolean>(true);
  const [subscribedApps, setSubscribedApps] = useState<SubscribedApp[]>([]);
  const [diySettings, setDiySettings] = useState<DiySettings>(null);
  const [showMobileOptions, setShowMobileOptions] = useState<boolean>(false);
  const [crmSettings, setCrmSettings] = useState<CrmSetting>(null);
  const pathLocation = useLocation();
  const { state, dispatch: authDispatch } = useContext(AuthContext);
  const isAdmin = state.role === ADMIN;
  const isSuperAdmin = state.role === SUPER_ADMIN;
  const isAgency = state.role === AGENCY;
  const isAuthenticated = state.isAuthenticated;
  const locationBrand = state.authUser?.brand;
  const capabilities = state.capabilities;
  const withAgencyAccess = state.withAgencyAccess;
  const campaignProvider = useSelector(
    (state: any) => state.campaignProvider.campaignProvider,
  );
  const isFranchisee = locationBrand !== null && locationBrand !== undefined;
  const brand: Brand = useSelector((state: any) => state.brand?.brand);
  const location: Brand = useSelector((state: any) => state.location?.location);
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const isFacebook = campaignProvider === adsProvider.FACEBOOK;

  const pathNames = pathLocation.pathname.split('/');
  const isScoreboard =
    pathNames.includes('scorecardz') || pathNames.includes('trendz');
  const isCreateBrand =
    (pathNames.includes('brandz') || pathNames.includes('locationz')) &&
    pathNames.includes('create');
  const isSubscribedAppAlert =
    !fetchAppsLoading &&
    (location || brand) &&
    !isCreateBrand &&
    subscribedApps?.length === 0 &&
    diySettings?.facebookPage?._id &&
    isFacebook;

  useEffect(() => {
    if (location || brand) {
      getCrmSettings();
      const pathNames = pathLocation.pathname.split('/');
      const currentPath = pathNames[1];
      const paths = diyAdzDrawers(
        location ? location : brand,
        isFacebook,
        crmSettings,
        isAdmin || isSuperAdmin,
        capabilities,
        withAgencyAccess,
      ).map((path: any) => {
        return path.pathName;
      });

      if (
        !paths.includes(currentPath) &&
        !brandId &&
        !locationId &&
        !redirectUrl &&
        !hubSpotAuthCode
      ) {
        navigate('/reportz/kpiz');
      }
    }
  }, [brand, location, capabilities, withAgencyAccess]);

  useEffect(() => {
    if (isExpired(authToken) || !isAuthenticated) {
      resetSession();
      resetReduxSession();
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Your session has been expired',
          type: 'error',
          horizontal: 'center',
          vertical: 'top',
        }),
      );

      navigate(
        `/login${brandId ? `?brandId=${brandId}` : ''}${
          locationId ? `&locationId=${locationId}` : ''
        }${redirectUrl ? `&redirectUrl=${redirectUrl}` : ''}`,
      );
    }
  }, [pathLocation]);

  useEffect(() => {
    if ((location || brand) && isFacebook) {
      getPageSubscribedApp();
    }
  }, [location, brand, isFacebook]);

  const getCrmSettings = async () => {
    try {
      const response = await getCrmSettingsByBrand((location || brand)?._id);

      setCrmSettings(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const getPageSubscribedApp = async () => {
    try {
      setFetchAppsLoading(true);

      const settings = await getSingleDiySetting((location || brand)?._id);

      setDiySettings(settings.data);

      const response = await getSubscribedApps(
        (location || brand)?._id,
        settings?.data?.facebookPage?.fbPageId,
      );

      const filteredApps =
        response?.data?.filter((app: any) => app.id === chatzAppId) || [];

      setSubscribedApps(filteredApps);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchAppsLoading(false);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resetReduxSession = () => {
    authDispatch({
      type: LOGGED_IN_USER,
      payload: {
        token: null,
        role: null,
        roleBasedId: null,
        user: null,
        capabilities: null,
        withBrandAccess: null,
        withAgencyAccess: null,
        isAuthenticated: false,
      },
    });
    dispatch(setBrands([]));
    dispatch(setBrand(null));
    dispatch(setBrandTimezone(null));
    dispatch(setAgencies([]));
    dispatch(setAgency(null));
    dispatch(setBrandFilter(null));
    dispatch(setCampaignProvider(null));
    dispatch(setBudgetReportProvider(null));
    dispatch(setLocations([]));
    dispatch(setLocation(null));
  };

  const handleOpenMobileOptions = () => {
    setShowMobileOptions((show) => !show);
  };

  const handleOnSubscribeToApp = async () => {
    try {
      setSubscribeLoading(true);
      const response = await subscribeToApp(
        (location || brand)?._id,
        diySettings?.facebookPage?.fbPageId,
      );

      const filteredResponse =
        response?.data?.filter((app: any) => app.id === chatzAppId) || [];

      setSubscribedApps(filteredResponse);

      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Your Facebook page is now subscribed to aiADZ Facebook app',
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, type: 'error', message: errorMsg }));
    } finally {
      setSubscribeLoading(false);
    }
  };

  const renderSubscribedAppAlert = () => {
    if (isSubscribedAppAlert) {
      return (
        <Grow in>
          <Grid container spacing={2} sx={{ pb: 2, px: 0 }}>
            <Grid item xs={12}>
              <Alert
                sx={{ display: 'flex', alignItems: 'center' }}
                variant="filled"
                severity="error"
                action={
                  <PrimaryButton
                    title="Subscribe"
                    type="button"
                    variant="text"
                    theme="white"
                    handleOnClick={handleOnSubscribeToApp}
                    loading={subscribeLoading}
                  />
                }
              >
                You are not subscribed to the aiADZ Facebook App. This will
                prevent you from sending leads to aiADZ.
              </Alert>
            </Grid>
          </Grid>
        </Grow>
      );
    }

    return <></>;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CircularLoading loading={loading} />

      <TopNavBar
        drawerWidth={DRAWER_WIDTH}
        onOpenDrawer={handleDrawerToggle}
        setLoading={setLoading}
        onOpenMobileOptions={handleOpenMobileOptions}
      />

      <SideNavBar
        drawerWidth={DRAWER_WIDTH}
        onOpenDrawer={handleDrawerToggle}
        open={mobileOpen}
        isAdmin={isSuperAdmin || isAdmin}
        isAgency={isAgency}
        isFacebook={isFacebook}
        brand={brand}
        location={location}
        isFranchisee={isFranchisee}
        capabilities={capabilities}
        withAgencyAccess={withAgencyAccess}
        crmSettings={crmSettings}
      />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: {
            md: `calc(100% - ${
              isScoreboard
                ? isSubscribedAppAlert
                  ? 223
                  : DRAWER_WIDTH
                : DRAWER_WIDTH
            }px)`,
          },
          marginLeft: { md: `${DRAWER_WIDTH}px` },
          backgroundColor: '#f5f5f5',
          marginTop: '50px',
        }}
      >
        {xsOnly ? (
          <MobileFilters open={showMobileOptions} setLoading={setLoading} />
        ) : null}

        {renderSubscribedAppAlert()}

        {isScoreboard ? (
          <Box
            sx={{
              minHeight: `calc(100vh - ${isSubscribedAppAlert ? 163 : 100}px)`,
              maxHeight: `calc(100vh - ${isSubscribedAppAlert ? 163 : 100}px)`,
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#F1F0F0',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
              },

              '&::-webkit-scrollbar-thumb': {
                background: DEFAULT_COLOR_THEME,
                borderRadius: '18px',

                '&:hover': {
                  background: DEFAULT_COLOR_THEME,
                },
              },
            }}
          >
            <Outlet />
          </Box>
        ) : (
          <Card
            sx={{
              minHeight: `calc(100vh - ${isSubscribedAppAlert ? 163 : 100}px)`,
              maxHeight: `calc(100vh - ${isSubscribedAppAlert ? 163 : 100}px)`,
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-track': {
                // background: "#F1F0F0",
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
              },

              '&::-webkit-scrollbar-thumb': {
                background: DEFAULT_COLOR_THEME,
                borderRadius: '18px',

                '&:hover': {
                  background: DEFAULT_COLOR_THEME,
                },
              },
            }}
          >
            {/*
              * Temporary disable
              * Uncomment if needed
            <CardHeader avatar={<SimpleBreadcrumbs />}></CardHeader>
            */}

            <Outlet />
          </Card>
        )}
      </Box>

      <Toast />

      <footer></footer>
    </Box>
  );
};
