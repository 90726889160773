import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  ListItem,
  List,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions';

interface GGLTemplatesDisplayKeywordsProps {
  submitForm: any;
  templateForm: any;
  setIsDirty: any;
  templateType: string;
}

const GGLTemplatesDisplayKeywords: React.FC<
  GGLTemplatesDisplayKeywordsProps
> = ({ submitForm, templateForm, setIsDirty, templateType }) => {
  const dispatch = useDispatch();
  const [keywordsInput, setKeywordsInput] = useState('');
  const [keywords, setKeywords] = useState([]);
  const limit = templateType === 'performancemax' ? 25 : 100;

  const handleTextFieldEdit = (e: any) => {
    setKeywordsInput(e.target.value);
    let raw = e.target.value
      .split('\n')
      .map((keyword: string) => keyword.trim().toLowerCase())
      .filter((keyword: string) => keyword.trim() !== '');

    raw = raw.filter(
      (value: string, index: number, self: string[]) =>
        self.indexOf(value) === index,
    );

    setKeywords(raw);
    submitForm(raw);
  };

  useEffect(() => {
    if (templateForm) {
      setKeywordsInput(templateForm.join('\n'));
      setKeywords(templateForm);
      submitForm(templateForm);
    }
  }, []);
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        gap: 4,
      }}
    >
      <TextField
        label={templateType === 'performancemax' ? 'Search Themes' : 'Keywords'}
        variant="outlined"
        value={keywordsInput}
        sx={{
          width: '100%',
          maxWidth: 500,
          ...scrollBarGreen,
        }}
        onChange={(e) => {
          handleTextFieldEdit(e);
        }}
        multiline
        rows={20}
      />
      <Box sx={{}}>
        {keywords.length > 0 ? (
          <>
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', color: DEFAULT_COLOR_THEME }}
            >
              Total{' '}
              {templateType === 'performancemax' ? 'Search Themes' : 'Keywords'}
              : [{keywords.length}]{' '}
              <span style={{ color: 'red' }}>
                {keywords.length > limit ? `(${limit} limit)` : ''}
              </span>
            </Typography>
            <Box sx={{ columns: 2 }}>
              <List>
                {keywords.map((keyword, index) => {
                  return (
                    <ListItem
                      disableGutters
                      key={index}
                      sx={{
                        paddingLeft: 2,
                        paddingY: 0,
                      }}
                    >
                      • {keyword}
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default GGLTemplatesDisplayKeywords;
