import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { MdPauseCircleOutline } from 'react-icons/md';
import { DEFAULT_COLOR_THEME } from '../../utils';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

const locationlist = [
  'New York - Manhattan',
  'Los Angeles - Downtown',
  'Chicago - North Side',
  'Houston - Midtown',
  'Phoenix - Central City',
  'San Francisco - Financial District',
  'Miami - South Beach',
  'Seattle - Capitol Hill',
  'Boston - Back Bay',
  'Denver - LoDo',
];

const campaignslist = [
  'aiAdz-Search-1',
  'aiAdz-Search-2',
  'aiAdz-Search-3',
  'aiAdz-Display-1',
  'aiAdz-Display-2',
  'aiAdz-Display-3',
  'aiAdz-PMax-1',
  'aiAdz-PMax-2',
  'aiAdz-PMax-3',
];

const adgroupslist = [
  'aiAdz-AdGroup-1',
  'aiAdz-AdGroup-2',
  'aiAdz-AdGroup-3',
  'aiAdz-AdGroup-4',
  'aiAdz-AdGroup-5',
  'aiAdz-AdGroup-6',
  'aiAdz-AdGroup-7',
  'aiAdz-AdGroup-8',
  'aiAdz-AdGroup-9',
];

const templates = [
  'aiAdz-Template-1',
  'aiAdz-Template-2',
  'aiAdz-Template-3',
  'aiAdz-Template-4',
  'aiAdz-Template-5',
  'aiAdz-Template-6',
  'aiAdz-Template-7',
  'aiAdz-Template-8',
];

const statsBuilder = () => {
  return {
    clicks: Math.floor(Math.random() * 1000),
    cpc: Math.random() * 100,
    impressions: Math.floor(Math.random() * 1000),
    cpm: Math.random() * 100,
    leads: Math.random() * 100,
    cpl: Math.random() * 100,
    qleads: Math.random() * 100,
    cpql: Math.random() * 100,
    costs: Math.random() * 100,
  };
};

const campaignBuilder = () => {
  const campaign =
    campaignslist[Math.floor(Math.random() * campaignslist.length)];
  const adgroup = adgroupslist[Math.floor(Math.random() * adgroupslist.length)];
  const template = templates[Math.floor(Math.random() * templates.length)];
  //piok 3 random locations from locationlist
  const locations = locationlist
    .sort(() => Math.random() - Math.random())
    .slice(0, 3);

  return {
    campaign,
    adgroup,
    template,
    locations,
    level: 'Campaign',
    ...statsBuilder(),
  };
};

const adgroupBuilder = () => {
  const adgroup = adgroupslist[Math.floor(Math.random() * adgroupslist.length)];
  const campaign =
    campaignslist[Math.floor(Math.random() * campaignslist.length)];
  const template = templates[Math.floor(Math.random() * templates.length)];
  //piok 3 random locations from locationlist
  const locations = locationlist
    .sort(() => Math.random() - Math.random())
    .slice(0, 3);

  return {
    adgroup,
    campaign,
    template,
    locations,
    level: 'Ad Group',
    ...statsBuilder(),
  };
};

function createLocationData(
  group: string,
  level: string,
  clicks: number,
  cpc: number,
  impressions: number,
  cpm: number,
  leads: number,
  cpl: number,
  qleads: number,
  cpql: number,
  costs: number,
  campaigns: {
    name: string;
    level: string;
    clicks: number;
    cpc: number;
    impressions: number;
    cpm: number;
    leads: number;
    cpl: number;
    qleads: number;
    cpql: number;
    costs: number;
    status: string;
    adgroups?: any[];
  }[],
) {
  return {
    group,
    level,
    clicks,
    cpc,
    impressions,
    cpm,
    leads,
    cpl,
    qleads,
    cpql,
    costs,
    status: Math.random() > 0.5 ? 'ENABLED' : 'PAUSED',
    campaigns,
  };
}

function createCampaignData(
  name: string,
  level: string,
  clicks: number,
  cpc: number,
  impressions: number,
  cpm: number,
  leads: number,
  cpl: number,
  qleads: number,
  cpql: number,
  costs: number,
  status: string,
  adgroups?: {
    name: string;
    clicks: number;
    cpc: number;
    impressions: number;
    cpm: number;
    leads: number;
    cpl: number;
    qleads: number;
    cpql: number;
    costs: number;
    status: string;
  }[],
) {
  return {
    name,
    level,
    clicks,
    cpc,
    impressions,
    cpm,
    leads,
    cpl,
    qleads,
    cpql,
    costs,
    adgroups,
    //random between "PAUSED" && "ENABLED"
    status,
  };
}

function createAdGroupData(
  name: string,
  level: string,
  clicks: number,
  cpc: number,
  impressions: number,
  cpm: number,
  leads: number,
  cpl: number,
  qleads: number,
  cpql: number,
  costs: number,
  ads?: {
    name: string;
    clicks: number;
    cpc: number;
    impressions: number;
    cpm: number;
    leads: number;
    cpl: number;
    qleads: number;
    cpql: number;
    costs: number;
  }[],
) {
  return {
    name,
    level,
    clicks,
    cpc,
    impressions,
    cpm,
    leads,
    cpl,
    qleads,
    cpql,
    costs,
    ads,
  };
}

function AdRow(props: {
  row: ReturnType<typeof createAdGroupData>;
  display: boolean;
}) {
  const { row, display } = props;
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (!display) {
      setOpen(false);
    }
  }, [display]);

  return (
    <TableRow
      sx={{
        display: display ? 'table-row' : 'none',
        backgroundColor: '#ffffff',
      }}
    >
      <TableCell>
        <IconButton
          sx={{
            display: 'none', //change this to row.adgroups.length > 0 ? 'table-cell' : 'none' when we have keywords
          }}
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell component="th" scope="row" sx={{ pl: 6 }}>
        {row.name}
      </TableCell>
      <TableCell align="left">{row.level}</TableCell>
      <TableCell align="right">{row.clicks}</TableCell>
      <TableCell align="right">$ {row.cpc}</TableCell>
      <TableCell align="right">{row.impressions}</TableCell>
      <TableCell align="right">$ {row.cpm}</TableCell>
      <TableCell align="right">{row.leads}</TableCell>
      <TableCell align="right">$ {row.cpl}</TableCell>
      <TableCell align="right">{row.qleads}</TableCell>
      <TableCell align="right">$ {row.cpql}</TableCell>
      <TableCell align="right">$ {row.costs}</TableCell>
    </TableRow>
  );
}

function AdGroupRow(props: {
  row: ReturnType<typeof createAdGroupData>;
  display: boolean;
}) {
  const { row, display } = props;
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (!display) {
      setOpen(false);
    }
  }, [display]);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          display: display ? 'table-row' : 'none',
          backgroundColor: '#b9d870',
        }}
      >
        <TableCell>
          <IconButton
            sx={{ display: row.ads?.length > 0 ? 'table-cell' : 'none' }}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ pl: 4 }}>
          {row.name}
        </TableCell>
        <TableCell align="left">{row.level}</TableCell>
        <TableCell align="right">{Math.round(row.clicks)}</TableCell>
        <TableCell align="right">$ {row.cpc.toFixed(2)}</TableCell>
        <TableCell align="right">{Math.round(row.impressions)}</TableCell>
        <TableCell align="right">$ {row.cpm.toFixed(2)}</TableCell>
        <TableCell align="right">{Math.round(row.leads)}</TableCell>
        <TableCell align="right">$ {row.cpl.toFixed(2)}</TableCell>
        <TableCell align="right">{Math.round(row.qleads)}</TableCell>
        <TableCell align="right">$ {row.cpql.toFixed(2)}</TableCell>
        <TableCell align="right">$ {row.costs.toFixed(2)}</TableCell>
      </TableRow>

      {/* CHILDREN TABLE */}

      {row.ads.map((adgroup, i) =>
        AdRow({
          row: createAdGroupData(
            adgroup.name,
            'Ad',
            adgroup.clicks,
            adgroup.cpc,
            adgroup.impressions,
            adgroup.cpm,
            adgroup.leads,
            adgroup.cpl,
            adgroup.qleads,
            adgroup.cpql,
            adgroup.costs,
            [],
          ),
          display: open,
        }),
      )}
      {/* CHILDREN TABLE */}
    </React.Fragment>
  );
}

function CampaignRow(props: {
  row: any;
  display: boolean;
  reportview: string;
}) {
  const { row, display, reportview } = props;
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (!display) {
      setOpen(false);
    }
  }, [display]);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          display: display ? 'table-row' : 'none',
          backgroundColor: '#b6c61a',
        }}
      >
        <TableCell>
          <IconButton
            sx={{ display: row.adgroups?.length > 0 ? 'table-cell' : 'none' }}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ pl: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {row.name}{' '}
            {row.status === 'PAUSED' ? (
              <MdPauseCircleOutline size={20} />
            ) : null}
          </Box>
        </TableCell>
        <TableCell align="left">{row.level}</TableCell>
        <TableCell align="right">{Math.round(row.clicks)}</TableCell>
        <TableCell align="right">$ {row.cpc.toFixed(2)}</TableCell>
        <TableCell align="right">{Math.round(row.impressions)}</TableCell>
        <TableCell align="right">$ {row.cpm.toFixed(2)}</TableCell>
        <TableCell align="right">{Math.round(row.leads)}</TableCell>
        <TableCell align="right">$ {row.cpl.toFixed(2)}</TableCell>
        <TableCell align="right">{Math.round(row.qleads)}</TableCell>
        <TableCell align="right">$ {row.cpql.toFixed(2)}</TableCell>
        <TableCell align="right">$ {row.costs.toFixed(2)}</TableCell>
      </TableRow>

      {/* CHILDREN TABLE */}
      {row.adgroups?.map((adgroup: any, i: number) =>
        AdGroupRow({
          row: createAdGroupData(
            adgroup.name,
            adgroup.level,
            adgroup.clicks,
            adgroup.cpc,
            adgroup.impressions,
            adgroup.cpm,
            adgroup.leads,
            adgroup.cpl,
            adgroup.qleads,
            adgroup.cpql,
            adgroup.costs,
            reportview === 'adgroup'
              ? []
              : [
                  {
                    name: 'Ad 1',
                    clicks: 305,
                    cpc: 3.7,
                    impressions: 67,
                    cpm: 4.3,
                    leads: 2.5,
                    cpl: 1.5,
                    qleads: 1.5,
                    cpql: 1.5,
                    costs: 1.5,
                  },
                  {
                    name: 'Ad 2',
                    clicks: 305,
                    cpc: 3.7,
                    impressions: 67,
                    cpm: 4.3,
                    leads: 2.5,
                    cpl: 1.5,
                    qleads: 1.5,
                    cpql: 1.5,
                    costs: 1.5,
                  },
                ],
          ),
          display: open,
        }),
      )}
      {/* CHILDREN TABLE */}
    </React.Fragment>
  );
}

function LocationRow(props: {
  row: ReturnType<typeof createLocationData>;
  reportview: string;
}) {
  const { row, reportview } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ backgroundColor: '#e6ffd4' }}>
        <TableCell>
          <IconButton
            sx={{ display: row.campaigns.length > 0 ? 'table-cell' : 'none' }}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.group}
        </TableCell>
        <TableCell align="left">{row.level}</TableCell>
        <TableCell align="right">{Math.round(row.clicks)}</TableCell>
        <TableCell align="right">$ {row.cpc.toFixed(2)}</TableCell>
        <TableCell align="right">{Math.round(row.impressions)}</TableCell>
        <TableCell align="right">$ {row.cpm.toFixed(2)}</TableCell>
        <TableCell align="right">{Math.round(row.leads)}</TableCell>
        <TableCell align="right">$ {row.cpl.toFixed(2)}</TableCell>
        <TableCell align="right">{Math.round(row.qleads)}</TableCell>
        <TableCell align="right">$ {row.cpql.toFixed(2)}</TableCell>
        <TableCell align="right">$ {row.costs.toFixed(2)}</TableCell>
      </TableRow>

      {/* CHILDREN TABLE */}
      {row.campaigns.map((campaign, i) =>
        CampaignRow({
          row: createCampaignData(
            campaign.name,
            campaign.level,
            campaign.clicks,
            campaign.cpc,
            campaign.impressions,
            campaign.cpm,
            campaign.leads,
            campaign.cpl,
            campaign.qleads,
            campaign.cpql,
            campaign.costs,
            campaign.status,
            campaign.adgroups,
          ),
          display: open,
          reportview: reportview,
        }),
      )}

      {/* CHILDREN TABLE */}
    </React.Fragment>
  );
}

export default function GoogleMultiUnitReport() {
  const [reportView, setReportView] = React.useState('multiunit');
  const [campaigns, setCampaigns] = React.useState(
    //create 30 random campaigns
    Array.from({ length: 30 }, () => campaignBuilder()),
  );
  const [adgroups, setAdgroups] = React.useState(
    //create 30 random adgroups
    Array.from({ length: 50 }, () => adgroupBuilder()),
  );

  const [locations, setLocations] = React.useState([]);
  const [locationGroup, setLocationGroup] = React.useState({});

  const [locationRows, setLocationRows] = React.useState([]);
  const [locationRowsB, setLocationRowsB] = React.useState([]);
  const [locationRowsC, setLocationRowsC] = React.useState([]);

  const [OGlocationRows, setOGLocationRows] = React.useState([]);

  const [campaignRows, setCampaignRows] = React.useState([]);

  const [adgroupRows, setAdgroupRows] = React.useState([]);

  console.log(`🚀 campaigns`);
  console.log(campaigns);

  console.log(`🚀 adgroups`);
  console.log(adgroups);

  React.useEffect(() => {
    console.log(`🚀 reportView change`);
    console.log(reportView);
    if (reportView === `multiunit`) {
      setLocationRows(OGlocationRows);
    }

    if (reportView === `campaign`) {
      setLocationRowsB(
        OGlocationRows.map((lr) => {
          return {
            ...lr,
            group: [...campaignslist]
              .sort(
                //random sort
                () => Math.random() - 0.5,
              )
              .pop(),
            level: 'Campaign',
            campaigns: lr.campaigns
              .map((c: any) => {
                return {
                  ...c,
                  name: [...locationlist]
                    .sort(
                      //random sort
                      () => Math.random() - 0.5,
                    )
                    .pop(),
                  level: 'Unit',
                  adgroups: [],
                };
              })
              .filter(
                //filter duplicate group
                (v: any, i: any, a: any) =>
                  a.findIndex((t: any) => t.name === v.name) === i,
              )
              .sort(
                //sort by name
                (a: any, b: any) => a.name.localeCompare(b.name),
              ),
          };
        })
          .filter(
            //filter duplicate group
            (v, i, a) => a.findIndex((t) => t.group === v.group) === i,
          )
          .sort(
            //sort by group
            (a, b) => a.group.localeCompare(b.group),
          ),
      );
    }

    if (reportView === `adgroup`) {
      setLocationRowsC(
        OGlocationRows.map((lr) => {
          return {
            ...lr,
            group: [...campaignslist]
              .sort(
                //random sort
                () => Math.random() - 0.5,
              )
              .pop(),
            level: 'Campaign',
            campaigns: lr.campaigns
              .map((c: any) => {
                return {
                  ...c,
                  name: [...adgroupslist]
                    .sort(
                      //random sort
                      () => Math.random() - 0.5,
                    )
                    .pop(),
                  level: 'Ad Group',
                  adgroups: [...locationlist].map((l) => {
                    return {
                      name: l,
                      level: 'Unit',
                      ...statsBuilder(),
                    };
                  }),
                };
              })
              .filter(
                //filter duplicate group
                (v: any, i: any, a: any) =>
                  a.findIndex((t: any) => t.name === v.name) === i,
              )
              .sort(
                //sort by name
                (a: any, b: any) => a.name.localeCompare(b.name),
              ),
          };
        })
          .filter(
            //filter duplicate group
            (v, i, a) => a.findIndex((t) => t.group === v.group) === i,
          )
          .sort(
            //sort by group
            (a, b) => a.group.localeCompare(b.group),
          ),
      );
    }
  }, [reportView]);

  React.useEffect(() => {
    const templocations: string[] = [];
    const templocationgroup: any = {};
    const tempcampaigns = [];
    const tempadgroupsgroup: any = {};

    adgroups.forEach((a) => {
      if (a.campaign) {
        if (!tempadgroupsgroup[a.campaign]) {
          tempadgroupsgroup[a.campaign] = [];
        }
        tempadgroupsgroup[a.campaign].push({
          ...a,
          level: 'Ad Group',
          name: a.adgroup,
          status: Math.random() > 0.3 ? 'ENABLED' : 'PAUSED',
        });
      }
    });

    console.log(`🚀 tempadgroupsgroup`);
    console.log(tempadgroupsgroup);

    campaigns.forEach((c) => {
      c.locations.forEach((l) => {
        if (!templocations.includes(l)) {
          templocations.push(l);
        }
        if (!templocationgroup[l]) {
          templocationgroup[l] = [];
        }
        templocationgroup[l].push({
          ...c,
          name: c.campaign,
          status: Math.random() > 0.3 ? 'ENABLED' : 'PAUSED',
          adgroups:
            tempadgroupsgroup[c.campaign]
              .filter(
                //remove duplicates
                (v: any, i: any, a: any) =>
                  a.findIndex((t: any) => t.name === v.name) === i,
              )
              .splice(0, 2)
              .sort(
                //sort by name
                (a: any, b: any) => a.name.localeCompare(b.name),
              ) || [],
        });
      });
    });
    setLocations(templocations);
    console.log(`🚀 templocations`);
    console.log(templocations);

    setLocationGroup(templocationgroup);
    console.log(`🚀 templocationgroup`);
    console.log(templocationgroup);

    const templocationrows = templocations.map((l) => {
      return {
        group: l,
        level: 'Location',
        ...statsBuilder(),
        campaigns: templocationgroup[l]
          .filter(
            //remove duplicate names,
            (v: any, i: any, a: any) =>
              a.findIndex((t: any) => t.name === v.name) === i,
          )
          .sort(
            //sort by name
            (a: any, b: any) => a.name.localeCompare(b.name),
          ),
      };
    });

    setOGLocationRows(templocationrows);
    setLocationRows(templocationrows);

    console.log(`🚀 templocationrows`);
    console.log(templocationrows);
  }, []);

  return (
    <Box marginTop={4} marginBottom={2}>
      <Typography
        variant="h6"
        fontWeight="bold"
        textAlign="center"
        color={DEFAULT_COLOR_THEME}
      >
        MULTI UNIT REPORT
      </Typography>
      <Box>
        <Box>
          <FormControl
            sx={{ float: 'right', m: 1, minWidth: 120 }}
            size="small"
          >
            <InputLabel
              id="report-view-select-label"
              sx={{ backgroundColor: `#ffffff` }}
            >
              Report View
            </InputLabel>
            <Select
              labelId="report-view-select-label"
              id="report-view-select"
              value={reportView}
              onChange={(e) => {
                setReportView(e.target.value);
              }}
              sx={{
                width: '100%',
              }}
            >
              <MenuItem value={`multiunit`}>Multi Unit</MenuItem>
              <MenuItem value={`campaign`}>Campaign</MenuItem>
              <MenuItem value={`adgroup`}>Ad group</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {reportView === `multiunit` ? (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: '#096f4d',
                  }}
                >
                  <TableCell />
                  <TableCell sx={{ color: '#ffffff' }}></TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="left">
                    Level
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    Clicks
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    CPC
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    Impressions
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    CPM
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    Leads
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    CPL
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    QLeads
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    CPQL
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    Costs
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locationRows.map((row, i) => (
                  <LocationRow
                    key={`${row.group} ${i}`}
                    row={row}
                    reportview={reportView}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}

        {reportView === `campaign` ? (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: '#096f4d',
                  }}
                >
                  <TableCell />
                  <TableCell sx={{ color: '#ffffff' }}></TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="left">
                    Level
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    Clicks
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    CPC
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    Impressions
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    CPM
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    Leads
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    CPL
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    QLeads
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    CPQL
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    Costs
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locationRowsB.map((row, i) => (
                  <LocationRow
                    key={`${row.group} ${i}`}
                    row={row}
                    reportview={reportView}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}

        {reportView === `adgroup` ? (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small">
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: '#096f4d',
                  }}
                >
                  <TableCell />
                  <TableCell sx={{ color: '#ffffff' }}></TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="left">
                    Level
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    Clicks
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    CPC
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    Impressions
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    CPM
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    Leads
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    CPL
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    QLeads
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    CPQL
                  </TableCell>
                  <TableCell sx={{ color: '#ffffff' }} align="right">
                    Costs
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locationRowsC.map((row, i) => (
                  <LocationRow
                    key={`${row.group} ${i}`}
                    row={row}
                    reportview={reportView}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Box>
    </Box>
  );
}
