import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '../../../../Buttons';
import {
  CustomAudience,
  SharedAccountInfo,
} from '../../../../../types/IAudience';
import LookalikeAudienceTable from '../../../../Tables/AudienceList/LookalikeAudienceTable';
import { Admin, Brand, Country } from '../../../../../types';
import {
  getCustomAudiences,
  shareCustomAudiences,
} from '../../../../../services/diy';
import { DiySettings } from '../../../../../types/IDiy';
import { useDispatch } from 'react-redux';
import { errorMessageParser } from '../../../../../utils/helpers/ToastHelper';
import { toggleAlert } from '../../../../../redux/actions';
import PopupModal from '../../../../Modal';
import ModalHeader from '../../../../Modal/ModalHeader';
import { fetchAdminData } from '../../../../../services/admin';

interface AudienceFormProps {
  brand: Brand;
  diySettings: DiySettings;
}

const AudienceForm: React.FC<AudienceFormProps> = ({ brand, diySettings }) => {
  const dispatch = useDispatch();
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sharedLookalikeAudiences, setSharedLookalikeAudiences] = useState<
    CustomAudience[]
  >([]);
  const [selectedCustomAudiences, setSelectedCustomAudiences] = useState<
    CustomAudience[]
  >([]);
  const [shareableCustomAudiences, setShareableCustomAudiences] = useState<
    CustomAudience[]
  >([]);
  const [openRequestForm, setOpenRequestForm] = useState<boolean>(false);
  const [adminData, setAdminData] = useState<Admin>(null);

  const TARGET_COUNTRIES: string[] = diySettings?.targetCountries?.map(
    (targetCountry: Country) => {
      return targetCountry.code;
    },
  ) || ['US'];

  useEffect(() => {
    getAdminData();
  }, []);

  useEffect(() => {
    if (
      diySettings?.fbAdAccount?.accountId &&
      adminData?.shareableLookalikeAudienceIds?.length > 0
    ) {
      getShareableLookalikeAudiences();
    }
  }, [
    diySettings?.fbAdAccount?.accountId,
    adminData?.shareableLookalikeAudienceIds,
  ]);

  const getAdminData = async () => {
    try {
      const response = await fetchAdminData();

      setAdminData(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const getShareableLookalikeAudiences = async () => {
    try {
      setFetchLoading(true);

      const response = await getCustomAudiences();

      const lookalikeAudiences: CustomAudience[] =
        response?.data?.filter(
          (audience: CustomAudience) => audience.subtype === 'LOOKALIKE',
        ) || [];

      const sharedLookalikeAudience = lookalikeAudiences.filter(
        (audience: CustomAudience) => {
          const filterAccountIds =
            audience?.shared_account_info?.data?.map(
              (sharedAccount: SharedAccountInfo) => sharedAccount.account_id,
            ) || [];

          return (
            filterAccountIds.includes(diySettings.fbAdAccount.accountId) ||
            audience.account_id === diySettings.fbAdAccount.accountId
          );
        },
      );

      setSharedLookalikeAudiences(sharedLookalikeAudience);

      const filteredShareableAudiences = lookalikeAudiences.filter(
        (audience: CustomAudience) => {
          return (
            adminData?.shareableLookalikeAudienceIds?.includes(audience.id) &&
            TARGET_COUNTRIES?.includes(audience.lookalike_spec.country)
          );
        },
      );

      setShareableCustomAudiences(filteredShareableAudiences);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const shareLookalikeAudiences = async () => {
    try {
      setLoading(true);

      await shareCustomAudiences(brand?._id, selectedCustomAudiences);

      const sharedAudienceCount = selectedCustomAudiences.length;

      let temp: CustomAudience[] = [];
      selectedCustomAudiences.forEach((customAudience: CustomAudience) => {
        const params: CustomAudience = {
          ...customAudience,
          id: customAudience.id.toString(),
          shared_account_info: {
            ...customAudience?.shared_account_info?.data,
            data: [
              ...(customAudience?.shared_account_info?.data || []),
              {
                account_id: diySettings?.fbAdAccount?.accountId,
                account_name: diySettings?.fbAdAccount?.name,
              },
            ],
          },
        };

        temp = [...temp, params];
      });

      setSharedLookalikeAudiences([...sharedLookalikeAudiences, ...temp]);
      setSelectedCustomAudiences([]);
      handleOpenRequestForm();
      dispatch(
        toggleAlert({
          toggle: true,
          message: `${sharedAudienceCount} new Lookalike Audience has been shared to your ad account`,
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const handleOpenRequestForm = () => {
    setOpenRequestForm((prev) => !prev);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
        <PrimaryButton
          title="Request Lookalike Audience"
          type="button"
          handleOnClick={handleOpenRequestForm}
          marginRight5
        />
      </Grid>

      <Grid item xs={12}>
        <LookalikeAudienceTable
          customAudiences={sharedLookalikeAudiences}
          loading={fetchLoading}
        />
      </Grid>

      <PopupModal open={openRequestForm} handleClose={handleOpenRequestForm}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ModalHeader title="Shareable Lookalike Audiences" />
          </Grid>

          <Grid item xs={12}>
            <LookalikeAudienceTable
              customAudiences={shareableCustomAudiences}
              loading={fetchLoading}
              share
              accountId={diySettings?.fbAdAccount?.accountId}
              setSelectedAudiences={setSelectedCustomAudiences}
            />
          </Grid>

          <Grid item xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
            {selectedCustomAudiences.length > 0 ? (
              <PrimaryButton
                title="Send Request"
                type="button"
                handleOnClick={shareLookalikeAudiences}
                loading={loading}
              />
            ) : null}
          </Grid>
        </Grid>
      </PopupModal>
    </Grid>
  );
};

export default AudienceForm;
