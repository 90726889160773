import {
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  Radio,
  Checkbox,
  Modal,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  formatNumber,
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
  flows,
} from '../../../../utils/constants/google';

import {
  fetchAssetLocationSet,
  fetchGoogle,
  fetchGoogleAssetsByBrand,
  saveModifiedAdBudget,
  saveModifiedAdCallouts,
  saveModifiedAdDescriptions,
  saveModifiedAdHeadlines,
  saveModifiedAdKeywords,
  saveModifiedAdSitelinks,
  saveModifiedAdFinalUrls,
  saveModifiedAdLocations,
  saveModifiedAdURL,
  saveModifiedAdImages,
  fetchGoogleAccountDetails,
  //saveModifiedAdLocations,
} from '../../../../services/googleAds';
import CircularLoading from '../../../CircularLoading';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions/ToggleAlert';
import LinearProgressBarWithLabel from '../../../ProgressBar/LinearProgressBarWithLabel';
import GoogleTemplateHeadlines from './GGLTemplatesHeadlines';
import GoogleTemplatesDescriptions from './GGLTemplatesDescriptions';
import GoogleTemplatesSiteLinks from './GGLTemplateSiteLinks';
import GoogleTemplatesKeywords from './GGLTemplatesKeywords';
import GoogleTemplatesImages from './GGLTemplatesImages';
import GoogleTemplatesPreview from './GGLTemplatesPreview';
import GoogleTemplatesCallouts from './GGLTemplatesCallouts';
import GoogleSelect from '../../../Select/GoogleSelect';
import GoogleTemplateSummary from './GGLTemplateSummary';
import GGLAssetsLocationTable from '../../Assetz/Google/GGLAssetsLocationTable';
import { TiWarningOutline } from 'react-icons/ti';
import googlesearch from '../../../../assets/images/employment.png';
import googledisplay from '../../../../assets/images/awareness.png';
import googlepmax from '../../../../assets/images/audience.png';

import GoogleTemplatesTopicsAndInterests from './GGLTemplatesTopicsAndInterests';
import GoogleTemplatesDemographics from './GGLTemplatesDemographics';
import GGLTemplatesDisplayKeywords from './GGLTemplatesDisplayKeywords';
import GGLTemplatesWebsites from './GGLTemplatesWebsites';
import GoogleTemplatesBSName from './GGLTemplatesBusinessName';
import GoogleTemplatesLongHeadline from './GGLTemplatesLongHeadline';
import GoogleTemplatesVideos from './GGLTemplatesVideos';
import GoogleTemplatesBusinessLogo from './GGLTemplatesBusinessLogo';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: `100%`,
  maxWidth: 600,
  bgcolor: 'background.paper',
  border: `4px solid ${DEFAULT_COLOR_THEME}`,
  boxShadow: 24,
  p: 4,
};

const selectionList: {
  [key: string]: {
    title: string;
    description: string;
    image: string;
  };
} = {
  search: {
    title: 'Responsive Search Ad',
    description:
      'Create ads that show in search results when someone searches for products or services you offer. Search ads can help you to find new customers or engage with existing ones.',
    image: googlesearch,
  },
  display: {
    title: 'Responsive Display Ad',
    description:
      'Create ads that show on websites and apps that are part of the Google Display Network such as YouTube, Gmail, and mobile apps',
    image: googledisplay,
  },
  performancemax: {
    title: 'Performance Max',
    description:
      'Create ads that show on websites and apps that are part of the Google Display Network such as YouTube, Gmail, and mobile apps',
    image: googlepmax,
  },
};

interface GGTemplateCreationProps {
  creationComplete: any;
  checkPS?: any;
  brandId: string;
  defaultRefreshToken: string;
  editPages?: string[];
  isExisting: boolean;
  template?: any;
  ad?: any;
  cancelCreation: () => void;
  selectedTemplateType?: (type: string) => void;
}

const GGTemplateCreation: React.FC<GGTemplateCreationProps> = ({
  creationComplete,
  checkPS,
  brandId,
  defaultRefreshToken,
  editPages,
  isExisting = false,
  template,
  ad,
  cancelCreation,
  selectedTemplateType,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState('');
  const [phoneNumberCollection, setPhoneNumberCollection] = useState([]);
  const [landingPageUrlCollection, setLandingPageUrlCollection] = useState([]);
  // const [imagesCollection, setImagesCollection] = useState([]);
  // const [videoCollection, setVideoCollection] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [templateType, setTemplateType] = useState('');
  //ad
  const [assetsLocationSet, setAssetsLocationSet] = useState([]);
  const [selectedLocationType, setSelectedLocationType] = useState(null);
  const [campaignDuration, setCampaignDuration] = useState('everyMonth');
  const [budget, setBudget] = useState('');
  const [budgetNextMonth, setBudgetNextMonth] = useState('');
  const [dailyBudget, setDailyBudget] = useState('');
  const [dateStart, setDateStart] = useState(
    new Date().toISOString().slice(0, 16),
  );

  const [dateEnd, setDateEnd] = useState('');
  const [isSettingUp, setIsSettingUp] = useState(true);
  const [updatingModal, setUpdatingModal] = useState(false);
  const [confirmOverwriteModal, setConfirmOverwriteModal] = useState(false);
  const [confirmOverwrite, setConfirmOverwrite] = useState(false);
  const [affectedAssets, setAffectedAssets] = useState([]);
  const [googleAds, setGoogleAds] = useState([]);
  const [googleAccount, setGoogleAccount] = useState({
    descriptiveName: '',
    currencyCode: '',
  });

  const [pages, setPages] = useState(
    editPages.length
      ? editPages
      : [
          'Product & Service',
          'URL',
          'Keywords',
          'Keywords Matching Type',
          'Phone Number',
          'Headlines',
          'Descriptions',
          'Callouts',
          'Sitelinks',
          'Images',
          'Preview',
        ],
  );
  const [currentTemplateForm, setCurrentTemplateForm] = useState({
    product_and_service: '',
    phoneNumber: '',
    landingPageUrl: '',
    headlines: [],
    descriptions: [],
    callouts: [],
    keywords: [],
    sitelinks: [],
    images: [],
    category: 'search',
    business_logo: [],
    topics_and_interest: [],
    demographics: {},
    websites: [],
    business_name: '',
    long_headline: '',
    display_keywords: [],
    videos: [],
  });

  const [isDirty, setIsDirty] = useState(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignDuration((event.target as HTMLInputElement).value);
  };

  const fetchLocationSets = () => {
    fetchAssetLocationSet({
      brandId: brandId,
    }).then((res: any) => {
      setAssetsLocationSet(res);
    });
  };

  const fetchGoogleAssets = async () => {
    setIsLoading(true);
    const { data } = await fetchGoogleAssetsByBrand(brandId);
    const newData: {
      headlines: {
        data: string;
        ps: string;
      }[];
      descriptions: {
        data: string;
        ps: string;
      }[];
      keywords: {
        data: any;
        ps: string;
      }[];
      sitelinks: {
        data: {
          headline: string;
          description1: string;
          description2: string;
          finalUrl: string;
        };
        ps: string;
      }[];
    } = {
      headlines: [],
      descriptions: [],
      keywords: [],
      sitelinks: [],
    };
    data.query.forEach(
      (q: {
        product_and_service: string;
        headlines?: string[];
        descriptions?: string[];
        keywords?: {
          keyword: string;
          exact: boolean;
          phrase: boolean;
          broad: boolean;
        }[];
        sitelinks?: {
          headline: string;
          description1: string;
          description2: string;
          finalUrl: string;
        }[];
      }) => {
        if (q.descriptions) {
          q.descriptions.forEach((d) => {
            if (d)
              newData.descriptions.push({ data: d, ps: q.product_and_service });
          });
        }
        if (q.headlines) {
          q.headlines.forEach((h) => {
            if (h)
              newData.headlines.push({ data: h, ps: q.product_and_service });
          });
        }
        if (q.keywords) {
          q.keywords.forEach((k) => {
            if (k)
              newData.keywords.push({
                data: k,
                ps: q.product_and_service,
              });
          });
        }
        if (q.sitelinks) {
          q.sitelinks.forEach((s) => {
            if (s.headline)
              newData.sitelinks.push({ data: s, ps: q.product_and_service });
          });
        }
      },
    );

    setPhoneNumberCollection(
      data.gacc?.phone_numbers
        ?.filter((p: any) => p.number)
        .map((p: any, index: number) => {
          return {
            id: `p${index}-${p}`,
            data: p,
            label: p.label,
          };
        }),
    );
    setLandingPageUrlCollection(
      data.gacc?.landing_page_url
        ?.filter((p: any) => p.url)
        .map((p: any, index: number) => {
          return {
            id: `p${index}-${p}`,
            data: p,
            label: p.label,
          };
        }),
    );

    // setImagesCollection(
    //   data.gacc?.images?.map((i: any, index: number) => {
    //     return {
    //       id: i.asset_id,
    //       ...i,
    //     };
    //   }) || [],
    // );

    // setVideoCollection(data.gacc?.videos || []);

    setIsLoading(false);
    if (currentPage === '' && !template?.category) {
      setCurrentPage(pages[0]);
    }
  };

  const checkDuplicateTemplateName = (name: string) => {
    if (isExisting) {
      return false;
    }

    let duplicate = false;
    googleAds.forEach((ad) => {
      if (ad.template?.name === name) {
        duplicate = true;
      }
    });

    return duplicate;
  };

  const saveTemplate = async () => {
    if (!templateName) {
      dispatch(
        toggleAlert({
          toggle: true,
          type: 'error',
          message: 'Please enter a name for the template',
        }),
      );
      return;
    }

    if (checkDuplicateTemplateName(templateName)) {
      dispatch(
        toggleAlert({
          toggle: true,
          type: 'error',
          message: 'Template name already exists, please choose another name',
        }),
      );
      return;
    }

    let hitTemplate = false;
    let hits: string[] = [];
    pages.forEach((page) => {
      if (['Budget', 'Locations', 'Preview'].includes(page)) return;
      hitTemplate = true;
      hits.push(page);
    });

    if (isExisting && hitTemplate) {
      if (hits.length > 0) {
        setAffectedAssets(hits);
        setConfirmOverwriteModal(true);
        return;
      }
    }

    if (isExisting && !hitTemplate) {
      handleSave();
      return;
    }

    let templateToSend = {};

    if (templateType === 'search') {
      templateToSend = {
        brandId: brandId,
        final_url: currentTemplateForm.landingPageUrl,
        product_and_service: currentTemplateForm.product_and_service,
        keywords: currentTemplateForm.keywords,
        headlines: currentTemplateForm.headlines,
        descriptions: currentTemplateForm.descriptions,
        sitelinks: currentTemplateForm.sitelinks,
        callouts: currentTemplateForm.callouts,
        images: currentTemplateForm.images,
        business_logo: currentTemplateForm.business_logo,
        phone_number: currentTemplateForm.phoneNumber,
        category: 'search',
        name: templateName,
        type: templateType,
      };
    } else if (templateType === 'display') {
      templateToSend = {
        brandId: brandId,
        final_url: currentTemplateForm.landingPageUrl,
        category: 'display',
        ...currentTemplateForm,
        name: templateName,
        type: templateType,
      };
    } else if (templateType === 'performancemax') {
      templateToSend = {
        brandId: brandId,
        final_url: currentTemplateForm.landingPageUrl,
        category: 'performancemax',
        ...currentTemplateForm,
        name: templateName,
        type: templateType,
      };
    } else {
      console.error('Template type not recognized');
      return dispatch(
        toggleAlert({
          toggle: true,
          type: 'error',
          message: 'Template type not recognized',
        }),
      );
    }

    try {
      const req = await creationComplete(templateToSend);

      return req;
    } catch (err) {
      console.log(err);
    }
  };

  const handleNextBtnClick = () => {
    let errors = false;
    let errorMsg = '';

    if (currentPage === 'Selection') {
      console.log(templateType, 'templateType');
      if (!templateType) {
        errors = true;
        errorMsg = 'Please select a template type';
      } else {
        setPages([...flows[templateType]]);
        setCurrentPage(flows[templateType][0]);

        setCurrentTemplateForm({
          ...currentTemplateForm,
          category: templateType,
        });

        return;
      }
    }

    if (currentPage === 'Budget') {
      if (!budget) {
        errors = true;
        errorMsg = 'Please enter a budget';
      }
      if (!dailyBudget) {
        errors = true;
        errorMsg = 'Please enter a daily budget';
      }
      if (!campaignDuration) {
        errors = true;
        errorMsg = 'Please select a campaign duration';
      }

      if (campaignDuration === 'fixedDuration' && !dateEnd) {
        errors = true;
        errorMsg = 'Please select an end date';
      }

      if (campaignDuration === 'fixedDuration' && dateEnd < dateStart) {
        errors = true;
        errorMsg = 'End date cannot be before start date';
      }
    }

    //Location
    if (currentPage === 'Locations') {
      if (!selectedLocationType) {
        errors = true;
        errorMsg = 'Please select a location set';
      }
    }

    //Product and service and landing page url
    if (currentPage === 'Product & Service') {
      if (!currentTemplateForm.product_and_service) {
        errors = true;
        errorMsg = 'Please create a product and service';
      }

      if (currentTemplateForm.product_and_service) {
        setTemplateName(
          currentTemplateForm.product_and_service
            .replace(/[^a-zA-Z0-9]/g, '_')
            .toLowerCase(),
        );
      }
    }

    //URL
    if (currentPage === 'URL') {
      if (!currentTemplateForm.landingPageUrl) {
        errors = true;
        errorMsg = 'Please select a landing page URL';
      }

      if (currentTemplateForm.landingPageUrl) {
        setCurrentTemplateForm({
          ...currentTemplateForm,
          landingPageUrl: currentTemplateForm.landingPageUrl,
        });
      }
    }

    //Keywords
    if (currentPage === 'Keywords') {
      errors = currentTemplateForm.keywords.length === 0;
      errorMsg = 'Please enter at least one keyword';
    }

    //Keywords matching type
    if (currentPage === 'Keywords Matching Type') {
      let error = 0;
      currentTemplateForm.keywords.forEach((keyword) => {
        if (!keyword.exact && !keyword.phrase && !keyword.broad) {
          error++;
        }
      });

      if (error > 0) {
        errors = true;
        errorMsg = 'Please select matching type for all keywords';
      }
    }

    //Phone number
    if (currentPage === 'Phone Number') {
      // errors = currentTemplateForm?.phoneNumber?.length === 0;
      // errorMsg = 'Please select a phone number';
    }

    //Head lines
    if (currentPage === 'Headlines') {
      errorMsg = '';
      let error = 0;
      const dupliCheck = new Set();
      currentTemplateForm.headlines.forEach((headline, i) => {
        if (dupliCheck.has(headline)) {
          errorMsg += `Headline #${i + 1} is a duplicate, `;
          error++;
        } else {
          dupliCheck.add(headline);
        }

        if (
          headline.length === 0 ||
          headline.length >
            googleResourceLimits.headlines[templateType as 'search' | 'display']
              .length
        ) {
          error++;
          errorMsg += `Headline #${i + 1} is empty or exceeds the limit of ${
            googleResourceLimits.headlines[templateType as 'search' | 'display']
              .length
          } characters, `;
        }
      });

      if (templateType === 'performancemax') {
        //check headline length 1 of them should be less than or equal to 15 characters
        let lessThan15 = 0;
        currentTemplateForm.headlines.forEach((h) => {
          if (h.length <= 15) {
            lessThan15++;
          }
        });

        if (lessThan15 < 1) {
          error++;
          errorMsg += `Please create at least 1 headline with less than or equal to 15 characters, `;
        }
      }

      if (currentTemplateForm.headlines.length < 5) {
        errorMsg += 'Please create at least 5 headlines';
      }
      errors = error > 0 || currentTemplateForm.headlines.length < 5;
    }

    //Descriptions
    if (currentPage === 'Descriptions') {
      errorMsg = '';
      let error = 0;
      const dupliCheck = new Set();
      currentTemplateForm.descriptions.forEach((d, i) => {
        if (dupliCheck.has(d)) {
          errorMsg += `Description #${i + 1} is a duplicate, `;
          error++;
        } else {
          dupliCheck.add(d);
        }

        if (
          d.length === 0 ||
          d.length >
            googleResourceLimits.descriptions[
              templateType as 'search' | 'display' | 'performancemax'
            ].length
        ) {
          error++;
          errorMsg += `Description #${i + 1} is empty or exceeds the limit of ${
            googleResourceLimits.descriptions[
              templateType as 'search' | 'display' | 'performancemax'
            ].length
          } characters, `;
        }
      });

      if (templateType === 'performancemax') {
        //check description length 1 of them should be less than or equal to 60 characters
        let lessThan60 = 0;
        currentTemplateForm.descriptions.forEach((d) => {
          if (d.length <= 60) {
            lessThan60++;
          }
        });
        if (lessThan60 < 1) {
          error++;
          errorMsg += `Please create at least 1 description with less than or equal to 60 characters, `;
        }
      }

      if (currentTemplateForm.descriptions.length < 2) {
        errorMsg += 'Please create at least 2 descriptions';
      }
      errors = error > 0 || currentTemplateForm.descriptions.length < 2;
    }

    //Callouts
    if (currentPage === 'Callouts') {
      errorMsg = '';
      let error = 0;
      const dupliCheck = new Set();
      currentTemplateForm.callouts.forEach((c, i) => {
        if (dupliCheck.has(c)) {
          errorMsg += `Callout #${i + 1} is a duplicate, `;
          error++;
        } else {
          dupliCheck.add(c);
        }

        if (c.length === 0 || c.length > googleResourceLimits.callouts.length) {
          error++;
          errorMsg += `Callout #${i + 1} is empty or exceeds the limit of ${
            googleResourceLimits.callouts.length
          } characters, `;
        }
      });

      if (currentTemplateForm.callouts.length < 4) {
        errorMsg += 'Please create at least 4 callouts';
      }
      errors = error > 0 || currentTemplateForm.callouts.length < 4;
    }

    //Sitelinks
    if (currentPage === 'Sitelinks') {
      errors = currentTemplateForm.sitelinks.length < 2;
      errorMsg = 'Please create at least 2 sitelinks';
      let error = 0;

      currentTemplateForm.sitelinks.forEach((sitelink) => {
        Object.keys(sitelink).forEach((key) => {
          if (
            sitelink[key].length === 0 ||
            //@ts-ignore
            sitelink[key].length > googleResourceLimits.sitelinks.length[key]
          ) {
            error++;
          }
        });
      });

      if (error)
        errors
          ? (errorMsg += `, Please fill in all sitelinks correctly`)
          : (errorMsg = `Please fill in all sitelinks correctly`);

      errors = error > 0;
    }

    //Images
    if (currentPage === 'Images') {
      if (
        currentTemplateForm.category === 'display' ||
        currentTemplateForm.category === 'performancemax'
      ) {
        if (currentTemplateForm.images.length > 0) {
          const squareImages = currentTemplateForm.images.filter(
            (image) => image.width === image.height,
          );
          const nonSquareImages = currentTemplateForm.images.filter(
            (image) => image.width !== image.height,
          );

          if (squareImages.length > 0 && nonSquareImages.length > 0) {
            errors = false;
          } else {
            errors = true;
            errorMsg =
              'Please upload at least one square and one non square image';
          }
          //Error check for images
        } else {
          errors = true;
          errorMsg =
            'Please upload at least one square and one non square image';
        }
      }
    }

    //BusinessName
    if (currentPage === 'Business Name') {
      errorMsg = '';
      let error = 0;

      if (
        !currentTemplateForm.business_name ||
        currentTemplateForm.business_name.length >
          googleResourceLimits.business_name
      ) {
        error++;
        errorMsg = `Please enter a business name with a maximum of ${googleResourceLimits.business_name} characters`;
      }

      errors = error > 0;
    }

    //Long Headline
    if (currentPage === 'Long Headline') {
      errorMsg = '';
      let error = 0;

      if (
        !currentTemplateForm.long_headline ||
        currentTemplateForm.long_headline.length >
          googleResourceLimits.long_headline
      ) {
        error++;
        errorMsg = `Please enter a long headline with a maximum of ${googleResourceLimits.long_headline} characters`;
      }

      errors = error > 0;
    }

    //Websites
    if (currentPage === 'Websites') {
      errors = currentTemplateForm?.websites?.length === 0;
      errorMsg = 'Please enter at least one website';
    }

    //display keywords
    if (currentPage === 'Display Keywords') {
      errors = currentTemplateForm.display_keywords.length === 0;
      errorMsg = 'Please enter at least one keyword';

      if (!errors) {
        if (
          googleResourceLimits.keywords[templateType as 'performancemax'] &&
          currentTemplateForm.display_keywords.length >
            googleResourceLimits.keywords[templateType as 'performancemax']
        ) {
          errors = true;
          errorMsg = `You have exceeded the limit of ${
            googleResourceLimits.keywords[templateType as 'performancemax']
          } keywords. Please remove ${
            currentTemplateForm.display_keywords.length -
            googleResourceLimits.keywords[templateType as 'performancemax']
          } keywords to proceed.`;
        }
      }
    }

    if (!errors) {
      const getIndex = pages.indexOf(currentPage);
      const nextPage = pages[getIndex + 1];
      setCurrentPage(nextPage);
    } else {
      dispatch(
        toggleAlert({
          toggle: true,
          type: 'error',
          message: errorMsg,
        }),
      );
    }
  };

  const fetchAds = () => {
    fetchGoogle({
      brandId: brandId,
      db: 'googleAds',
    }).then((data) => {
      setGoogleAds(data);
    });
  };

  const fetchAccount = () => {
    fetchGoogleAccountDetails({
      brandId: brandId,
    }).then((data) => {
      setGoogleAccount(data);
    });
  };

  useEffect(() => {
    if (ad) {
      setDailyBudget(ad.dailyBudget);
      setBudget(ad.budget);
      setBudgetNextMonth(ad.monthlyBudget);
      setSelectedLocationType(ad.locationSet);
      setIsSettingUp(false);
    }
    fetchGoogleAssets();
    fetchLocationSets();
    fetchAds();
    fetchAccount();

    const templ = {
      product_and_service: template?.product_and_service || '',
      phoneNumber: template?.phone_number || '',
      landingPageUrl: template?.final_url || '',
      headlines: template?.headlines || [],
      descriptions: template?.descriptions || [],
      callouts: template?.callouts || [],
      keywords: template?.keywords || [],
      sitelinks: template?.sitelinks || [],
      images: template?.images || [],
      category: template?.category || 'search',
      business_logo: template?.business_logo || [],
      topics_and_interest: template?.topics_and_interest || [],
      demographics: template?.demographics || {},
      business_name: template?.business_name || '',
      long_headline: template?.long_headline || '',
      websites: template?.websites || [],
      display_keywords: template?.display_keywords || [],
      videos: template?.videos || [],
    };
    console.log(`🚀 templ`);
    console.log(templ);
    setCurrentTemplateForm(templ);
    setTemplateName(isExisting ? template?.name || '' : '');

    //setCurrentPage(editPages[0]);

    if (template?.category) {
      setTemplateType(template?.category);
      setPages(flows[template?.category]);
      selectedTemplateType(template?.category);
      setCurrentPage(flows[template?.category][0]);
    }
    return () => {
      setCurrentPage('');
      setTemplateType('');
      setTemplateName('');
      setPages([]);
      setCurrentTemplateForm({
        product_and_service: '',
        phoneNumber: '',
        landingPageUrl: '',
        headlines: [],
        descriptions: [],
        callouts: [],
        keywords: [],
        sitelinks: [],
        images: [],
        category: 'search',
        business_logo: [],
        topics_and_interest: [],
        demographics: {},
        business_name: '',
        long_headline: '',
        websites: [],
        display_keywords: [],
        videos: [],
      });
    };
  }, []);

  const saveHeadlines = (headlines: string[]) => {
    setCurrentTemplateForm({
      ...currentTemplateForm,
      headlines: headlines,
    });
  };

  const saveCallouts = (callouts: string[]) => {
    setCurrentTemplateForm({
      ...currentTemplateForm,
      callouts: callouts,
    });
  };

  const saveDescriptions = (descriptions: string[]) => {
    setCurrentTemplateForm({
      ...currentTemplateForm,
      descriptions: descriptions,
    });
  };

  const saveKeywords = (keywords: string[]) => {
    setCurrentTemplateForm({
      ...currentTemplateForm,
      keywords: keywords,
    });
  };

  const saveSitelinks = (sitelinks: string[]) => {
    setCurrentTemplateForm({
      ...currentTemplateForm,
      sitelinks: sitelinks,
    });
  };

  const saveInterestsAndTopics = (topicsAndInterests: string[]) => {
    console.log(`save interests and topics`, topicsAndInterests);
    setCurrentTemplateForm({
      ...currentTemplateForm,
      topics_and_interest: topicsAndInterests,
    });
  };

  const saveDemographics = (demographics: any) => {
    console.log(`save demographics`, demographics);
    setCurrentTemplateForm({
      ...currentTemplateForm,
      demographics: demographics,
    });
  };

  const handleCloseConfirmOverwrite = () => {
    setConfirmOverwriteModal(false);
    setConfirmOverwrite(false);
  };

  const handleSave = async () => {
    setConfirmOverwriteModal(false);
    setIsLoading(true);

    const t = ad?.template || template;

    const affectedAds = googleAds
      ?.filter((a) => a.template?._id === t._id)
      ?.map((a) => {
        return a;
      });

    for (let a of affectedAds) {
      for (let p of pages) {
        if (p === 'Budget') {
          if (ad._id === a._id) {
            await saveModifiedAdBudget({
              brandId: brandId,
              ad: ad,
              budget: {
                dailyBudget: dailyBudget,
                budget: budget,
                budgetNextMonth: budgetNextMonth,
                dateStart: dateStart,
                dateEnd: dateEnd,
              },
            });
          }
        }

        if (p === 'Locations') {
          if (ad._id === a._id) {
            saveModifiedAdLocations({
              brandId: brandId,
              ad: ad,
              locations: selectedLocationType.locations,
              locationSet: selectedLocationType,
            });
          }
        }

        if (p === 'URL') {
          saveModifiedAdURL({
            brandId: brandId,
            ad: ad,
            url: currentTemplateForm.landingPageUrl,
          });
        }

        if (p === 'Headlines') {
          await saveModifiedAdHeadlines({
            brandId: brandId,
            ad: ad,
            headlines: currentTemplateForm.headlines,
          });
        }

        if (p === 'Descriptions') {
          await saveModifiedAdDescriptions({
            brandId: brandId,
            ad: ad,
            descriptions: currentTemplateForm.descriptions,
          });
        }

        if (p === 'Keywords') {
          await saveModifiedAdKeywords({
            brandId: brandId,
            ad: ad,
            keywords: currentTemplateForm.keywords,
          });
        }

        if (p === 'Sitelinks') {
          await saveModifiedAdSitelinks({
            brandId: brandId,
            ad: ad,
            sitelinks: currentTemplateForm.sitelinks,
          });
        }
        if (p === 'Callouts') {
          await saveModifiedAdCallouts({
            brandId: brandId,
            ad: ad,
            callouts: currentTemplateForm.callouts,
          });
        }
      }
    }

    setIsLoading(false);
    creationComplete(t);
  };

  const overwriteModal = (
    <Modal
      open={confirmOverwriteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {isExisting ? (
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color={DEFAULT_COLOR_THEME}
            sx={{
              display: `flex`,
              justifyContent: `start`,
              gap: 1,
              alignItems: `center`,
              fontWeight: 600,
            }}
          >
            <TiWarningOutline color={DEFAULT_COLOR_THEME} size={28} /> Are you
            sure?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Changes to this template will affect all ads that are using it. Are
            you sure you want to make changes for this template?
          </Typography>

          {affectedAssets.length > 0 ? (
            <Box sx={{ marginY: 2 }}>
              <Typography>Changes to this template:</Typography>
              <Box sx={{ maxHeight: `400px`, overflowY: `auto` }}>
                {affectedAssets?.map((a) => (
                  <Box sx={{ paddingLeft: 2 }}>• {a}</Box>
                ))}
              </Box>
            </Box>
          ) : null}

          {googleAds?.filter((a) => a?.template?._id === ad?.template?._id)
            .length > 0 ? (
            <Box sx={{ marginY: 2 }}>
              <Typography>Ads using this template:</Typography>
              <Box sx={{ maxHeight: `400px`, overflowY: `auto` }}>
                {googleAds
                  ?.filter((a) => a?.template?._id === ad?.template?._id)
                  ?.map((a) => (
                    <Box sx={{ paddingLeft: 2 }}>• {a.name}</Box>
                  ))}
              </Box>
            </Box>
          ) : null}
          <Box sx={{ marginY: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmOverwrite}
                  onChange={() => {
                    setConfirmOverwrite(!confirmOverwrite);
                  }}
                />
              }
              label="I understand the implications of overwriting this template"
            />
          </Box>
          <Box
            sx={{
              display: `flex`,
              justifyContent: `space-between`,
              gap: 2,
            }}
          >
            <Button onClick={handleCloseConfirmOverwrite}>Cancel</Button>
            <Button
              variant="contained"
              disabled={!confirmOverwrite}
              onClick={handleSave}
            >
              {confirmOverwrite
                ? 'Confirm and Save'
                : 'you must confirm to save'}
            </Button>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Modal>
  );

  const page: {
    [key: string]: JSX.Element;
  } = {
    Selection: (
      <Box>
        <Typography
          variant="h6"
          color={`primary`}
          sx={{
            fontWeight: 800,
          }}
        >
          Select a template type:
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, marginTop: 4, paddingX: 4 }}>
          {Object.keys(selectionList).map((key) => (
            <Card
              key={`card-${key}`}
              sx={{
                maxWidth: 345,
                border: 'solid',
                borderColor:
                  templateType === key ? DEFAULT_COLOR_THEME : 'transparent',
                marginBottom: '30px',
                marginLeft: '10px',
                background:
                  'linear-gradient(180deg, #FFF 38.7%, #096F4D 113.65%)',
                height: '100% !important',
                borderRadius: '8px',
                cursor: 'pointer',
                '&:hover': {
                  border: 'solid',
                  borderColor: DEFAULT_COLOR_THEME,
                },
              }}
            >
              <CardActionArea
                sx={{
                  height: '100%',
                }}
                onClick={() => {
                  setTemplateType(key);
                  setPages(flows[key]);
                  selectedTemplateType(key);
                  setCurrentPage(flows[key][0]);
                  setCurrentTemplateForm({
                    ...currentTemplateForm,
                    category: key,
                  });
                }}
              >
                <CardMedia
                  component="img"
                  height="240"
                  image={selectionList[key].image}
                  alt="google search"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color={'white'}
                    fontWeight={600}
                  >
                    {selectionList[key].title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'white',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      lineClamp: 3,
                      WebkitLineClamp: 3,
                      overflow: 'hidden',
                    }}
                  >
                    {selectionList[key].description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      </Box>
    ),
    Budget: (
      <Box>
        <Typography
          variant="h6"
          color={`primary`}
          sx={{
            fontWeight: 800,
          }}
        >
          PLEASE SET YOUR SCHEDULE AND BUDGET
        </Typography>
        <Box
          sx={{
            marginY: 2,
          }}
        >
          <TextField
            label="Start Date/Time"
            variant="standard"
            type="datetime-local"
            value={dateStart}
            onChange={(e) => {
              setDateStart(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              minWidth: 300,
            }}
          />
        </Box>

        <Box
          sx={{
            marginTop: 2,
          }}
        >
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={campaignDuration}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="everyMonth"
                control={<Radio />}
                label="Run campaign every month"
              />
              <FormControlLabel
                value="fixedDuration"
                control={<Radio />}
                label="Stop campaign on"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box
          sx={{
            marginBottom: 2,
            opacity: campaignDuration === 'fixedDuration' ? 1 : 0,
            height: campaignDuration === 'fixedDuration' ? 'auto' : 0,
            transition: 'opacity 0.5s ease',
          }}
        >
          <TextField
            label="End Date/Time"
            variant="standard"
            type="datetime-local"
            value={dateEnd}
            onChange={(e) => {
              setDateEnd(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              minWidth: 300,
            }}
          />
        </Box>

        <Box
          sx={{
            marginY: 4,
          }}
        >
          <TextField
            label="Current Month's Budget"
            variant="standard"
            value={budget}
            sx={{ marginRight: '2rem' }}
            name="currentMonthBudget"
            onChange={(e) => {
              const val = e.target.value.replace(/[^0-9.]/g, '');
              setBudget(val);
              const today = new Date();
              const lastDay = new Date(
                today.getFullYear(),
                today.getMonth() + 1,
                0,
              );
              const daysLeft = lastDay.getDate() - today.getDate() + 1;
              const dailyBudget = parseFloat(e.target.value) / daysLeft;
              setDailyBudget(
                val ? (Math.floor(dailyBudget * 100) / 100).toFixed(2) : '',
              );
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">USD</InputAdornment>,
            }}
          />

          <TextField
            label="Future Monthly Budget"
            variant="standard"
            value={budgetNextMonth}
            onChange={(e) => {
              const val = e.target.value.replace(/[^0-9.]/g, '');

              setBudgetNextMonth(val);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">USD</InputAdornment>,
            }}
          />
        </Box>
        <Box
          sx={{
            marginY: 2,
          }}
        >
          <TextField
            label="Daily Budget"
            variant="standard"
            value={dailyBudget}
            onChange={(e) => {
              const val = e.target.value.replace(/[^0-9.]/g, '');
              setDailyBudget(val ? val : '');
              const today = new Date();
              const lastDay = new Date(
                today.getFullYear(),
                today.getMonth() + 1,
                0,
              );
              const daysLeft = lastDay.getDate() - today.getDate();
              const targetBudget = parseFloat(val) * daysLeft;
              setBudget(val ? targetBudget.toFixed(2) : '');
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">USD</InputAdornment>,
            }}
          />
        </Box>
      </Box>
    ),
    Locations: (
      <Box sx={{}}>
        <Typography variant="h6" color={`primary`}>
          Select a location set to target your Google Ad
        </Typography>
        <Box sx={{ maxWidth: 800, paddingLeft: 2, marginTop: 2 }}>
          {assetsLocationSet.length > 0
            ? assetsLocationSet?.map((l) => (
                <Box
                  key={l.name}
                  sx={{
                    width: `100%`,
                    textAlign: `left`,
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    paddingX: '0.5rem',
                    paddingY: '0.2rem',
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      border: '1px solid #aaa',
                      marginBottom: 1,
                      backgroundColor:
                        selectedLocationType?.name === l.name
                          ? `#e6ffd4`
                          : `#ffffff`,
                      borderColor:
                        selectedLocationType?.name === l.name
                          ? DEFAULT_COLOR_THEME
                          : `#aaa`,

                      maxHeight: 150,
                      minWidth: 700,
                      overflowY: 'auto',
                      ...scrollBarGreen,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingX: '0.5rem',
                        paddingY: '0.2rem',
                        position: 'sticky',
                        top: 0,
                        backgroundColor:
                          selectedLocationType?.name === l.name
                            ? `#e6ffd4`
                            : `#ffffff`,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: DEFAULT_COLOR_THEME,
                          fontWeight: 'bold',
                        }}
                      >
                        {l.name}
                      </Typography>
                      <Typography
                        sx={{
                          color: DEFAULT_COLOR_THEME,
                          fontWeight: 'bold',
                        }}
                      >
                        {l?.locations?.length} Locations
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.5rem',
                        padding: '0.5rem',
                      }}
                    >
                      <GGLAssetsLocationTable
                        locations={l?.locations?.map((loc: any) => {
                          return {
                            ...loc,
                            reach: formatNumber(loc.reach),
                          };
                        })}
                        selected={selectedLocationType?.name === l.name}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Button
                    onClick={() => setSelectedLocationType(l)}
                    variant="contained"
                    sx={{
                      backgroundColor: DEFAULT_COLOR_THEME,
                      paddingX: '20px',
                    }}
                  >
                    Select
                  </Button>
                </Box>
              ))
            : null}
        </Box>
      </Box>
    ),
    'Product & Service': (
      <>
        <Box sx={{ width: '100%', maxWidth: '600px' }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
              marginBottom: '10px',
              textTransform: 'uppercase',
            }}
          >
            Enter Product or Services:
          </Typography>
          <TextField
            sx={{ width: '100%' }}
            label="Product or Services"
            value={currentTemplateForm.product_and_service}
            onChange={(e) =>
              setCurrentTemplateForm({
                ...currentTemplateForm,
                product_and_service: e.target.value,
              })
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleNextBtnClick();
              }
            }}
          />
        </Box>
      </>
    ),
    URL: (
      <>
        <Box sx={{ width: '100%', maxWidth: '600px' }}>
          <Box
            sx={{
              maxWidth: `700px`,
              marginTop: 8,
              marginBottom: 4,
              paddingRight: 2,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '1.2rem',
                fontWeight: 800,
                color: DEFAULT_COLOR_THEME,
              },
              overflowY: 'auto',
              ...scrollBarGreen,
            }}
          >
            <GoogleSelect
              title="Select Final URL:"
              options={landingPageUrlCollection?.map((ps: any) => ({
                row1: 'label',
                row2: 'url',
                data: ps.data,
                value: 'url',
              }))}
              selected={currentTemplateForm?.landingPageUrl}
              onSelect={(url: any) => {
                console.log(url);
                console.log(currentTemplateForm);
                setCurrentTemplateForm({
                  ...currentTemplateForm,
                  landingPageUrl: url,
                });
              }}
            />
          </Box>
        </Box>
      </>
    ),
    Keywords: (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Box>
              <GoogleTemplatesKeywords
                templateUrl={currentTemplateForm?.landingPageUrl}
                currentPage={currentPage}
                selectedProductAndService={
                  currentTemplateForm?.product_and_service
                }
                submitForm={saveKeywords}
                templateForm={currentTemplateForm?.keywords}
                setIsDirty={setIsDirty}
                brandId={brandId}
                defaultRefreshToken={defaultRefreshToken}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    'Keywords Matching Type': (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Box>
              <GoogleTemplatesKeywords
                templateUrl={currentTemplateForm?.landingPageUrl}
                currentPage={currentPage}
                selectedProductAndService={
                  currentTemplateForm?.product_and_service
                }
                submitForm={saveKeywords}
                templateForm={currentTemplateForm.keywords}
                setIsDirty={setIsDirty}
                brandId={brandId}
                defaultRefreshToken={defaultRefreshToken}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    'Phone Number': (
      <>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Box
              sx={{
                maxWidth: `500px`,
                marginBottom: 4,
                fontWeight: 800,
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontSize: '1.2rem',
                  fontWeight: 800,
                  color: DEFAULT_COLOR_THEME,
                },
              }}
            >
              <GoogleSelect
                title="Select a Phone Number:"
                options={phoneNumberCollection?.map((ps: any) => ({
                  row1: 'label',
                  row2: 'number',
                  data: ps.data,
                  value: 'number',
                }))}
                selected={currentTemplateForm?.phoneNumber}
                onSelect={(s: any) => {
                  setCurrentTemplateForm({
                    ...currentTemplateForm,
                    phoneNumber: s,
                  });
                }}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    Headlines: (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
                marginBottom: '10px',
                textTransform: 'uppercase',
              }}
            >
              Create headlines:
            </Typography>
            <Box>
              <GoogleTemplateHeadlines
                submitForm={saveHeadlines}
                templateType={templateType as 'search' | 'display'}
                templateForm={currentTemplateForm?.headlines}
                setIsDirty={setIsDirty}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    Descriptions: (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
                marginBottom: '10px',
                textTransform: 'uppercase',
              }}
            >
              Create descriptions:
            </Typography>
            <Box>
              <GoogleTemplatesDescriptions
                templateType={templateType as 'search' | 'display'}
                submitForm={saveDescriptions}
                templateForm={currentTemplateForm.descriptions}
                setIsDirty={setIsDirty}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    Callouts: (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
                marginBottom: '10px',
                textTransform: 'uppercase',
              }}
            >
              Create callouts:
            </Typography>
            <Box>
              <GoogleTemplatesCallouts
                brandId={brandId}
                submitForm={saveCallouts}
                templateForm={currentTemplateForm.callouts}
                setIsDirty={setIsDirty}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    Sitelinks: (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
                marginBottom: '10px',
                textTransform: 'uppercase',
              }}
            >
              Create site links:
            </Typography>
            <Box>
              <GoogleTemplatesSiteLinks
                submitForm={saveSitelinks}
                templateForm={currentTemplateForm.sitelinks}
                setIsDirty={setIsDirty}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    'Business Logo': (
      <>
        <Box sx={{ width: '100%' }}>
          <GoogleTemplatesBusinessLogo
            templateType={templateType as 'search' | 'display'}
            brandId={brandId}
            templateForm={currentTemplateForm.business_logo}
            submitForm={(e: any) => {
              setCurrentTemplateForm({
                ...currentTemplateForm,
                business_logo: e,
              });
            }}
            setIsDirty={setIsDirty}
            refetchImages={fetchGoogleAssets}
          />
        </Box>
      </>
    ),
    Images: (
      <>
        <Box sx={{ width: '100%' }}>
          <GoogleTemplatesImages
            templateType={
              templateType as 'search' | 'display' | 'performancemax'
            }
            brandId={brandId}
            templateForm={currentTemplateForm.images}
            submitForm={(e: any) => {
              setCurrentTemplateForm({
                ...currentTemplateForm,
                images: e,
              });
            }}
            setIsDirty={setIsDirty}
            refetchImages={fetchGoogleAssets}
          />
        </Box>
      </>
    ),
    'Topics & Interests': (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
                marginBottom: '10px',
                textTransform: 'uppercase',
              }}
            >
              Select Topics & Interests:
            </Typography>
            <Box>
              <GoogleTemplatesTopicsAndInterests
                submitForm={saveInterestsAndTopics}
                templateForm={currentTemplateForm.topics_and_interest}
                setIsDirty={setIsDirty}
                brandId={brandId}
                templateType={
                  templateType as 'search' | 'display' | 'performancemax'
                }
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    Demographics: (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
                marginBottom: '10px',
                textTransform: 'uppercase',
              }}
            >
              Set Demographics:
            </Typography>
            <Box>
              <GoogleTemplatesDemographics
                submitForm={saveDemographics}
                templateForm={currentTemplateForm.demographics}
                setIsDirty={setIsDirty}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    'Display Keywords': (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
                marginBottom: '10px',
                textTransform: 'uppercase',
              }}
            >
              {templateType === 'performancemax'
                ? 'Set Search Themes:'
                : 'Set Keywords:'}
            </Typography>
            <Box>
              <GGLTemplatesDisplayKeywords
                submitForm={
                  (keywords: string[]) =>
                    setCurrentTemplateForm({
                      ...currentTemplateForm,
                      display_keywords: keywords,
                    }) //submitForm
                }
                templateForm={currentTemplateForm.display_keywords}
                setIsDirty={setIsDirty}
                templateType={templateType}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    Websites: (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
                marginBottom: '10px',
                textTransform: 'uppercase',
              }}
            >
              Set Websites:
            </Typography>
            <Box>
              <GGLTemplatesWebsites
                submitForm={
                  (websites: string[]) =>
                    setCurrentTemplateForm({
                      ...currentTemplateForm,
                      websites: websites,
                    }) //submitForm
                }
                templateForm={currentTemplateForm.websites}
                setIsDirty={setIsDirty}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    'Business Name': (
      <>
        <GoogleTemplatesBSName
          submitForm={(name: string) => {
            setCurrentTemplateForm({
              ...currentTemplateForm,
              business_name: name,
            });
          }}
          googleAccount={googleAccount}
          templateForm={currentTemplateForm.business_name}
        />
      </>
    ),
    'Long Headline': (
      <>
        <GoogleTemplatesLongHeadline
          submitForm={(headline: string) => {
            setCurrentTemplateForm({
              ...currentTemplateForm,
              long_headline: headline,
            });
          }}
          templateForm={currentTemplateForm.long_headline}
        />
      </>
    ),
    Videos: (
      <>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              overflowY: `auto`,
              ...scrollBarGreen,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
                marginBottom: '10px',
                textTransform: 'uppercase',
              }}
            >
              Set Video Links:
            </Typography>
            <Box>
              <GoogleTemplatesVideos
                brandId={brandId}
                submitForm={(videos: any) => {
                  setCurrentTemplateForm({
                    ...currentTemplateForm,
                    videos: videos,
                  });
                }}
                templateForm={currentTemplateForm.videos}
                setIsDirty={setIsDirty}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
    Preview: (
      <>
        <Box
          sx={{
            width: '100%',
            overflowY: `auto`,
            position: 'relative',
            ...scrollBarGreen,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            flexDirection: 'row-reverse',
          }}
        >
          <Box
            sx={{
              width: '50%',
              position: 'sticky',
              top: 0,
              right: 0,
              paddingRight: '1rem',
            }}
          >
            <GoogleTemplatesPreview
              category={templateType}
              headlines={currentTemplateForm?.headlines}
              descriptions={currentTemplateForm?.descriptions}
              url={currentTemplateForm?.landingPageUrl}
              sitelinks={currentTemplateForm?.sitelinks}
              images={currentTemplateForm?.images}
              business_logo={currentTemplateForm?.business_logo}
              business_name={currentTemplateForm?.business_name}
            />
          </Box>
          <Box
            sx={{
              width: '50%',
            }}
          >
            <Box
              sx={{
                maxWidth: `500px`,
                marginBottom: '20px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  color: DEFAULT_COLOR_THEME,
                  marginBottom: '10px',
                  textTransform: 'uppercase',
                }}
              >
                Enter name for this Template:
              </Typography>
              <TextField
                sx={{ width: '100%' }}
                label="Template Name"
                value={templateName}
                disabled={isExisting}
                onChange={(e) => setTemplateName(e.target.value)}
              />
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  fontStyle: 'italic',
                  color: DEFAULT_COLOR_THEME,
                  marginBottom: '10px',
                  display: isExisting ? 'block' : 'none',
                }}
              >
                Template name cannot be changed on existing templates
              </Typography>
            </Box>
            <Box>
              <GoogleTemplateSummary
                template={currentTemplateForm}
                ad={ad}
                templateType={templateType}
              />
            </Box>
          </Box>
        </Box>
      </>
    ),
  };

  const creationFooter = (
    <Box
      sx={{
        position: 'sticky',
        zIndex: 300,
        bottom: -20,
        backgroundColor: '#fff',
        display: currentPage === 'Selection' ? 'none' : 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
        padding: '1rem',
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: '50%',
        }}
      >
        <LinearProgressBarWithLabel
          value={((pages.indexOf(currentPage) + 1) / pages.length) * 100}
        />
      </Box>
      <Box
        sx={{
          width: '50%',
          display: 'flex',
          justifyContent: 'end',
          gap: '0.5rem',
          alignItems: 'end',
        }}
      >
        <Button variant="outlined" onClick={cancelCreation}>
          Cancel
        </Button>

        <Button
          variant="outlined"
          sx={{ display: currentPage === pages[0] ? 'none' : 'block' }}
          onClick={() => {
            const getIndex = pages.indexOf(currentPage);
            const prevPage = pages[getIndex - 1];
            setCurrentPage(prevPage);
          }}
        >
          Back
        </Button>

        <Button
          variant="contained"
          sx={{ display: currentPage === 'Preview' ? 'none' : 'block' }}
          onClick={handleNextBtnClick}
        >
          Next
        </Button>

        <Button
          variant="contained"
          sx={{ display: currentPage === 'Preview' ? 'block' : 'none' }}
          onClick={saveTemplate}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
  return (
    <Box sx={GoogleAssetsContainer}>
      <CircularLoading loading={isLoading} />
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        {page[currentPage]}
        {creationFooter}
        {overwriteModal}
      </Box>
    </Box>
  );
};

export default GGTemplateCreation;
